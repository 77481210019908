<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row>
      <v-col>
        <v-card
          dark
          class="elevation-4"
          style="
            text-align: center;
            padding: 0 0 35px 0;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Pour lire les raisons qui m'ont amenées à la création du roman
            <strong>Les pigments d'éternité</strong>, <br>
            cliquez sur le titre de la rubrique qui vous intéresse.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col lg="4" md="5" sm="12">
        <v-btn x-large active @click="le95 = false">
          <v-icon color="primary" v-if="le95 == false">mdi-eye</v-icon> 
          La Génèse
        </v-btn>
      </v-col>
      <v-col lg="4" md="5" sm="12">
        <v-btn x-large @click="le95 = true">
          <v-icon color="primary" v-if="le95 == true">mdi-eye</v-icon> 
          Trouver un titre
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text v-if="le95 == false">
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              « Les pigments d’éternité » est un roman dans la veine de «
              l’Inconnue », dans cet univers qui est le mien depuis que j’écris
              des romans. La seule exception à cette règle, et ce ne sera sans
              doute pas la seule dans l'avenir, est « Carnets d’esprit » où j’ai
              investi un champ d’action différent en revenant dans le réel. Très
              franchement, ce n’est pas un choix que j’ai fait de façon
              consciente car j’ai plus l’impression que ce sont les sujets qui
              me choisissent plutôt que le contraire.<br />
              Mais revenons à « Les pigments d’éternité ». C’est en visitant le
              Musée du Louvre en 2007 et en voyant la Joconde que j’ai eu l’idée
              d’écrire « Les pigments d’éternité ». Je me suis dit subitement
              que la Joconde était vivante, qu’elle nous regardait tous et
              qu’elle attendait la personne qu’elle aimerait avant de sortir. Je
              la sentais protégée par la peinture de Léonard de Vinci.
              L’invention des « pigments d’éternité » est venue tout
              naturellement par la suite afin d’étayer la « véracité » du roman.
              Les trois caractéristiques indispensables de ces pigments pour
              donner du corps à l’histoire m’ont très rapidement semblé
              évidentes :<br />
              - première caractéristique : annihiler la gravité terrestre : cela
              me semblait logique afin que personne ne devine la présence de
              Mona Lisa à l’intérieur du tableau. Si celui-ci avait eu un poids
              conséquent, l’histoire aurait été à mon sens difficile à faire
              avaler.<br />
              - deuxième caractéristique : préserver Mona de la morsure du
              temps,<br />
              - troisième caractéristique : les pigments sont prévus fondre le
              jour où Mona va rencontrer la personne qu’elle aime.<br />
              Ces trois caractéristiques donnent à mon sens son originalité au
              roman. J’ai ensuite voulu donner de la densité en faisant exister
              et parler Léonard de Vinci ainsi que la Joconde. Afin de ne pas
              être trop conventionnel, j’ai choisi que la Joconde tombe
              amoureuse d’une femme. Je trouvais intéressant l’idée d’étudier
              l’amour au féminin entre deux femmes dans la société
              d’aujourd’hui.<br />
              J’ai pour cela écouté des personnes qui vivaient en couple afin
              d’obtenir des éléments tangibles sur la difficulté de vivre un
              amour homosexuel. J’ai aussi voulu imaginer ce qui arriverait si
              une personne aussi célèbre que la Joconde quittait « son » tableau
              : comment réagirait le public si le tableau était retiré de la
              salle où il est exposé ? Comment réagirait le Ministre de la
              Culture si ce tableau, qui est la « tête de gondole » du Musée du
              Louvre, disparaissait subitement ? Comment réagirait un flic si on
              lui demandait de lancer ses hommes à la recherche d’une femme
              ressemblant comme deux gouttes d’eau à la Joconde ?<br />
              Et puis, il y a cette question essentielle : comment
              réagirions-nous si l’opportunité nous était donnée de pouvoir
              traverser le temps ? Je trouve qu’il est bien de s’intéresser à
              une telle question car elle nous renvoie à notre propre fin et au
              sens même de l’existence. Ces questions m’ont semblé intéressantes
              à creuser au-delà de l’histoire elle-même.<br />
              Et puis, il y a ces thèmes récurrents dans mes romans, y compris
              dans « Carnets d’esprit ». Ce sont notamment les failles de mes
              personnages que j’aime étudier. J’aime les amener au bord du
              gouffre et faire en sorte qu’ils trouvent au fond d’eux-mêmes les
              ressources pour se relever. Pablo dans « Les pigments d’éternité »
              ou Léonid dans « Carnets d’esprit » en sont les reflets.<br />
            </p>
          </v-card-text>
          <v-card-text v-if="le95 == true">
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              Un titre a toujours l’air simple à trouver. C’est en fait un
              véritable casse-tête. J’ai d’abord hésité sur « Jours d’été » car
              beaucoup d’évènements des « pigments d’éternité » se déroulent un
              jour d’été. Il me semblait donc logique de prendre ce titre.<br />
              L’éditeur ne le trouvait pas assez vendeur. Il n’avait pas tort.
              C’est vrai que « Jours d’été » n’attire pas l’œil. Il faut
              imaginer son livre dans une forêt de livres dans une librairie.
              C’est dur de se faire une place, croyez-moi !<br />
              Pour vous dire, la moyenne de vente d’un premier roman est de…200
              exemplaires. Quand on arrive à 7000 comme ce fut le cas pour
              <em>
                <a 
                  href="http://philippe-nonie.fr/inconnue"
                  class="extraitRoman"
                  style="font-size: 1.1em"
                  >l’Inconnue</a>
                </em>
                , c’est un succès ! Pourtant, quand on y regarde de près, c’est
              très peu pour beaucoup de travail !<br />
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      le95: false,
    };
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["pigments/viewState"];
      },
      set(newState) {
        return this.$store.commit("pigments/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
</style>