const state = () => ({
  viewState: {
    defaultDsply: true,
    sampleDsply: false,
    geneseDsply: false,
    albumDsply: false,
    liensUtilesDsply: false,
    lecteursDsply: false,
    auteurDsply: false
  },
  count: 0
})


// mutations
const mutations = {
  changeDsplyState(state, newState) {
      state.viewState = newState
  },
  changeCountval(state, val) {
      state.count = val;
  }
}

// actions
const actions = {}


// getters
const getters = {
  viewState (state) {
      return state.viewState;
  },
  counterVal (state) {
      return state.count;
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}