<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row>
      <v-col>
        <v-card
          dark
          class="elevation-4"
          style="text-align: center; font-size: 16pt"
        >
          <v-card-text style="color: AntiqueWhite; font-size: 16pt">
            Ici nous abordons les raisons qui m'ont amené à l'écriture du roman <u>Les pierres de mémoires</u>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text>
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              Cette histoire, est née un soir, fortuitement, alors même que
              j’écrivais mon deuxième roman, Carnets d’esprit-Prypiat, l’autre
              histoire de Tchernobyl. 
              <br><br>
              J’étais littéralement possédé par
              l’écriture de ce livre et, le soir de Noël, j’ai dit à ma femme
              sur le ton de la plaisanterie : Le virus de la création me dévore.
              <br><br>
              Durant l’écriture de ce livre, j’ai visité à maintes reprises des
              maisons abandonnées, des granges foraines, d’anciennes bergeries,
              des étables d’un autre temps, des moulins désaffectés, d’anciennes
              scieries qui utilisaient alors la force motrice de l’eau pour
              entrainer les lames de scie. 
              <br>Quoi qu’on en pense, les paysages
              gardent en mémoire l’histoire de ceux qui ont construit tout ce
              qui nous entoure. Sans en avoir l’air, le travail réalisé par
              leurs mains murmure à nos oreilles : « Avant vous, il y a eu nous. » 
              <br>
              Je veux profiter de cette note pour dire un mot sur les cagots.
              C’était le terme générique employé dans les Hautes-Pyrénées pour
              désigner les cagots même si beaucoup de villages possédaient leur
              propre mot. 
              <br><br>
              D’après certaines estimations, les cagots ont
              représenté près de 10% de la population française avant de
              disparaître totalement ; pendant des siècles, ils ont été des
              parias qui devaient porter une patte d’oie cousue sur leurs
              vêtements pour les distinguer des autres. 
              <br>
              Leurs conditions de vie,
              la considération dont ils faisaient l’objet, n’est pas à mettre au
              crédit des sociétés qui les ont rejetés à travers les siècles, à
              croire que chaque société a besoin d’exclure pour mieux se situer
              face à l’autre. Je place ci-dessous quelques sites internet qui
              évoquent la situation des cagots. 
              <br><br>
              Ils ne sont en aucun cas exhaustifs, c’est juste une incitation à découvrir qui ils ont été
              et le regard que leur histoire peut indirectement nous aider à
              porter sur notre époque. 
              <br><br><br>
              Voir aussi <v-icon>mdi-forward</v-icon><v-btn class="primary" light @click="navigateToUsefullLinks">Liens utiles</v-btn>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      le95: false,
    };
  },
  methods: {
    navigateToUsefullLinks() {
      this.viewState.defaultDsply = false;
      this.viewState.sampleDsply = false;
      this.viewState.geneseDsply = false;
      this.viewState.albumDsply = false;
      this.viewState.liensUtilesDsply = true;
      this.viewState.lecteursDsply = false;
      this.viewState.auteurDsply = false;
    }
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["lpdm/viewState"];
      },
      set(newState) {
        return this.$store.commit("lpdm/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
</style>