<template>
  <v-container v-if="viewState.albumDsply == true">
    <v-card
      dark
      class="elevation-4 ma-1 mb-3 grey darken-4"
      outlined
      style="
        text-align: center;
        font-size: 16pt;
      ">
      <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
        Ci-dessous, vous trouverez une sélection de photos liées au roman
      </v-card-text>
    </v-card>
    <v-carousel cycle height="800" hide-delimiters class="carousel">
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/04-murmure/1-bergerac-16h11.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/04-murmure/2-photo-commemorative.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/04-murmure/3-plaque-rue.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/04-murmure/4-philippe-bergerac-photo-1.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/04-murmure/5-andre-leveque.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/04-murmure/6-chaufferie-poudrerie-bergerac.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['murmure/viewState'];
      },
      set(newState) {
        return this.$store.commit('murmure/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>

.carousel {
  border-radius: 5px;
  border: 3px solid lightgrey;
  background: rgba(100,100,100,0.8);
}

</style>