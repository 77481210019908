import Vue from 'vue'
import Vuex from 'vuex'

import inconnue     from './modules/inconnue.js';
import carnets      from './modules/carnets.js';
import pigments     from './modules/pigments.js';
import murmure      from './modules/murmure.js';
import ldb          from './modules/ldb.js';
import lpdm         from './modules/lpdm.js';
import lmds         from './modules/lmds.js';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        inconnue,
        carnets,
        pigments,
        murmure,
        ldb,
        lpdm,
        lmds
    },
    state: {
        count: 0,
        comingSoonDlg: {
            dsplyState: false
        },
        visitCounter: 0
    },
    mutations: {
        increment (state) {
            state.count++
        },
        SET_COMING_SOON_DIALOG(state, newDlg) {
            state.comingSoonDlg = newDlg;
        },
        SET_COUNTER(state, payload) {
            state.visitCounter = payload;
        }
    },
    actions: {},
    getters: {
        GET_COMING_SOON_DIALOG (state) {
            return state.comingSoonDlg;
        },
        GET_COUNTER (state) {
            return state.visitCounter;
        }
    }
})