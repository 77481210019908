<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    <v-row justify="center">
      <v-col align="center">
        <v-card
          dark
          class="elevation-4 mb-3 grey darken-4"
          outlined
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Ci-dessous, se trouve une sélection de liens connexes à mon roman
          </v-card-text>
        </v-card>
        <v-card dark outlined class="pa-5">
          <v-divider></v-divider>
          <v-card-text
            style="color: AntiqueWhite; font-size: 16pt; text-align: justify"
          >
            
            
            <p>
              <v-icon>mdi-forward</v-icon>
              J’évoque ce passage de la fin du film « Diên Biên Phu » dans mon roman où un
              violon triste et mélancolique vient appuyer 
              <a 
                href="https://www.dailymotion.com/video/x4uk73" 
                title="Images de la défaite de Dien Bien Phu">
                les images de la défaite.
              </a> 
            </p>
            <br>
            <p>
              <v-icon>mdi-forward</v-icon>
              <a href="https://fr.wikipedia.org/wiki/Saint-Antoine-de-Ficalba">Quelques images</a> de la commune de Saint-Antoine-de-Ficalba
              où je situe la majeure partie de l’action de L.D.B. .
            </p>
            <br>
            <p>
              <v-icon>mdi-forward</v-icon>
              Dans mon roman, Stéphanie appartient aux troupes des Chasseurs Alpins. 
              Je vous propose donc <a href="https://www.youtube.com/watch?v=QhVcxsKHu-s">un reportage</a> 
              de l’émission « Zone interdite » qui se situe dans la vallée de la Kapisa. 
              Je me suis appuyé sur ce type de reportage 
              mais aussi sur l’échange avec des soldats français ayant
              servi en Afghanistan pour construire mon roman et décrire cette
              ambiance terrible des combats et des embuscades.
            </p>
            <br>
            <p>
              <v-icon>mdi-forward</v-icon>
              <a href="http://www.dailymotion.com/video/x5n3q7_dien-bien-phu-5-colonnes-a-la-une-1_news">
                L’interview de Pierre Schœndoffer et du colonel Bigeard 
              </a>
              que j’évoque dans « L.D.B. » : ces deux hommes ont servi à Diên Biên Phu et ont été faits
              prisonniers. Ils sont effectué cette longue marche à travers la
              jungle qu’évoque Amédée, le grand-père de Stéphanie. Cette interview
              date de 1964 et s’est déroulée dans le cadre de l’émission « Cinq
              colonnes à la Une ». Les deux hommes découvrent en direct un
              reportage tourné du côté des Viêts et racontent la reddition de la
              garnison de Diên Biên Phu. 
            </p>
            <br>
            <p>
              <v-icon>mdi-forward</v-icon>
              Amédée, le grand-père de Stéphanie, appartient à la 
              <a href="https://web.archive.org/web/20181221050953/https://fr.wikipedia.org/wiki/Troupes_coloniales">
              « Coloniale »</a>, ces troupes françaises qui ont servi
              outre-mer et dans les colonies. On voit d’ailleurs dans ce lien une
              affiche de l’époque pour s’engager dans les troupes coloniales
              durant la Seconde Guerre mondiale. Mon grand-père paternel a
              appartenu à ces troupes d’élite de l’Armée française. Il a lui-même
              fait la guerre d’Indochine pendant deux ans.
            </p>
            
            
            
            
            
            
            
            
             
            
            
            
            
            
            
            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["ldb/viewState"];
      },
      set(newState) {
        return this.$store.commit("ldb/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.parag {
  color: AntiqueWhite;
  text-align: justify;
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>