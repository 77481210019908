<template>
  <v-container class="py-0 my-0" >
    <v-row class="py-0 my-0">
      <v-col cols="12" class="pa-0 ma-0 mb-3">
        <div style="font-size: 24pt; height: 150px; line-height: 150px; text-shadow: 5px 5px 28px grey;">
        </div>
      </v-col>
    </v-row>    

    <v-row >
      <v-col class="grey darken-3 pa-0 ma-0" cols="12">
        <!-- Lien de menu 1 -->
        <hr>
        <v-btn
          class="mr-3"
          dark text
          to="/"
          color="white">
          Accueil
        </v-btn>

        <!-- Lien de menu 2 -->
        <v-menu
          open-on-hover bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              dark text
              class="mr-3"
              v-bind="attrs"
              v-on="on"
              @click="dsplySiteContent('Default')"
            >
              Le Roman
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(mrLink, index) in menuRomanLinks"
              :key="index"
              @click="dsplySiteContent(mrLink.title)"
              link
            >
              <v-list-item-title >{{ mrLink.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


        <!-- Lien de menu 3 -->
        <v-btn
          text
          class="mr-3"
          color="white"
          @click="dsplySiteContent('LiensUtiles')"
        >
          Lien utiles
        </v-btn>

        <!-- Lien de menu 4 -->
        <v-menu
          open-on-hover bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              text
              class="mr-3"
              v-bind="attrs"
              v-on="on"
            >
              Paroles
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(oepLink, index) in onEnParle"
              :key="index"
              @click="dsplySiteContent(oepLink.title)"
              link
            >
              <v-list-item-title v-text="oepLink.title" ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Lien de menu 5 -->
        <v-btn
          text
          class="mr-3"
          color="white"
          href="mailto:philippe.nonie@gmail.com"
        >
          Contact
        </v-btn>

      <hr>
      </v-col>
    </v-row>

    <!-- default View State -->
    <v-row v-if="viewState.defaultDsply == true" justify="center" class="pa-0 ma-0">
      <v-col lg="6" md="6" sm="10" xs="12">
        <v-carousel cycle height="550" hide-delimiters class="carousel">
          <v-carousel-item
            background-size: contain
            src="@/assets/img/pigments/AGenouxLittle.png"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
          <v-carousel-item
            background-size: contain
            src="@/assets/img/ldb/slideshow-premiere-couverture-ldb.png"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
        </v-carousel>
        <!-- <v-img class="img-inco" src="@/assets/img/inconnue/AGenoux.png"></v-img> -->
      </v-col>
      <v-col lg="6" md="6" sm="10" xs="12" align-self="start">
        <v-card class="pa-3 " style="border: 3px solid lightgrey;">
          <p class="text">
            
          </p>
          <p class="text">
            Après le succès littéraire de « L’Inconnue », 
            prix Nouveau Talent 2011, Philippe Nonie livre unqqzdqssd
            roman puissant aux frontières de la sciencefiction. 
            <br>
            L.D.B. est une œuvre qui nous entraîne
            sur les chemins du passé et de l’oubli, 
            et nous questionne : comment détricoter un deuil
            douloureux et comment réagir face à l’impossible ?
          </p>
          <v-divider></v-divider>
          <h2>Actualités</h2>
          <v-divider></v-divider>
          <p v-for="(actu, idx) in news" :key="idx" class="news ma-0 pa-0 my-5">
            <strong>{{ actu.title }}</strong> : {{ actu.content }} <br> <a :href="actu.linkRef" target="_blank">{{ actu.linkText }}</a>
          </p>
          <p>
            Communiqué de presse de L.D.B.
            <a :href="communiquePresse" title="Communiqué de presse de L.D.B.">Cliquez ici</a>
          </p>
        </v-card>
      </v-col>
    </v-row>


    <!-- Album Photo -->
    <v-row v-if="viewState.albumDsply == true">
      <v-col>
        <AlbumPhoto />
      </v-col>
    </v-row>

    <!-- La génèse -->
    <v-row v-if="viewState.geneseDsply == true">
      <v-col>
        <Genese />
      </v-col>
    </v-row>

    <!-- Extraits -->
    <v-row v-if="viewState.sampleDsply == true">
      <v-col>
        <Extraits />
      </v-col>
    </v-row>

    <!-- Liens Utiles -->
    <v-row v-if="viewState.liensUtilesDsply == true">
      <v-col>
        <LiensUtiles />
      </v-col>
    </v-row>

    <!-- Paroles de lecteurs -->
    <v-row v-if="viewState.lecteursDsply == true">
      <v-col>
        <ParolesLecteurs />
      </v-col>
    </v-row>

    <!-- Paroles de l'auteur -->
    <v-row v-if="viewState.auteurDsply == true">
      <v-col>
        <ParolesAuteur />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import AlbumPhoto       from "@/components/ldb/album-photos.vue";
import Extraits         from "@/components/ldb/extraits.vue";
import Genese           from "@/components/ldb/genese.vue";
import LiensUtiles      from "@/components/ldb/liens-utiles.vue";
import ParolesLecteurs  from "@/components/ldb/paroles-lecteurs.vue";
import ParolesAuteur    from "@/components/ldb/paroles-auteur.vue";

import newsMurmure      from "@/components/ldb/news.json";


export default {
  components:{
    Extraits,
    Genese,
    AlbumPhoto,
    LiensUtiles,
    ParolesLecteurs,
    ParolesAuteur
  },
  data() {
    return {
      news: newsMurmure,
      communiquePresse : require('@/assets/img/ldb/Communique-de-presse-L.D.B.pdf'),
      menuRomanLinks: [
        { title: 'Extraits' },
        { title: 'La génèse' }
      ],
      onEnParle: [
        { title: 'De lecteurs'},
        { title: "l'Auteur"}
      ]
    }
  },
  methods: {
    dsplySiteContent(link) {

      if(link == 'Default') {
        this.viewState.defaultDsply = true;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if(link == 'La génèse') {
        this.viewState.defaultDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.geneseDsply = true;
        this.viewState.sampleDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if(link == 'Album photos') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = true;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if (link  == 'Extraits') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = true;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if (link  == 'LiensUtiles') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = true;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if (link  == 'De lecteurs') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = true;
        this.viewState.auteurDsply = false;
      
      } else if (link  == "l'Auteur") {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = true;
      }
    }
  },
  computed: {
    viewState: {
      get(){
        return this.$store.getters['ldb/viewState'];
      },
      set(newState) {
        return this.$store.commit('ldb/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>

.text {
  color: black;
  font-size: 16pt;
  text-align: justify;
  text-indent: 10%;
}

.carousel {
  background: white;
  border: 3px solid lightgrey;
  border-radius: 5px;
}

.site-banner {
  border: 4px solid lightgrey;
  border-radius: 20px;
}

</style>