<template>
  <v-container v-if="viewState.albumDsply == true">
      <v-card
          dark
          class="elevation-4 mb-3"
          style="text-align: center; font-size: 16pt; "
        >
        <v-card-text style="color: AntiqueWhite; font-size: 16pt">
          Ci-dessous vous trouverez une sélection de photos ayant inspiré l'univers du roman <u>Les pierres de mémoires</u>
        </v-card-text>
      </v-card>
    <v-carousel cycle height="800" hide-delimiters class="carousel">
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/06-LesPierresDeMemoires/amelie-les-bains.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/06-LesPierresDeMemoires/benitier-cagot-h900.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/06-LesPierresDeMemoires/benitier-normal-h900.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/06-LesPierresDeMemoires/entree-cagots-h900.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/06-LesPierresDeMemoires/entree-normale-h900.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['lpdm/viewState'];
      },
      set(newState) {
        return this.$store.commit('lpdm/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>

.carousel {
  border-radius: 5px;
  border: 3px solid lightgrey;
  background: rgba(100,100,100,0.8);
}

</style>