<template>
  <v-container v-if="viewState.lecteursDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title>
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de lecteurs
            </h1>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Si tu aimes les vrais êtres humains, les mots, la lenteur comme art de vivre, 
              l’étang de Thau, Sète, ces étranges moments de grâce qui font le sel de la vie… 
              <br>
              Alors tu aimeras l’excellent roman de Philippe Nonie : « Les mots des Salines ». 
              <br>
              Je le recommande très chaleureusement. "
              <br><br>
              <strong>Juan</strong>
            </p>
          </v-card-text>


          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              Lisez le nouveau roman de Philippe Nonie ... un beau roman. 
              <br><br>
              Merci Philippe.
              <br><br>
              <strong>Sophie</strong>
            </p>
          </v-card-text>


          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Magnifique roman ! A ne pas manquer."
              <br><br>
              <strong>Jean-Philippe (directeur de la maison d'édition : La Trace)</strong>
            </p>
          </v-card-text>


          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Je viens de finir la lecture des "Mots des Salines", commencée dimanche dernier 
              <br><br>
              Très joli livre, intelligemment construit 
              (mêlant habilement les récits : celui de l histoire, 
              celui de Sophie à Stéphane, celui de Simon). 
              Que de pages impatientes ! 
              J ai beaucoup aimé le personnage de la lenteur (!!!), 
              à laquelle tu consacres des passages empreints d une juste délicatesse 
              et joliment écrits que l on a envie de souligner ! 
              La métaphore du temps qui passe et les trains qui traversent ton roman 
              de façon récurrente sont habilement menés.
              <br><br>
              Donc merci et bravo pour cette lecture délicate, sensible et profonde !
              Bon vent à ce roman et bonne continuation ! 
              (Le roman sur les bancs reste à écrire !!!
              <br><br>
              J ai oublié bien sûr ce qui est une évidence : au centre du roman, 
              on lit une véritable réflexion sur les MOTS, leur pouvoir, 
              leurs méandres etc... 
              Bref, une plongée dans les méandres du dire et de l écrire. 
              Pour reprendre Sophie et Simon, tout en détournant quelque peu leurs mots, on pourrait ainsi résumer ce livre : 
              "Les mots sont le sel de la vie." 
              <br><br>
              <strong>Christine</strong>
            </p>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-text>
            <p class="avis-lecteur">
              Bonsoir Philippe, Je viens de finir de lire ton livre. <br>
              On dévore ce livre. Je suis vraiment impressionée par ton grand talent Philippe, 
              par ton vocabulaire, tellement beau et riche. <br>
              J'ai appris beaucoup de nouveaux mots français en lisant ton livre.<br>
              Et en plus tu parles dans ton livre du problème très important - des assistants sexuels, 
              auquel je n'ai jamais pensé avant. <br>
              C'est le livre qui n’a pas seulement enrichi mon vocabulaire français mais aussi ma vie. <br>
              Ton livre mérite tous les prix littéraires.  
              <br><br>
              <strong>Justyna</strong>
            </p>
          </v-card-text>
        
          <v-divider></v-divider>
          
          <v-card-text>
            <p class="avis-lecteur">
              Bonjour Philippe, ça y est, je viens de terminer votre livre. <br>
              J'ai pris le temps de suivre l'histoire. Tous les jours, quelques pages, 
              lentement dans le temps ...<br>
              J'ai beaucoup aimé votre manière d'écrire et de jouer avec les mots.<br><br>
              Félicitations.
              <br><br>
              <strong>Sylvie</strong>
            </p>
          </v-card-text>
        
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["lmds/viewState"];
      },
      set(newState) {
        return this.$store.commit("lmds/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.avis-lecteur {
  color: AntiqueWhite;
  font-size: 16pt;
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
}

.avis-lecteur p {
  color: AntiqueWhite;
  text-align: justify;
}
</style>