<template>
  <v-container v-if="viewState.sampleDsply == true">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <span style="color: white;">Voici une sélection d'extraits de mon roman <u>Le murmure des attentes</u></span>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <v-window-item
            v-for="(s, idx) in samples"
            :key="idx"
          >
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <!-- <v-avatar
                    color="grey"
                    class="mr-4"
                  ></v-avatar> -->
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">{{ s.titre }}</strong>
                  </v-col>
                  
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
  
                <p v-for="(paragraph, idxp) in s.paragraph" :key="idxp" class="paragraphe">
                  {{ paragraph }}
                  <v-img src=""></v-img>
                </p>

              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "Houla (Syrie) - 18 Mai",
          paragraph: 
          ["La vie et la mort passent l’essentiel de leur temps à jouer à cache-cache avec nous. "
          +"Cette phrase jaillit de ma mémoire alors même qu’un obus "
          +"ravage la façade d’un immeuble. Je devrais avoir peur, être "
          +"terrorisée, mais non. "
          +"Pendant une fraction de seconde, je songe à cette réflexion d’Hassan comme si les mots pouvaient "
          +"devenir un refuge face à la barbarie. "
          +" ",
          " "
          +"***",
          " ",
          "A suivre ..."
          ]
        },
        {
          titre: "Tonkin-Cu-Phong - 3 novembre 1915",
          paragraph: 
          ["Le soleil est déjà au zénith dans les rizières de Cu-Phong "
          +"lorsqu’une voiture se met à pétarader. Hô lève la tête avec ses "
          +"camarades. Son visage est constellé de sueur. Il s’éponge le "
          +"front tout en regardant le conducteur manoeuvrer pour se garer "
          +"au plus près d’eux. ",
          "Le chauffeur ouvre la porte arrière gauche et un Européen "
          +"en sort. Il se dirige vers eux puis s’arrête au bord de l’eau. ",
          "— Bonjour messieurs, dit-il en plaçant ses mains en portevoix,"
          +"réunion ce soir à dix-neuf heures dans la salle des fêtes ! "
          +"Présence obligatoire de tous les travailleurs. L’Intendant "
          +"Général des colonies aura une communication de la plus haute "
          +"importance à vous faire !",
          "Tous les Tonkinois se regardent tandis que l’Européen "
          +"rentre dans sa voiture sans en dire plus. Le véhicule démarre et "
          +"s’éloigne en laissant derrière lui une fumée grise.",
          "— Que se passe-t-il Hô ? Pourquoi nous convoquent-ils ?",
          ""
          +"",
          "A suivre ..."
          ]
        },
        {
          titre: "Houla - 22 mai 2012",
          paragraph: 
          ["La voiture s’engage prudemment sur la droite. Je tente de "
          +"trouver la position la moins inconfortable possible. L’angoisse "
          +"semble décupler la douleur à ma jambe tandis qu’une tension "
          +"palpable règne dans le véhicule. Aucun d’entre nous n’ose "
          +"rompre le silence, comme si les snipers pouvaient repérer un "
          +"murmure. Jamais autant qu’aujourd’hui, je ne me suis sentie "
          +"aussi vulnérable, peut-être parce que je n’ai jamais vécu une "
          +"telle incertitude. Cette obscurité recèle des dangers bien plus "
          +"dangereux que les monstres nocturnes de mon enfance. ",
          " ",
          "Brusquement, la voiture s’arrête. Chacun d’entre nous se "
          +"tait. Les mots sont inutiles alors même que nos yeux scrutent "
          +"l’obscurité à la recherche d’un détail anormal que le chauffeur "
          +"a repéré. ",
          "— Que se passe-t-il ? souffle Abdul qui a du mal à "
          +"dissimuler son inquiétude. ",
          "— Notre homme devrait être là… ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Poudredrie de Bergerac - 11 novembre 1918",
          paragraph: 
          ["Une pluie fine tombe doucement sur le Périgord. "
          +"La Dordogne, toute proche, renforce un peu plus cette sensation "
          +"d’humidité qui flotte dans l’air depuis déjà plusieurs jours. ",
          "D’un seul coup, l’une des sirènes se déclenche sans raison "
          +"apparente. Les hommes et les femmes lèvent la tête, se "
          +"regardent étonnés, médusés même. "
          +"À cet instant, il est onze heures du matin en ce onzième jour "
          +"du onzième mois de l’année 1918. Personne ne comprend "
          +"vraiment le sens de cette sonnerie qui, en temps normal, est "
          +"seulement activée à l’embauche et à la débauche des ouvriers. ",
          "Le personnel sort des ateliers de fabrication. Les visages sont "
          +" surpris et traversés par les stigmates de l’incompréhension. ",
          "Chacun regarde l’autre pour comprendre. Au loin, les cloches "
          +"de Bergerac, Cours-de-Pile, Lalinde, Mouleydier, bientôt "
          +"rejointes par les cloches de tous les villages environnants, "
          +"Saint-Laurent-des-Vignes, Lembras, carillonnent de manière "
          +"incompréhensible. Chacun perçoit bientôt leur murmure qui "
          +"enfle comme un fleuve en crue. ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Prison de Chanzy Bergerac - 5 septembre 1939",
          paragraph: 
          ["La période de prison touche à sa fin. Hô va être libéré après "
          +"vingt années à purger sa peine. Quelques jours avant sa "
          +"libération effective, il a cette conversation avec le directeur de la prison. ",
          "— Vous voyez, j’ai connu les tranchées et j’ai été blessé le "
          +"11 novembre 1918. En 1919, j’ai défilé avec mes camarades de "
          +"régiment au cri de : Plus jamais ça ! ",
          "Mais ce temps ne lui avait pas été donné. ",
          "Il marque une pause. Une mouche vole dans la pièce. Elle "
          +"décrit des arabesques étonnantes puis termine par quelques "
          +"figures géométriques avant de taper frénétiquement sur le "
          +"carreau de la fenêtre. ",
          "— Plus jamais ça ! hurle-t-il soudain, en tapant du poing "
          +"sur la table. Quelle fumisterie ! Ce cri, j’espérais n’avoir plus à "
          +"le dire qu’aux détenus qui tentent de s’évader de la prison de Chanzy.",
          "Il s’arrête à nouveau. Hô ne réagit pas. Le directeur l’a "
          +"convoqué quelques minutes auparavant dans son bureau. Il a "
          +"d’abord pensé que l’un des employeurs agricoles s’était plaint "
          +"de son comportement. "
          +"Ou de son travail. "
          +"Ou des deux peut-être. ",
          "Comment savoir ? ",
          "Il comprend à présent qu’il n’en est rien. ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Alger - 4 juin 1958",
          paragraph: 
          ["La foule est compacte, dense, nerveuse même. Elle est là, "
          +"comme une baleine échouée sur une plage, encore vivante, "
          +"mais incapable de regagner la haute mer.",
          "Des dizaines, peutêtre "
          +"même des centaines de milliers de gens patientent, "
          +" piétinent, trépignent, leurs regards tendus vers un balcon de la "
          +"place d’Alger. ",
          "D’un moment à l’autre, un homme doit "
          +"apparaître. Il vient pour prononcer un discours et tous ici, tous "
          +"sans exception, sont là pour l’écouter. Ils ne sont pas "
          +"véritablement dans l’attente, car ils savent ce qu’ils veulent "
          +"entendre. Ils veulent des mots, des mots pour les rassurer, des "
          +"mots qui vont dessiner leur avenir. S’il est venu jusqu’à Alger, "
          +"s’il a pris la peine de traverser la mer Méditerranée, ce ne peut "
          +"être que pour cela. Soudain, cet homme apparaît au balcon et la "
          +"foule l’acclame spontanément. ",
          "",
          "A suivre ..."
          ]
        }
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['murmure/viewState'];
      },
      set(newState) {
        return this.$store.commit('murmure/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.title {
  color: white;
}
.paragraphe {
  font-size: 16pt;
  text-align: justify;
  color: antiquewhite
}
</style>