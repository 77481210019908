<template>
  <v-container v-if="viewState.sampleDsply == true">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-card flat dark class="pa-3 ma-3">
          <span style="color: white;">Voici une sélection d'extraits de mon roman <u>Les pigments d'éternité</u></span>
        </v-card>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <v-window-item
            v-for="(s, idx) in samples"
            :key="idx"
          >
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <!-- <v-avatar
                    color="grey"
                    class="mr-4"
                  ></v-avatar> -->
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">{{ s.titre }}</strong>
                  </v-col>
                  
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
  
                <p v-for="(paragraph, idxp) in s.paragraph" :key="idxp" class="paragraphe">
                  {{ paragraph }}
                  <v-img src=""></v-img>
                </p>

              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "4ème de couverture",
          paragraph: 
          ["A la mort de son père, un célèbre restaurateur de tableaux, Florence se "
          +"rend chez le notaire pour régler les formalités d’héritage. Elle se "
          +"trouve alors dans l’obligation inattendue d’écouter une lettre écrite "
          +"vingt-cinq ans auparavant et dont le contenu la laisse abasourdie : la  "
          +"Joconde serait toujours vivante ! Elle aurait traversé les siècles à  "
          +"l’intérieur du tableau grâce à une invention méconnue de Léonard de  "
          +"Vinci, les pigments d’éternité, prévus pour fondre le  "
          +"jour où Mona Lisa rencontrera l’amour. Florence va entreprendre une  "
          +"enquête afin de comprendre sa propre histoire, celle d’un père dont elle  "
          +"découvre la face cachée, d’une mère qu’elle n’a jamais connue et celle  "
          +"de la plus célèbre peinture au monde… ",
          " "
          +"L’auteur nous emmène dans une aventure où le Temps transcende le temps "
          +"et dont le suspense ne faiblit jamais, mais qu’on ne s’y trompe pas, il "
          +"aborde aussi des thèmes tels que la fascination exercée par l’œuvre  "
          +"d’art sur nos contemporains et le rapport très particulier liant le  "
          +"peintre à son modèle. ",
          ""
          +"Philippe Nonie est âgé de 46 ans. Il travaille comme conseiller en  "
          +"prévention des risques professionnels et vit dans les Hautes-Pyrénées. "
          +"«Les pigments d’éternité » est son troisième roman  "
          +"après « Carnets d’esprit » paru aux éditions L’Harmattan en septembre  "
          +"2012 et « L’Inconnue », publié chez Calmann-Levy en 2011. Ce roman a  "
          +"obtenu le Prix « Nouveau Talent 2011 ». ",
          " ",
          "A suivre ..."
          ]
        },
        {
          titre: "Appartement de Pablo",
          paragraph: 
          ["18, Boulevard du Temple. L’imposante porte cochère se referme derrière  "
          +"elle. Florence délaisse l’ascenseur et emprunte la cage d’escalier.  "
          +"Troisième étage. Un tintement de clés. Elle inspire profondément puis "
          +"ouvre les serrures une à une. Son cœur bat la chamade. Elle entre,  "
          +"referme délicatement la porte de l’appartement comme si elle craignait "
          +"de réveiller quelqu’un. Elle n’allume aucune lampe. Elle ferme les yeux "
          +"un instant, les rouvre. La lumière qui filtre au travers des volets à "
          +"persiennes suffit encore à éclairer l’appartement. "
          +"Florence ne veut pas les ouvrir. Elle a besoin de cette intimité pour "
          +"ressentir cette absence. Car l’absence est le parfum sibyllin de la "
          +"disparition d’un être cher. ",
          "Et cette absence lui appartient. ",
          "C’est la sienne. ",
          "Elle correspond à son père qui lui manque déjà terriblement. ",
          "Il était encore là il y a quelques jours. ",
          "Elle le revoit se lever et s’écrouler subitement ? ",
          "Depuis combien de temps ne l’avait-elle pas vu ? ",
          "Trois mois ? ",
          "Six mois ? ",
          "Plus ? ",
          "",
          "Impossible de le savoir tant ils s’étaient éloignés l’un de l’autre. "
          +"Florence en avait souffert et lui aussi sans doute. Mais aucun d’entre "
          +"eux n’avait voulu faire le premier pas. "
          +"Sur le moment, mais sur le moment seulement, sa disparition subite ne "
          +"l’avait pas affecté autant qu’elle l’aurait souhaité. Elle ressentait sa "
          +"mort comme une punition. Son père avait été sanctionné d’avoir prononcé "
          +"ces mots terribles. Voyant qu’il diminuait dans les dernières semaines "
          +"de sa vie, Florence avait tenté de se rapprocher de lui, elle désirait "
          +"en savoir davantage sur sa mère. Elle voulait faire parler son père "
          +"avant qu’il n’emporte les secrets dans sa tombe et il avait deviné la "
          +"manœuvre. ",
          "Il ne lui avait rien dit de plus qu’elle ne savait déjà : sa mère était "
          +"morte d’une longue maladie et il l’avait accompagnée jusqu’au bout. Fin "
          +"de l’histoire.",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "16 Septembre 2025",
          paragraph: 
          ["Florence froisse la lettre de rage. "
          +"Des gouttes de sueur perlent à son front. "
          +"En quelques instants, sa vie vient de basculer. "
          +"La faute à cette lettre. ",
          " "
          +"Quelques minutes auparavant, Florence était chez le notaire. Elle "
          +"dévisageait cet homme mince lui lire sur un ton fluide et monocorde le "
          +"testament de son père. Tout se déroulait normalement : l’appartement, "
          +"les produits financiers, la maison de montagne, les meubles, les "
          +"tableaux de peintres. ",
          " "
          +"Jusqu’à ce codicille contenu dans une enveloppe postée vingt-cinq ans "
          +"auparavant. Elle reconnait aussitôt l’écriture de son père. Il s’agit "
          +"d’une enveloppe cachetée qu’il s’est envoyée à lui-même et n’a jamais  "
          +"ouverte. ",
          "Ses dernières volontés sont précises : le clerc de notaire doit ouvrir "
          +"la lettre en sa présence et lui en dévoiler le contenu. En aucune façon, "
          +"elle ne peut le faire elle-même. L’homme exécute les conditions "
          +"testamentaires de son client à la lettre. Il lit et Florence sent "
          +"progressivement le monde s’écrouler autour d’elle.",
          "Les deux dernières phrases. ",
          "Je t’embrasse par-delà la mort. ",
          "Ton père qui t’aime. ",
          "Et le clerc de notaire s’arrête. Il jauge la réaction de sa cliente. ",
          "Florence est en plein désarroi. ",
          "Pourquoi une telle mise en scène ? ",
          "Pourquoi a-t-il chargé cet homme de lui en dévoiler le contenu ? ",
          "Dans quel but ?",
          "- Ça va aller ? demande le clerc de notaire. ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Laboratoire",
          paragraph: 
          ["Le carillon du téléphone le surprend dans son travail. "
          +"- Allô ? "
          +"- Bonsoir, Monsieur. J’ai le Ministre de la Culture en ligne. Il "
          +"souhaiterait vous parler et m’a fait comprendre qu’il faut vous libérer "
          +"pour le prendre. "
          +"Pablo pousse un soupir de désapprobation. Il se passerait volontiers de "
          +"cette corvée. La standardiste s’impatiente au bout du fil. "
          +"- Monsieur, j’ai le Ministre qui attend…et je voudrais rentrer chez moi. ",
          " "
          +"- Oui, je sais, rétorque-t-il sèchement. Bonne soirée ! Allô ? "
          +"- Bonsoir Monsieur, c’est le cabinet du Ministre. Je vous passe le "
          +"Ministre de la Culture. ",
          "- Merci. Allô ? Bonsoir, Monsieur le Ministre. ",
          "- Bonsoir, mon cher. Alors, où en êtes-vous de vos recherches sur cette ",
          "mystérieuse disparition ? ",
          "Pablo déteste le ton condescendant avec lequel le Ministre le considère. "
          +"Mais il n’a d’autre choix que de faire contre mauvaise fortune bon cœur. "
          +"Il pense un instant à Claire qui lui reprochait fréquemment son manque "
          +"de diplomatie dans ce genre de situation. Mais il a justement choisi ce "
          +"métier car les tableaux sont patients avec lui. Ils ne lui posent pas de "
          +"questions indécentes, ne font jamais pression sur lui et sont muets face "
          +"à ses colères.",
          "Il décide d’abréger la conversation. "
          +"- Je n’ai pas encore de résultats précis, Monsieur le Ministre. J’en  "
          +"saurai plus dans quelques jours sans doute. Je suis d’ailleurs en train  "
          +"de procéder à des examens sur la Joconde. ",
          "- Je vous donne trois jours monsieur Esteban, pas un de plus. ",
          "Un silence. ",
          "La voix est ferme, sans concession. ",
          "Pablo voudrait lui répondre mais le Ministre le coupe dans son élan. ",
          "- Trois jours, Monsieur et pas un de plus. Si je n’ai aucun résultat "
          +"d’ici là, je vous décharge de la restauration de ce tableau. La France "
          +"et le Musée du Louvre ne peuvent pas se permettre de se passer de la "
          +"Joconde. Nous avons besoin de cette toile pour attirer les touristes et "
          +"les fidéliser. C’est notre meilleure tête de gondole. ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Seule",
          paragraph: 
          ["Et puis il y eut sa mort. ",
          "Subite, presque inattendue. Pas à cause du cancer qui le rongeait. "
          +"Inattendue car elle ne pensait pas qu’il mourrait d’un infarctus "
          +"foudroyant. Elle aurait souhaité un temps supplémentaire pour pouvoir "
          +"justement se préparer à sa disparition. ",
          "Mais ce temps ne lui avait pas été donné. ",
          "Et une fois sa mort survenue, il y eut ce rendez-vous chez le notaire.  ",
          "Et cette lettre. Pourquoi ne lui avait-il jamais parlé de tout cela bien  "
          +"avant ? ",
          "Florence marche doucement. Le plancher craque sous ses pas. Des rais de "
          +"lumière immobiles percent l’obscurité relative de la pièce. La poussière "
          +"qu’elle fait bouger dans son sillage apparaît subitement sous l’effet "
          +"des rayons du soleil. ",
          "Elle dirige son regard vers les volets à persiennes. Elle ne veut pas "
          +"les ouvrir. Pas encore. Pas tout de suite. C’est trop tôt. "
          +"Elle veut vivre ces derniers moments d’intimité avec cette absence que "
          +"la mort n’a pas  encore emportée. Elle a besoin de cela pour calmer sa "
          +"douleur et donner du sens à tous les souvenirs qui remontent "
          +"mmanquablement à la surface.",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Florence au Louvre",
          paragraph: 
          ["Il est vingt-deux heures trente. Un des gardiens effectue sa première "
          +"ronde nocturne. La routine. Comme tous les soirs, il pénètre dans la "
          +"salle de la Joconde. Comme tous les soirs, il passe machinalement devant "
          +"Mona Lisa. Comme tous les soirs, il la croise sans vraiment la voir. Ce "
          +"n’est pas du mépris, elle le sait bien. ",
          "Juste l’habitude d’être avec des gens que l’on croise tous les jours. "
          +"Des gens que l’on pense connaître et dont on ne sait même pas qu’ils "
          +"sont encore vivants. ",
          "Le gardien porte ses mains à la bouche puis éternue violemment. Il jure "
          +"puis se mouche tout aussi bruyamment. Il quitte la pièce et continue son "
          +"circuit. Il n’entend pas cet infime craquement derrière lui. Il ne voit "
          +"pas cette petite fumée qui sourd doucement du tableau. Il ne distingue "
          +"pas cette odeur si particulière qui embaume l’air. Il est trop enrhumé "
          +"pour sentir quoi que ce soit. Il est trop lié par l’habitude pour prêter "
          +"attention aux multiples craquements qui peuplent le Musée du Louvre "
          +"près la fermeture au public.",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Et si tu existais ?",
          paragraph: 
          ["- Bien, maintenant que vous avez votre réponse concernant la Joconde, "
          +"pouvons-nous enfin discuter de vous ? demande le professeur Jourdain. "
          +"Claire acquiesce d’un signe de tête indique au professeur de s’asseoir "
          +"au bord du lit. Dans le même temps, elle pianote un nouveau texto. ",
          "Etonnant, non ? ",
          "- Quoi donc ? ",
          "La Joconde ! ",
          "- Vous recommencez avec ça ? C’est une obsession ! ",
          "Claire pianote à la va-vite. ",
          "Je l’ai vue il y a 2 jours au Louvre. Elle était intacte ! C’est bizarre ",
          "cette disparition aussi soudaine, vous ne trouvez pas ",
          "- Non, je ne trouve pas ça bizarre. Ce tableau a près de cinq cents ans. ",
          "Il a subi des altérations au fil du temps et aujourd’hui, la Joconde "
          +"disparaît. Voilà, ce n’est pas plus compliqué que ça. "
          +"Il tousse pour s’éclaircir la voix. ",
          "- Il y a une question que je voulais vous poser avant de vous parler du "
          +"résultat des examens : avez-vous réellement parlé à cette femme comme "
          +"elle me l’a affirmé ? ",
          " ",
          "Oui. Et pas qu’une fois. A l’entrée du métro aussi. ",
          "Vous lui avez parlé plusieurs fois ? demande le professeur Jourdain interloqué. ",
          "Oui. A l’entrée du métro où je l’ai vue pour la première fois. J’ai été "
          +"étonnée car je pensais avoir retrouvé ma voix. Et puis non… ",
          "Elle tente de lui parler pour lui montrer que c’est impossible. Elle "
          +"fait une moue désabusée puis pianote à nouveau sur son portable. "
          +"Et si c’était elle ? ",
          "",
          "A suivre ..."
          ]
        }
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['pigments/viewState'];
      },
      set(newState) {
        return this.$store.commit('pigments/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.title {
  color: white;
}
.paragraphe {
  font-size: 16pt;
  text-align: justify;
  color: antiquewhite
}
</style>