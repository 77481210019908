<template>
  <v-container v-if="viewState.albumDsply == true">
    <v-carousel cycle height="800" hide-delimiters class="carousel">
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/clos-luce-art.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/clos-luce-bedroom.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/clos-luce-kitchen.jpeg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/ComeBackJoconde.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/flug-machine.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/Frederique Hebrard.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/La Joconde dans La Depeche du Midi.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/la-joconde.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/ldv.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/machine-volante.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/03-pigments/Photo 4 de couv des pigments.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['pigments/viewState'];
      },
      set(newState) {
        return this.$store.commit('pigments/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>

.carousel {
  border-radius: 5px;
  border: 3px solid lightgrey;
  background: rgba(100,100,100,0.8);
}

</style>