<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row>
      <v-col>
        <v-card
          dark
          class="elevation-4"
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Ici nous abordons les raisons qui m'ont amené à l'écriture de L.D.B.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text>
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              « L.D.B. », je ne peux donner la signification sans vous révéler l’intrigue du roman…
              Par contre, je peux vous parler du lieu-dit, le Bois joli de Martel : il existe réellement.
              Mes grands-parents paternels l’ont acheté en 1972, 
              soit seize ans après que mon grand-père soit revenu de la guerre d’Indochine.
              <br><br>
              D’une ruine, il en a fait une maison où j’ai vécu parmi les plus beaux souvenirs de mon enfance. 
              Mon grand-père paternel m’a appris beaucoup de choses, notamment que l’on ne plantait jamais les arbres pour soi, 
              mais pour les autres. Les pins, que nous avons plantés ensemble en 1980, sont encore là aujourd’hui. 
              Ils mesurent une quinzaine de mètres et continuent leur course vers le ciel.
              <br><br>
              Durant l’été 2011, je me suis rendu au Bois joli de Martel, au lever du jour, avec mes enfants. 
              Je voulais qu’ils découvrent cet endroit, qu’ils touchent du doigt ce que j’ai ressenti dans ces lieux. 
              Ce ne fut pas vraiment un partage, plutôt un passage de témoin : transmettre une émotion à mes enfants.
              <br>
              Au moment d’arriver sur les lieux, je me suis dit que j’allais peut-être croiser mon grand-père, 
              dans son potager, alors qu’il est mort en 1998. Cette pensée, si simple en apparence, 
              mais tellement délicate car elle touche au souvenir de ceux que l’on aime, m’a ému aux larmes.
              <br><br>
              J’ai compris bien plus tard que je venais chercher les poussières d’amour qu’il avait égarées dans le potager à mon intention.
              Ce roman est né de ces graines, récoltées un matin d’été, 
              et semées tout au long des pages que vous allez lire quand le roman sera entre vos mains.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      le95: false,
    };
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["ldb/viewState"];
      },
      set(newState) {
        return this.$store.commit("ldb/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
</style>