<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    <v-row justify="center">
      <v-col align="center">
        <v-card dark outlined class="pa-5">
          <v-card-title justify="center">
            <h1 style="width: 100%; text-align: center; color: AntiqueWhite">
              En savoir davantage sur "Le murmure des attentes"
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="color: AntiqueWhite; font-size: 16pt; text-align: justify;">
            <p style="text-align: justify; width: 650px; margin: 0 auto; padding: 15px;">
                <br>
                <v-icon>mdi-forward</v-icon>
                <a 
                  href="http://prisons-cherche-midi-mauzac.com/varia/presence-indochinoise-a-la-poudrerie-de-bergerac-12390" 
                  title="Présence Indochinoise à la poudrerie de Bergerac"
                  style="color: wheat;">
                  Présence Indochinoise à la poudrerie de Bergerac
                </a>
                <br><br>
                <v-icon>mdi-forward</v-icon>
                <a 
                  href="http://amisbergerac.canalblog.com/archives/2009/07/13/14127672.html" 
                  title="Le murmure des attentes - Article sur l'explosion de la poudrerie de Bergerac" 
                  style="color: wheat;">Article sur l'explosion de la poudrerie</a>
                
                <br><br>
                <v-icon>mdi-forward</v-icon>
                <a 
                  style="color: wheat;" 
                  href="http://prisons-cherche-midi-mauzac.com/varia/la-poudrerie-nationale-de-bergerac-dune-guerre-a-lautre-4627" 
                  title="Le murmure des attentes - Articles sur la poudrerie de Bergerac" >
                  Articles sur la poudrerie
                </a>
                <br> 
                <em>
                Ce lien présente l'évolution de la poudrerie de Bergerac 
                et présente des photos très intéressantes qui montrent la vie à la poudrerie. 
                On y voit notamment les deux cheminées de la chaufferie qui sont aujourd'hui détruites.
                </em>
                <br><br>
                <v-icon>mdi-forward</v-icon>
                <a href="http://www.travailleurs-indochinois.org/morts.htm" 
                title="Tombe d'un supplétif indochinois" 
                style="color: wheat;">Tombe d'un supplétif indochinois tué lors du bombardement de mars 1944</a>
                
                <br><br>
                <v-icon>mdi-forward</v-icon>
                <a 
                  href="http://www.anai-asso.org/NET/document/anai/historique/les_militaires_indochinois_en_europe_19141918/index.htm" 
                  title="Déclaration de guerre de 1914 et ses conséquences en Indochine" style="color: wheat;">
                  Un lien</a> 
                  très intéressant sur la déclaration de guerre de 1914 et ses conséquences en Indochine
               <!-- 
                  <audio src="http://www.philippe-nonie.com/SiteLesPigmentsDeternite/Audio/agression-tableau-mona-lisa-par-une-tourriste-russe.mp3" autoplay controls></audio> 
                -->
                <!-- 
                <object  type="audio/mpeg" data="http://www.philippe-nonie.com/SiteLesPigmentsDeternite/Audio/agression-tableau-mona-lisa-par-une-tourriste-russe.mp3" width="400px" height="35px">
                    <param name="src" value="http://www.philippe-nonie.com/SiteLesPigmentsDeternite/Audio/agression-tableau-mona-lisa-par-une-tourriste-russe.mp3"/>
                    <param name="autoplay" value="true"/>
                    <param name="loop" value="false"/>
                </object>
                -->
                <br/>

            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["murmure/viewState"];
      },
      set(newState) {
        return this.$store.commit("murmure/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.parag {
  color: AntiqueWhite;
  text-align: justify;
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>