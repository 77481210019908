<template>
  <v-dialog
      v-model="comingSoonDlg.dsplyState" width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Bientôt disponible
        </v-card-title>

        <v-card-text style="font-size: 14pt;">
          <br>
          Suite à un incident technique avec la société OVH, le site web doit être entièrement reconstruit.
          <br><br>
          Cet espace sera bientôt disponible.
          <br>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="comingSoonDlg.dsplyState = false"
          >
            Compris
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'ComingSoon',
  data() {
    return {
    }
  },
  computed: {
    comingSoonDlg:{
      get() {
        return this.$store.getters['GET_COMING_SOON_DIALOG']
      }, set (newDlg) {
        return this.$store.commit('SET_COMING_SOON_DIALOG', newDlg) 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
