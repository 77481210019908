<template>
  <v-container v-if="viewState.lecteursDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title>
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de lecteurs
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              « Les pigments d’éternité», On a défiguré la Joconde !
              <br>
              ARTS PLASTIQUES 5ème Image, œuvre et fiction
              <br><br>
              1 2
              <br><br>
              1-Léonard de Vinci, Portrait de Mona Lisa, dit « la Joconde »,
              entre 1503 et 1506, peinture à l’huile sur panneau de bois de
              peuplier de 77x53cm. Musée du Louvre à Paris.
              <br>
              http://www.louvre.fr/oeuvre-notices/portrait-de-lisa-gherardini-epouse-de-francesco-del-giocondo
              <br>
              2-Roman de Philippe Nonie, éditions Paul et Mike, 2013
              <br><br>
              Enoncé :
              <br>
              Dans son roman, paru en 2013, Philippe Nonie imagine qu’en son
              temps Léonard de Vinci a inventé un procédé révolutionnaire
              capable d’enfermer le modèle dans le tableau qui le représente : «
              les pigments d’éternité ». Mona Lisa aurait ainsi traversé les
              siècles, attendant son heure pour revenir à la vie dans notre
              époque.
              <br><br>
              A la fin du roman, la Joconde réintègre sagement le panneau de
              peuplier sur lequel elle fut peinte. Imaginez alors ce que
              s’exclamèrent les visiteurs du Louvre : « c’est bien elle, mais on
              a défiguré la Joconde ! »<br>
              Montrez-nous ce qui a pu susciter une telle exclamation. Comment
              la Joconde revient-elle marquée de son passage dans notre époque ?
              Quelles traces conserve-t-elle de 2014 ?
              <br>
              Etapes à suivre :
              <br>
              -Dans le cahier d’arts plastiques réfléchissez à la façon dont
              vous allez articuler l’idée de figurer et de défigurer.
              <br><br>
              Deux séances en classe, format A3, travail individuel.
              <br>
              Vous utiliserez les matériaux, les outils, les moyens de votre
              choix. Vous pouvez découper, coller, déchirer, scanner,
              photocopier, décalquer, agrandir, fragmenter, peindre…."
              <br><br>
              Voilà....à suivre....
              <br><br>
              C'est aussi une façon de te dire combien j'ai apprécié ton roman!"
              Je te souhaite une bonne continuation et à bientôt j'espère.
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Nous nous sommes rencontrés dans le magasin Cultura de
              Pau-Lescar, il y a de cela un mois. Vous m'aviez dédicacé mon
              livre, "Les pigments d'éternité" que ma mère m'a offert pour mon
              anniversaire. Je tenais à vous écrire afin de vous féliciter d'une
              part,pour votre livre qui m'a énormément plu. Mais également, je
              voulais vous remercier car vous m'avez redonner le goût de la
              lecture, en effet, il y a fort longtemps que je n'avais pas dévoré
              un livre en si peu de temps. Je me suis même surprise le soir à
              profitrer de lire plutôt que de regarder la télévision ! Je vais
              m'empresser d'acheter L'inconnue ! Continuez dans cette voie à
              nous procurer tant de plaisir et d'émotion !!"
              <br><br>Bien cordialement, Caroline<br>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "bonjour, j'ai reçu vendredi "les pigments d'eternité" je l'ai
              commencé hier soir et je ne l'ai quitté qu'une fois terminé. Que
              dire ? j'ai la chance de donner bénévolement des cours d'histoire
              de l'art à des enfants. et le sfumato je leur en parle en long en
              large et en travers (de leonard aussi d'ailleurs). Je trouve
              l'idée géniale et même, s'agissant de Leonard, réaliste. Bref j'ai
              vraiment pris un grand plaisir à vous lire et à découvrir pourquoi
              ce fascinant petit tableau illumine le Louvre encore de nos jours.
              Merci pour ces pages de poesie."

              <br><br>Pascale<br>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Mon très cher Philippe Nonie, j'ai hélas terminé ton livre les
              "pigments d'éternité", je l'ai adoré, même si c'est un roman je ne
              pourrai plus jamais regardé la Joconde comme avant. Et comme nous
              parlions ce soir avec mon mari et mes filles, je t'admire avoir
              cette imagination, ses idées, je me dis que l'on nait écrivain,
              c'est un don et pour tout ça merci, merci de m'envouter avec tes
              livres, et je te le dis vivement ton prochain roman!!! Merci
              Philippe. Bises"

              <br><br>Peggy<br>
            </p>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-text>
            <p class="avis-lecteur">
              "Ça y est ! j ai fini les pigments d eternite super ! j ai adore !
              bravo philippe continue".
              <br><br>Cécile<br>
            </p>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Encore un roman avec un univers totalement différent de "Carnets
              d'esprit" et une nouvelle réussite. Une très très belle histoire
              avec des personnages attachants, vivants et une Joconde que je ne
              pourrai plus regarder de la même façon. Je l'entendais, la voyais,
              la touchait presque avec vos mots. Dépêchez-vous de nous faire le
              prochain. J'ai hâte."
              <br><br>Christelle<br>
            </p>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Bonsoir Philippe,<br>

              Ce n'est pas sans émotion que j'ai tourné la dernière page de
              votre livre.....une merveilleuse et mystérieuse aventure que j'ai
              vécue au fil des pages, toujours curieuse d'aller plus loin... le
              voyage s'est achevé dimanche ... jour où les jours d'hiver
              devenaient des jours d'été ... "un jour d'été ne peut imaginer
              qu'il vieillira et deviendra avec le temps un jour d'hiver" ...
              difficile de ne pas être touchée par cette phrase , elle est
              magnifique ... en lisant la génèse de votre roman j'ai compris
              votre hésitation quant au choix du titre et trouvé des réponses à
              quelques questions que je me posais......pas toutes....... je suis
              encore toute imprégnée de ces "pigments d'éternité" et ma
              prochaine visite au Louvre aura forcément une autre dimension.....
              Beaucoup d'émotion dans votre récit, beaucoup de sujets
              abordés.... j'ai ressenti, appris, réfléchi, compris.....difficile
              de revenir dans l'instant présent après ce voyage dans le
              temps...... merci Philippe et à très vite de vous lire ...."
              <br><br>Gaëlle<br>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["pigments/viewState"];
      },
      set(newState) {
        return this.$store.commit("pigments/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.avis-lecteur {
  color: AntiqueWhite;
  font-size: 16pt;
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
}

.avis-lecteur p {
  color: AntiqueWhite;
  text-align: justify;
}
</style>