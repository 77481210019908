<template>
  <v-app >

    <!-- Vue SEO Friendly -->
    <!-- https://dzone.com/articles/how-to-make-vuejs-website-seo-friendly -->
    <!-- https://zestedesavoir.com/articles/3096/ameliorer-son-score-seo-avec-vuejs-nuxtjs/ -->

    <!-- <v-navigation-drawer app>
    </v-navigation-drawer> -->

    <!-- <v-app-bar app>
    </v-app-bar> -->

    <!-- Sizes your content based upon application components -->
    <v-main :style="getTheme()">
      <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <v-row justify="center" >
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <h1>
            <span v-if="this.$route.path == '/'">Philippe Nonie</span>
            <!-- <span v-if="this.$route.path == '/inconnue'"> - L'inconnue</span> -->
            <!-- <span v-if="this.$route.path == '/carnets'"> - Carnets d'esprit</span> -->
            <!-- <span v-if="this.$route.path == '/pigements'"> - Les pigments d'éternité</span> -->
            <!-- <span v-if="this.$route.path == '/lemurmuredesattentes'"> - Le murmure des attentes</span> -->
            <!-- <span v-if="this.$route.path == '/ldb'"> - L.D.B.</span> -->
            <!-- <span v-if="this.$route.path == '/lespierresdememoires'"> - Les pierres de mémoires</span> -->
            <!-- <span v-if="this.$route.path == '/lesmotsdessalines'"> - Les mots des salines</span> -->
            <span v-if="this.$route.path == '/biographie'"> Philippe Nonie - Mon histoire</span>
          </h1>

        </v-col>
        <v-col cols="2"></v-col>
      </v-row>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-0 ma-0">
        <!-- vue-router -->
        <router-view></router-view>
        <!-- <router-view/> -->
      </v-container>
    </v-main>



    <!-- FOOTER -->
    <v-footer >
      
      <v-row >
        <!-- LIGNE 1 -->
        <v-col cols="3" class="pa-0 ma-0 pl-3" align="start">
          Philippe Nonie © 2021
        </v-col>
        <v-col cols="3" class="pa-0 ma-0">
        </v-col>
        <v-col cols="6" class="pa-0 ma-0 pr-3" align="end">
          <v-btn text to="/">Accueil</v-btn>
          |
          <v-btn text to="/biographie">Ma biographie</v-btn>
        </v-col>

        <!-- LIGNE 2 
        <v-col cols="2" class="pa-0 ma-0 pl-3" align="start">
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
        </v-col>
        <v-col cols="2" class="pa-0 ma-0 pr-3" align="end">
        </v-col>
        -->
      
        <!-- LIGNE 3 -->
        <v-col cols="2" class="pa-0 ma-0 pl-3" align="start">
          Tous droits réservés
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          {{ visitCounter }} visites depuis le 27 Avril 2011
          <!-- <v-btn
            @click="setCounterValue"
          >
            Set Counter
          </v-btn> -->
        </v-col>
        <v-col cols="2" class="pa-0 ma-0 pr-3" align="end">
          <v-btn text href="mailto:philippe.nonie@gmail.com">Me contacter</v-btn>
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>
// @ is an alias to /src
import countapi from 'countapi-js';

// Essai 1
// https://api.countapi.xyz/create?namespace=philippe-nonie.fr&value=180000
// namespace : philippe-nonie.fr
// key : ded907b3-8f8e-4a4d-8ff8-e0c5ab563a5f
// initialValue : 180 000

// Essai 2
// https://api.countapi.xyz/create?namespace=www.philippe-nonie.fr&value=198000&enable_reset=0
// namespace : www.philippe-nonie.fr
// key : 6857f823-b1f9-420f-9a7c-7475716b337c
// value : 198000

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
    } //- return
  },
  mounted() {    
    this.getCounterValue()
    this.incrementCounter()
  },
  methods: {
    incrementCounter() {
      countapi.update('www.philippe-nonie.fr', "6857f823-b1f9-420f-9a7c-7475716b337c", +1)
      .then((result) => { 
        this.visitCounter = result.value
       });
    },
    decrementCounter() {
      countapi.update('www.philippe-nonie.fr', "6857f823-b1f9-420f-9a7c-7475716b337c", -1)
      .then((result) => {
        this.visitCounter = result.value
      });
    },
    setCounterValue() {
      countapi.set('www.philippe-nonie.fr', "6857f823-b1f9-420f-9a7c-7475716b337c", 180000)
      .then((result) => { 
        this.visitCounter = result.value
      });
    },
    getCounterValue() {
      countapi.get('www.philippe-nonie.fr', "6857f823-b1f9-420f-9a7c-7475716b337c" )
      .then((result) => {
        this.visitCounter = result.value
      });
    },
    getTheme() {
      if(this.$route.path == '/inconnue') {
        return "background: #353535;";
      
      } else if(this.$route.path == '/carnetsdesprit') {
        return `background: url(${require('@/assets/img/carnets/danger-zone.jpg')})`;
      
      } else if(this.$route.path == '/pigmentsdeternite') {
        return `background: url(${require('@/assets/img/pigments/vitruve-painted.png')});background-position: top center; background-repeat: no-repeat;`;
      
      } else if(this.$route.path == '/lemurmuredesattentes') {
        return `background: url(${require('@/assets/img/lemurmure/body-background-le-murmure-des-attentes.png')});background-position: top center; background-repeat: no-repeat;`;
      
      } else if(this.$route.path == '/ldb') {
        return `background: url(${require('@/assets/img/ldb/fond-home-ldb.jpg')});background-position: top center; background-repeat: no-repeat;`;
      
      } else if(this.$route.path == '/lpdm') {
        return `background: url(${require('@/assets/img/lesPierresDeMemoires/pyrenees-strato.png')}); background-color: rgb(42,59,79); background-position: top center; background-repeat: no-repeat;`;
      
      } else if(this.$route.path == '/lmds') {
        return `background: url(${require('@/assets/img/lesMotsDesSalines/salin-bg-fondu.png')}); background-color: rgb(182,126,137); background-position: top center; background-repeat: no-repeat;`;
      }
    }
  },
  computed: {
    visitCounter: {
      get() {
        return this.$store.getters["GET_COUNTER"];
      },
      set(newVal) {
        return this.$store.commit("SET_COUNTER", newVal);
      },
    },
  }
}
</script>

<style>

html, body {
  background-color:rgb(20, 35, 110);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
