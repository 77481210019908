<template>
  <v-container v-if="viewState.lecteursDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title>
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de lecteurs
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Que dire de L.D.B. .... J'ai tout simplement adoré ! Dès les
              premières pages je me suis dis que je n'allais pas mettre
              longtemps pour le lire et puis j'ai pris mon temps.... Je me suis
              crue avec mon père et ma grand-mère au pays basque et
              contrairement à l'héroïne mes souvenirs étaient plutôt l'odeur des
              piments, la récolte du miel un rituel que nous faisions ensemble
              avec mon père, les odeurs de cuisine et les petites attentions de
              ma grand-mère et du coup bien d'autres souvenirs. Je n'ai pu
              retenir mes larmes. Très troublant. C'est donc grâce à vous et à
              votre livre que je me suis délectée de souvenirs et de moments
              retrouvés avec les miens. Je ne pense pas d'ailleurs être la seule
              à avoir vécu ce transfert et à avoir été transportée dans le passé
              ..... si présent. Merci, merci pour cette délicieuse lecture.
              L.D.B. est excellent dans tous les "sens" du terme. Amicalement
              France"

              <br /><br /><strong>France</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Une histoire touchante qui comme la couverture du livre m'a
              retournée la tête. J'ai perdu mes grands-parents, puis mes
              parents. Ils me manquent. Dans la vie, je pense que nous sommes
              toujours seuls. Mais dans les moments critiques comme la fin de
              vie, le sommes-nous vraiment ? Ce livre nous rappelle que la vie
              passe vite, qu'il ne faut pas oublier de vivre chaque jour comme
              le dernier, de profiter pleinement des êtres qui nous sont chers
              avant qu'il ne soit trop tard. Ce livre nous replonge dans les
              souvenirs de notre enfance, nous permet de revivre des sensations
              uniques. L'auteur à travers son écriture nous fait passer de
              fortes émotions. Il trouve les mots justes, et met tous nos sens
              en éveil : la vue, l'odorat, le goût… Comment résister à une
              écriture qui exprime tant de fraicheur et de sensualité ? Un
              auteur généreux qui partagent des sens dignes de toutes les
              estimes. Merci Monsieur Philippe Nonie ! Merci aussi à mon ami
              Jiji Magne de Barcelone qui a partagé avec moi ce beau roman. Il
              m'a fait découvrir cet auteur que je vais suivre, un livre que je
              partagerai avec mes proches et notamment mes élèves. Patrice
              Guignet - Prof. Documentaliste. C.D.I. Lycée Louis Feuillade -
              34-Lunel."
              <br /><br />
              <strong>Patrice Guinet</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              Un très beau roman. Avec les mots de l'auteur, je me suis souvenu
              de ceux de ma propre enfance. Un roman qui m'a ramené à mes
              souvenirs d'enfance sur un très joli thème auquel nous avons tous
              pensé : revoir ceux qui sont mots et que nous aimons. A lire
              absolument.
              <br /><br />
              <strong>Jean-Sébastien</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              Un roman magistral. Je l'ai dévoré en une nuit. Bravo l'artiste !

              <br /><br /><strong>Martine</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              Ca y est, je l'ai terminé ! L'histoire est géniale, tendre, belle,
              touchante. On sent l'odeur des tomates, de la terre, je sentais la
              fraîcheur de l'eau. Un roman écrit comme une pyramide égyptienne.
              On croit avoir trouvé la solution et on est dans une impasse.
              Bravo pour le suspense.
              <br /><br /><strong>Isabelle</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              Ca y est, je l'ai terminé ! L'histoire est géniale, tendre, belle,
              touchante. On sent l'odeur des tomates, de la terre, je sentais la
              fraîcheur de l'eau. Un roman écrit comme une pyramide égyptienne.
              On croit avoir trouvé la solution et on est dans une impasse.
              Bravo pour le suspense.
              <br /><br /><strong>Isabelle</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              Un magnifique roman qui a fondu dans mes mains comme un bonbon
              dans la bouche. Vivement le prochain que je m'évade à nouveau
              grâce aux très belles histoires que vous écrivez.
              <br /><br /><strong>Valérie</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Je viens de finir L.D.B. ! Je l'ai dévoré en 4 jours ! Un roman
              magnifique qui nous fait réfléchir au sens de la vie et à se poser
              des questions ! Ce que l'on vit est-il réel ? la frontière en rêve
              et réalité est peut être plus mince que l'on croit !"
              <br /><br /><strong>Carine</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "J'ai fini ton livre il y a deux jours....et je me ressens un vide
              de ne plus le retrouver le soir, car finalement je me sentais bien
              dans ce jardin de grand père !!! Je me suis prise au jeu de
              l'histoire au fur et à mesure. C'est incroyable comment tu
              entortilles le temps et retombe sur tes pattes....j' ai été
              soufflée par ta chute : bravo, tu mérites tes récompenses..."
              <br /><br /><strong>Béatrice</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Je te remercie pour ce très agréable moment de lecture. J'ai
              passé avec "L.D.B." des heures plongées dans mes propres
              souvenirs. Au-delà de tes mots, c'est les miens que je
              construisais avec mes propres souvenirs".
              <br /><br /><strong>Jean-Sébastien</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Bonsoir Philippe, Je viens de terminer LDB j'ai adoré, ça a fait
              remonté des souvenirs d'enfance et ça rappelle des bons souvenirs
              et des périodes phares dans la vie. Je ne regarderai plus les
              pieds de tomate de la même façon! Ce livre m'a beaucoup plu une
              fois de plus a la fin on est surpris! J'aime vos livres car il
              ramène à des choses oubliées et amène à une réflexion à chaque
              fois notamment par rapport à ceux qui nous ont quittés et qui
              vivent en nous à jamais..."
              <br /><br /><strong>Anne</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "L.D.B."… un livre remuant et émouvant , qui nous concerne tous.
              La mort , celle de nos proche la notre… comment nous interprétons
              la réalité… ou ce qui est notre réalité et comment cette réalité
              agit sur nos choix les plus essentiels. à un moment du roman ,
              j'imaginais la suite … j'avais des idées induites par des
              histoires déjà vues . et bien que nenni, avec Nonie faut aller au
              delà de tout ce qui a été imaginé!!!! on s'attend à un truc et
              puis Boum! on est dérouté ! c'est ça un bon moment de lecture ,
              quand on commence à lire et qu'on finit par dévorer et que le
              bonhomme de chemin qu'on pensait suivre est dérouté … on ne
              s'ennuie pas, quoi … et en plus il y a tous ces souvenirs de
              petits plaisirs enfouis , que tu réveilles et qui ont désengourdi
              (ça se dit?) les miens… c'est tout doux…"
              <br /><br /><strong>Sophie</strong>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["ldb/viewState"];
      },
      set(newState) {
        return this.$store.commit("ldb/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.avis-lecteur {
  color: AntiqueWhite;
  font-size: 16pt;
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
}

.avis-lecteur p {
  color: AntiqueWhite;
  text-align: justify;
}
</style>