<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row>
      <v-col>
        <v-card
          dark
          class="elevation-4"
          style="
            text-align: center;
            padding: 0 0 35px 0;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Pour lire les raisons qui m'ont amenées à la création du roman
            <strong>Le murmure des attentes</strong>, <br />
            cliquez sur le titre de la rubrique qui vous intéresse.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col lg="4" md="5" sm="12">
        <v-btn x-large active @click="le95 = false">
          <v-icon color="primary" v-if="le95 == false">mdi-eye</v-icon> 
          La Génèse
        </v-btn>
      </v-col>
      <v-col lg="4" md="5" sm="12">
        <v-btn x-large @click="le95 = true">
          <v-icon color="primary" v-if="le95 == true">mdi-eye</v-icon> 
          Trouver un titre
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text v-if="le95 == false">
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              « Le Murmure des Attentes », publié chez Lucane Editions, 
              n’échappe pas à la règle d’écriture de tous mes romans :<br>
              Il est né d’un « fait divers », le plus divers d’entre tous sans doute. 
              Je devais aller chercher ma fille, Chloé, à son collège à 15h00 
              et je n’ai pu venir la chercher avant 17h00. 
              Elle a donc dû faire deux heures d’étude et m’a retrouvé à la sortie du collège 
              particulièrement furieuse.<br>
              - J’ai attendu deux heures papa, tu entends deux heures en étude ! 
              <br>
              Tu imagines ?
              <br>
              Et là, j’ai tilté !
              <br>
              - Un roman sur l’attente, ce serait une belle idée, non ? Qu’en penses-tu ?
              <br>
              Au même moment, à la radio, France Info annonçait la libération de la journaliste 
              <br>
              Florence Bouvier. Et là, un thème s’est rajouté : 
              celui de l’attente d’une journaliste dans une cave blessée de Syrie. 
              <br><br>
              J’ai commencé à écrire le roman, un peu au hasard, 
              et mes personnages ont commencé à sortir de terre.
              <br>
              Deux mois plus tard, les grandes vacances commençaient 
              et nous sommes partis chez mes parents à Bergerac. 
              <br>
              Et là, je suis tombé par le plus pur des hasards sur un article 
              de journal dans « Le Démocrate », qui parlait de supplétifs indochinois 
              déportés en 1915 pour construire la poudrerie de Bergerac. 
              <br>
              J’ignorais totalement cet événement alors même que mon père 
              a travaillé dans cette poudrerie pendant près de 10 ans. 
              <br><br>
              En évoquant le sujet avec lui, il ignorait d’ailleurs cette réalité.
              J’ai décidé d’enquêter sur cette réalité que j’ignorais 
              et que je n’ai jamais apprise à l’école de l’Alba à Bergerac 
              lorsque j’étais gamin. J’ai tout appris sur l’histoire de la Dordogne, 
              tout sauf ces hommes qui n’ont eu droit à aucune phrase, aucun mot, aucune virgule. 
              <br>
              L’histoire les a oubliés pendant près de 97 ans 
              avant qu’une plaque commémorative ne soit apposée par la ville de Bergerac.
              <br>
              Après la lecture de cet article, j’ai donc été à la médiathèque sur les recommandations d’une amie, 
              Karine Sageaux. Et là, j’ai découvert la réalité de ces hommes qui ont fait sortir la poudrerie de terre…
              <br>
              J’ai donc intégré cela à mon récit et le personnage de Hô s’est naturellement imposé.
              La suite, vous la découvrirez en lisant mon roman !
            </p>
          </v-card-text>
          <v-card-text v-if="le95 == true">
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              Le premier titre que j’ai donné au roman était « Attentes »,
              mais je trouvais qu’il n’exprimait pas bien tout ce que j’allais raconter dans le roman. 
              <br><br>
              Certaines de ces attentes ressemblaient à des murmures, pour certaines inavouables, pour d’autres inaudibles. 
              <br><br>
              Et l’attente de ces Indochinois, mais aussi de tous ceux qui attendent, 
              aujourd’hui comme hier, me semblait nécessiter un titre qui incite le lecteur à prendre le livre. 
              <br><br>
              C’est ainsi que le titre « Le Murmure des Attentes » s’est naturellement imposé. Et mon éditeur, 
              Lucane éditions, ne l’a pas remis en cause.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      le95: false,
    };
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["murmure/viewState"];
      },
      set(newState) {
        return this.$store.commit("murmure/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
</style>