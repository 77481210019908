<template>
  <v-container>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="2">
          <v-img src="@/assets/img/BiographiePhoto.png" width="200"></v-img>
        </v-col>
        <v-col cols="10">
          <p style="text-align: justify;">
            <strong>Naissance</strong><br>
            Je suis né le 19 novembre 1965 à Angoulême au bord de la Charente (un autre fleuve). J’ai grandi dans le sud-ouest de la France en Dordogne et en Gironde notamment.
          </p>
          <p style="text-align: justify;">
            Séjour en Iran : un moment marquant de ma vie<br><br>
            En 1977, mes parents ont pris une décision qui a eu une influence considérable sur ma vie : ils ont fait le choix de partir à l’expatriation en Iran où mon père a travaillé pour le compte d’une société française.
            Je me rappelle du soir où ma mère m’a annoncé la nouvelle en me disant : « Ça te dirait qu’on parte vivre en Iran la semaine prochaine ? »
            Il est clair que çà me disait car je venais de prendre…quatre heures de « colle » pour un devoir de maths que je n’avais pas fait.
          </p>
        </v-col>
        
      </v-row>
      <v-row>
        <v-col cols="12">
          <p style="text-align: justify;">
            J’ai donc annoncé le lendemain à mon prof de maths et devant toute la classe que je ne pourrai pas faire les heures de colle car je partais en Iran. 
            Je ne pense pas avoir été crû…Comme quoi, c’est quand on dit la vérité que l’on vous croit le moins.
            Du jour au lendemain, je me suis retrouvé en plein désert et durant deux ans, je n’ai pas mis les pieds dans une école. En revanche, ce que j’ai appris là-bas, aucune école n’aurait pu me l’enseigner : la découverte du désert, 
            les tourbillons de chaleur l’été, le hurlement des loups la nuit pendant l’hiver 1978-1979, le goût de la solitude, les paysages, la chaîne de l’Elbrouz en hiver avec le Damavent du haut de ses 5.800 mètres, les ballades en moto dans le désert avec les copains dont le plus âgé avait quatorze ans, la découverte de villes magnifiques comme Ispahan et Shiraz, des sites archéologiques majeurs comme la Vallée des Rois, Persépolis, les Tours du Silence à Yazd et un peuple d’une grande hospitalité.
            
            C’est en Iran que j’ai connu le premier tremblement de terre en 1978. Il y a eu des milliers de morts et heureusement nous habitions dans un immeuble anti-sismique. J’ai un souvenir cocasse de cet instant car ma mère me faisait faire une dictée et mon stylo a dérapé sur la feuille pendant le tremblement de terre. Je l’ai envoyé tel quel au Centre National de Télé Enseignement en France pour la correction avec une flèche qui indiquait : « j’ai dérapé à cause d’un tremblement de terre ». Quinze jours après, la correction du prof revenait lapidaire : « vous vous foutez de ma gueule ? ».
            Puis la situation à l’intérieur du pays a commencé à se dégrader début 1978. J’ai vu les chars prendre position dans les rues, des gens être arrêtés sans ménagement. J’ai vu mes copains iraniens commencer à chavirer vers Khomeiny. L’Ambassade de France nous a évacués et nous avons quitté l’Iran le 17 janvier 1979, le lendemain du Shah d’Iran. Quand l’avion a décollé de l’aéroport de Mehrabad, les iraniens qui fuyaient l’Iran ont applaudi. C’est la seule fois de ma vie d’ailleurs que j’ai entendus des passagers applaudir au décollage d’un avion…Pourtant, j’ai pris très souvent l’avion par la suite.
            <br><br>
            <strong>Etudes et travail</strong><br>
            J’ai fait un DEUG de sociologie à l’Université de Bordeaux II, une licence et une maîtrise de communication sociale à l’Université de Bordeaux III et un DESS en communication des organisations également à Bordeaux III qui m’a donné l’occasion de franchir l’Atlantique pour aller à l’Université Laval à Québec lors d’un colloque.
            Puis j’ai fait une formation en ergonomie à l’Institut National de Recherche et de Sécurité (INRS).
            Aujourd’hui, je travaille comme préventeur dans une Mutualité Sociale Agricole. Je m’occupe d’améliorer les conditions de travail et de sécurité tant des salariés que des exploitants agricoles. C'est un travail que j’apprécie tout particulièrement car il donne un sens à ma vie.
            <br><br>
            <strong>Vie familiale</strong><br>
            Je suis marié et nous avons deux enfants, un garçon et une fille qui ont 11 et 14 ans. Nous vivons dans un petit village des Pyrénées.
          </p>
        </v-col>
      </v-row>

      

      
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>