<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    <v-row justify="center">
      <v-col align="center">
        <v-card
          dark
          class="elevation-4 mb-3 grey darken-4"
          outlined
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Ci-dessous, se trouve une sélection de liens connexes à mon roman.<br>
          </v-card-text>
        </v-card>
        <v-card dark outlined class="pa-5">
          <v-divider></v-divider>
          <v-card-text style="color: AntiqueWhite; font-size: 16pt; text-align: justify">
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["lmds/viewState"];
      },
      set(newState) {
        return this.$store.commit("lmds/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.parag {
  color: AntiqueWhite;
  text-align: justify;
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>