<template>
  <v-container v-if="viewState.sampleDsply == true">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <span style="color: white;">Voici une sélection d'extraits de mon roman <u>Carnets d'Esprit</u></span>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
        dark
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <v-window-item
            v-for="(s, idx) in samples"
            :key="idx"
          >
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <!-- <v-avatar
                    color="grey"
                    class="mr-4"
                  ></v-avatar> -->
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">{{ s.titre }}</strong>
                  </v-col>
                  
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
  
                <p v-for="(paragraph, idxp) in s.paragraph" :key="idxp" class="paragraphe">
                  {{ paragraph }}
                  <v-img src=""></v-img>
                </p>

              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "Quatrième de couverture",
          paragraph: 
          ["27 avril 1986. Léonid est un journaliste Ukrainien originaire de Prypiat. "
          +"Il travaille à Kiev pour La Pravda, le journal officiel du Parti "
          +"Communiste. Ce jour-là, il est contraint d’écrire la dépêche comme quoi "
          +"il ne s’est rien passé à Tchernobyl dans la nuit du 26 au 27 avril 1986. "
          +"Il ne le sait pas encore mais cette dépêche va se révéler comme une terrible"
          +"déchirure dans sa vie.",
          ""
          +"Vingt-cinq ans plus tard : Léonid est marié et a deux enfants. Il est"
          +"devenu journaliste indépendant et vit en France. Il émarge à la rubrique"
          +"des chiens écrasés quand un journal parisien lui propose de faire un"
          +"reportage sur Tchernobyl vingt-cinq ans après. En dépit des risques liés à"
          +"la radioactivité, Lénonid accepte… ",
          ""
          +"Au travers des reportages qu’il va faire "
          +"mais que l’on devine seulement par le biais de ses pensées et des «carnets "
          +"d’esprit» des «liquidateurs», sa femme, ses enfants et les lecteurs de ses "
          +"articles vont découvrir comment cet homme et sa famille ont été broyés "
          +"par la castastrophe de Tchernobyl. ",
          ""
          +"Dans le même temps, Léonid va rencontrer le journaliste qu’il est véritablement…",
          "A suivre ..."
          ]
        },
        {
          titre: "Annonce SMS",
          paragraph: 
          ["Tout a commencé avec ce SMS. "
          +"Bonjour Léonid. Pourriez-vous passer à mon bureau jeudi vers 11h00 ? ",
          ""
          +"J'ai quelque chose à vous proposer. ",
          ""
          +"JCR ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Réponse SMS",
          paragraph: 
          ["C'est lundi et comme tous les lundis, je suis en train de déjeuner à Dame Tartine. ",
          ""
          +"Dame Tartine, c'est un restaurant parisien qui a posé ses valises depuis déjà un bon moment à côté de Beaubourg. "
          +"J'ai placé mon portable sur la table à côté de moi. "
          +"Il y a encore peu de monde pour le moment. "
          +"J'observe les mobiles de Tinguely et les femmes de Niki de Saint Phalle s'animer dans le bassin qui se trouve à proximité immédiate. "
          +"J'ai programmé mon portable en mode vibreur pour ne pas déranger mes voisins par une sonnerie intempestive. "
          +"Soudain, il s'anime et se tortille comme un petit animal doué de vie. "
          +"C'est depuis cet instant d’ailleurs que j'ai donné un prénom à mon téléphone. "
          +"Je l'ai appelé Pinocchio. ",
          ""
          +"Pourquoi Pinocchio ? "
          +"Parce qu'en mode vibreur, mon mobile me fait penser à la marionnette en bois de Gepetto lorsqu'elle devient vivante après qu’une fée lui ait donné la vie. "
          +"Mon portable est de la même trempe. "
          +"Un appel téléphonique, un texto reçus en mode vibreur le font ressembler à un pantin doué de vie. ",
          ""
          +"Bonjour Léonid. Pourriez-vous passer à mon bureau jeudi vers 11h00 ? J'ai quelque chose à vous proposer. J.C.R",
          ""
          +"J.C.R. C'est Jean-Claude Roux, le P.D.G d'un grand quotidien gratuit qui est distribué tous les matins dans les couloirs du métro parisien et de plusieurs grandes villes de France."
          +"J.C.R. Notre première rencontre.",
          ""
          +"C’était il y a deux ans, lors du vernissage de l’exposition d'une artiste russe. C'était dans le huitième arrondissement, je crois. J'y étais pour couvrir cet événement pour son journal. C'était la première fois que je travaillais pour ce quotidien en tant que journaliste indépendant. J'avais été appelé, je suppose, parce que je parle couramment le russe, pas forcément parce que j’étais journaliste.",
          "",
          ""
          +"Tous nos écrits d’alors étaient scrupuleusement disséqués par Sergueï, un membre permanent du K.G.B, détaché à la rédaction du journal à Kiev. Sergueï n'avait aucune compétence pour rédiger un article de presse. Ce n’est pas d’ailleurs ce qu’on lui demandait. Il avait été recruté pour son oeil acéré capable de détecter les dissidents potentiels entre les lignes. Et un empressement encore plus certain pour les dénoncer auprès de ses supérieurs. Il faut avoir connu ce contexte pour juger des conditions dans lesquelles nous avons été amenés à exercer notre métier. Si on peut appeler cela un métier.",
          ""
          +"        Car depuis j'ai toujours considéré qu'on ne peut pas écrire dans la peur. Ou alors il faut posséder ce que François Mauriac appelait très justement le courage de papier. Et je n’ai jamais eu ce courage. Car le courage de papier en Union Soviétique donnait droit à un aller simple pour un camp de rééducation. Mais je m’égare tant la rancœur est tenace.",
          ""
          +"Revenons à J.C.R."
          +"",
          "A suivre ..."
          ]
        },
        {
          titre: "Carnet d'esprit d'Igor",
          paragraph: 
          ["…Ivan. Je l’entends gémir par moments. "
          +"Il veut peut-être me parler. Comment savoir ? "
          +"Le son de ses plaintes est trop limité pour comprendre s’il s’agit de souffrances ou de paroles."
          +"J’aimerais lui répondre, le réconforter mais je n’y arrive pas. "
          +"Mes cordes vocales ne fonctionnent plus. Elles ont été brûlées par la radioactivité. ",
          "Il faut dire que je l’ai côtoyé de tellement près sans jamais la voir."
          +"J’ai un service à vous demander,"
          +"à vous et à tous ceux qui pourraient entendre ou lire le carnet d’esprit que je suis en train de rédiger : "
          +"Aidez-moi à lui parler ! "
          +"Soyez l’interprète de mes mots. Celui qui porte ma parole. "
          +"Ça me rappelle ma prof de grec quand elle nous parlé de Hermès, le dieu des messagers."
          +"Vous voulez bien être Hermès l’espace d’une minute ?",
          "Carnet d’esprit"
          +"Vous pensez sans doute qu’il s’agit là d’une expression étonnante. "
          +"En fait, c’est un journal intime que je tiens dans mon cerveau. "
          +"Je ne peux pas l’écrire car mes mains sont brûlées par la radioactivité. "
          +"Et je ne peux pas le dicter non plus car mes cordes vocales ont cessé à jamais de fonctionner. "
          +"J’espère simplement que quelqu’un pourra pénétrer mon esprit et rapporter mon témoignage avant que je ne quitte ce monde. ",
          ""
          +"A suivre ..."
          ]
        },
        {
          titre: "Journal Intime de Léonid",
          paragraph: 
          ["Vingt-six avril 1986. Tchernobyl. "
          +"J’étais journaliste en Ukraine à la Pravda au moment où c’est arrivé. "
          +"Pravda ? "
          +"Ah oui, c’est vrai, vous ne parlez pas forcément ma langue. "
          +"En russe, pravda çà veut dire vérité. "
          +"Vérité ! Ce mot était-il possible après ce qui s’est passé ? "
          +"En d’autre termes, pouvais-je dire la vérité sur ce qui s’est réellement déroulé à Tchernobyl ? "
          +"Si j’avais fait ce choix, cela serait revenu à démonter les rouages d’un système politique où le journaliste d’investigation avait autant de chances de survie qu’un homme seul sur la Lune. "
          +"Pourtant, avec le recul, Tchernobyl n’a t-il pas été le signal de départ d’un empire qui s’est écroulé ?",
          ""
          +"Quelques jours après la catastrophe, j'écoute comme beaucoup de mes concitoyens le discours de Gorbatchev à la télévision d’Etat."
          +"Il choisit de dire la vérité sur ce qui s’est réellement déroulé à la centrale nucléaire de Tchernobyl dans la nuit du 26 au 27 avril 1986. "
          +"C’est une première dans mon pays. Si la catastrophe de Tchernobyl était survenue sous Staline, pareil discours politique aurait été inimaginable."
          +"Mais là, c’était l’époque de la pérestroïka."
          +"Ah oui, c’est vrai, j’ai à nouveau oublié que vous ne comprenez pas forcément ma langue natale. "
          +"Car vous l’avez compris, le russe est ma langue natale. ",
          ""
          +"Perestroïka en russe signifie reconstruction, restructuration. "
          +"Vous imaginez ce que ça représentait ?",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Carnet d'esprit de Grichka",
          paragraph: 
          ["Tu te rappelles de l’Apocalypse selon Saint Jean versets 10 et 11 ? "
          +"L’absinthe est le nom de la météorite qui s’écrase sur terre et empoisonne l’eau des sources. "
          +"Rappelle-toi, nous l’avons étudié en secret avec le pope quand nous étions enfant. "
          +"Je me souviens encore aujourd’hui de ta voix quand tu as lu ce passage. "
          +"Car ma mémoire est intacte. "
          +"C’est la seule chose que la radioactivité n’a pas encore affectée. "
          +"Le troisième ange sonna de la trompette. "
          +"Et il tomba du ciel une grande étoile ardente comme un flambeau, "
          +"et elle tomba sur le tiers des fleuves et sur les sources des eaux.",
          ""
          +"Le nom de cette étoile est Absinthe, et le tiers des eaux fut changé en absinthe, "
          +"et beaucoup d’hommes moururent par les eaux, parce qu’elles étaient devenues amères. ",
          ""
          +"Tchernobyl. "
          +"Les Ukrainiens disent que le nom de cette ville fait référence à son apparence noire en été et blanche de neige en hiver. "
          +"La centrale atomique de Tchernobyl est une fleur, Léonid."
          +"Une fleur vénéneuse."
          +"Une absinthe noire qui contamine les hommes et les tue irrémédiablement depuis la tragédie qui s’est déroulée à la centrale. ",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Prypiat-24 décembre 1985",
          paragraph: 
          ["Ce fut le dernier soir. "
          +"Notre dernier soir ensemble. "
          +"Mais, entendons-nous bien : il y a deux sortes de derniers soirs. "
          +"Il y a ceux, dont on sait pertinemment que c'est la dernière fois, "
          +"et cela n'arrive quasiment jamais. Et pour cause. Personne ne connaît son destin.",
          "Et puis, il y a les derniers soirs dont on ne sait pas encore que ce sera la dernière fois. "
          +"Ce fut notre cas avec mon frère et mes parents.",
          "Il y a toutefois un dénominateur commun aux deux types de derniers soirs : "
          +"c'est cette idée sous-jacente de la mort qui est directement liée à la dernière fois. "
          +"C'est le fait que cette occurrence ne se produira plus jamais dans l'histoire de l'univers en général "
          +"et dans la trajectoire d’un être humain en particulier. "
          +"Un terme permet d'exprimer avec une grande justesse les derniers soirs connus "
          +"et les derniers soirs dont on ne peut savoir que ce sera le dernier. "
          +"C'est la nostalgie. ",
          ""
          +"La nostalgie d'un moment vécu, la nostalgie d'un instant qui ne reviendra plus jamais. "
          +"C'est ainsi, qu'aujourd'hui, j'analyse ce dernier réveillon de Noël passé avec mon frère et mes parents. "
          +"C'était le dernier soir tous ensemble dont aucun d'entre nous ne pouvait imaginer un seul instant que ce serait le dernier. "
          +"Mon frère est arrivé en même temps que moi. "
          +"Mes parents étaient sur le seuil de leur appartement pour nous accueillir. "
          +"Mon père avait dans ces cas-là l'élégance de toujours se tenir un peu en retrait afin de laisser à ma mère "
          +"le privilège d'embrasser ses enfants en premier.",
          "La table était mise et des bougies avaient été disposées afin de faire un repas aux chandelles. "
          +"Cette ambiance tamisée était l'idée de ma mère qui aimait bien mettre en scène nos repas de famille. "
          +"C'était sa façon à elle de manifester, sans le dire, sa joie de nous voir à nouveau réunis. "
          +"C'est Grichka qui a lancé la conversation de manière tout à fait anodine et, aujourd'hui encore, "
          +"elle raisonne dans mon esprit comme un avertissement de ce qui allait advenir par la suite. "
          +"Pourtant, c'est une intime conviction chez moi, je le sais, rien n'est écrit. "
          +"La vie est comme un article de presse que l’on doit rédiger : c’est une feuille blanche qu’il nous appartient de remplir. ",
          "",
          "A suivre ..."
          ]
        },
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['carnets/viewState'];
      },
      set(newState) {
        return this.$store.commit('carnets/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.paragraphe {
  font-size: 16pt;
  text-align: justify;
}
</style>