<template>
  <v-container v-if="viewState.sampleDsply == true">

    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <span style="color: white;">Voici une sélection d'extraits de <u>L'Inconnue</u></span>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
        dark
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <v-window-item
            v-for="(s, idx) in samples"
            :key="idx"
          >
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <!-- <v-avatar
                    color="grey"
                    class="mr-4"
                  ></v-avatar> -->
                  <strong class="title">{{ s.titre }}</strong>
                </v-row>
  
                <p v-for="(paragraph, idxp) in s.paragraph" :key="idxp" class="paragraphe">
                  {{ paragraph }}
                  <v-img src=""></v-img>
                </p>

              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>




    <v-row justify="center">
      <v-expansion-panels accordion dark>
        <!-- <v-expansion-panel v-for="(item,i) in 5" :key="i"> -->
        <!-- Panel 01 -->
        <v-expansion-panel>
          <v-expansion-panel-header><strong>Le Fort de Tavannes</strong></v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
            Ce fort existe réellement et a été utilisé par les Poilus durant la première guerre mondiale. Mon arrière grand père maternel Emile Dupin a combattu dans ce fort contre les allemands d’avril à octobre 1916 durant la bataille de Verdun.<br>
            Il a été tué par un obus le 09 octobre 1916. 
            J’ai voulu lui rendre hommage dans ce chapitre dont vous pourrez d’ailleurs trouver un extrait sur mon site.<br>
            La lettre écrite dans le chapître est une carte que mon arrière grand père a réellement rédigé et envoyé à mon arrière grand-mère lorsqu’il était au Fort de Tavannes. 
            Il l’a écrite alors même que les allemands pilonnaient le Fort de Tavannes.<br>
            Vous pourrez d’ailleurs voir des cartes de cette époque qui témoignent de la violence des sentiments patriotiques en cliquant ici. 
            Ces cartes font partie de ma mémoire familiale et m’ont été transmises par ma grand-mère. 
            Je les ai classées dans un carnet de guerre que j’ai dédicacé à mes enfants.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 02 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>La Rencontre</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
            Ce passage se déroule en Iran dans un lieu où j’ai vécu pendant deux ans quand j’étais gamin. 
            J’ai été particulièrement marqué par le désert qui en Iran ressemble à un paysage minéral. 
            Cela m’a profondément changé de la verdure de la Dordogne où j’avais jusqu’alors grandi.<br>
            L’Iran, c’est un pays superbe où j’ai connu la Révolution à la fin des années soixante-dix. 
            Ce fut un moment assez étrange de voir des chars dans les rues, des soldats défier les manifestants.<br>
            Pourtant, l’Iran que j’ai connu ne ressemble pas à l’Iran que l’on voit dans les journaux télévisés. 
            Une petite anecdote : en 1978, nous sommes revenus en Iran en voiture et un copain à mon père a eu un accident de la circulation à Tabriz. 
            Il s’est endormi au volant de son véhicule et s’est retrouvé dans un fossé. 
            Des iraniens sont sortis de leur maison et nous ont proposés de rentrer chez eux. 
            Ils nous ont offerts à manger et nous ont gardés jusqu’à l’arrivée de la dépanneuse. 
            Je n’oublierai jamais leur gentillesse et leur hospitalité.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 03 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>28 Octobre 1578</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
            Ce chapitre présente une histoire réelle : le détournement de l’Adour. 
            L’Adour est un fleuve essentiel dans ma vie. Mes parents se sont rencontrés sur les bords de l’Adour. 
            J’habite moi même en bordure de l’Adour en un lieu où le fleuve est encore un torrent.<br>
            Ce fleuve a été détourné par la main de l’homme le 28 octobre 1578 et ne se trouve plus dans le Marensin.<br>
            Le Marensin, c’est pour la petite histoire un bout de terre des Landes d’où est originaire toute la famille de ma mère.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 04 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Le départ de l'action</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
            Pourquoi l’Australie ?<br>
            Il me semblait que la côte ouest australienne se prêtait bien au début du roman pour son isolement. 
            Pourtant, je n’y ai jamais mis les pieds ! 
            J’ai repéré les lieux en me servant de Google Earth et en descendant au plus près des lieux que je décris.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "Le fort de Tavannes",
          paragraph: 
          ["« Le 09 octobre 1916.\nCher Catherine, je réponds à ton aimable lettre que j’ai reçue avec un grand plaisir. "
          +"Le voyage de retour s’est très bien passé. Seulement, c’est le départ quand je t’ai quitté à la gare de Taller à toi et à Emilie. "
          +"Oh, quelle peine j’avais sur l’estomac toute la journée. Depuis, je suis revenu à ce maudit fort. "
          +"Dehors, le jour doit se lever. Mais dedans, il fait toujours nuit. "
          +"Nous sommes enfermés dans le Fort de Tavannes. "
          +"Malgré l’épaisseur des murs, on entend le canon qui tonne constamment et les obus pleuvoir sur le fort. "
          +"Les murs tremblent. Il ne fait pas bon sortir par le tunnel d’entrée. "
          +"Les boches le prennent pour cible. Malheur aux hommes qui l’empruntent pour nous ravitailler ou pour monter en ligne. "
          +"Nous sommes obligés de nous acheter des bougies si nous voulons écrire parce que dans le fort, il fait toujours noir et dehors c'est impossible de survivre à cause des obus. ",
          "Il faut rester comme ça pendant 10 jours comme si on était en prison avant la relève. Le jour avant d’arriver, il a été bombardé avec des gros obus le fort de Tavannes. "
          +"On l’a esquinté. "
          +"Avec une autre rafale comme celle là, il est foutu. "
          +"Malheur à ceux qui seront dedans. "
          +"Je songe toujours au dernier jour de cette maudite guerre."
          +"Si je pouvais la voir en bonne santé, quelle joie ce serait pour moi de rentrer dans notre maison du Bruca. "
          +"Quant à moi, ma santé va bien pour le moment. Mille baisers. A toi pour la vie. Emile. »",
          "Il donne sa carte à un camarade de régiment blessé qui va être évacué à l’arrière. "
          +"Il lui demande d’acheminer le courrier à bon port. Jean-Baptiste Laporte acquiesce."
          +"Il prend la lettre et promet de la remettre en main propre. "
          +"Comme lui, son camarade est originaire des Landes. "
          +"Il vient de Mont-de-Marsan. ",
          "Emile le remercie. Le visage tuméfié de son camarade est souligné par la lueur incertaine des bougies. "
          +"Il se dit que cette maudite guerre n’en finira jamais."
          +"Voilà plus de deux ans qu’elle a commencé et personne n’en voit raisonnablement la fin. "
          +"Mais, il faut continuer à se battre et à se battre encore. "
          +"Il serre promptement son camarade contre sa poitrine. "
          +"Aucun mot n’est nécessaire pour exprimer le désarroi et la lassitude qu’ils ressentent. "
          +"Les brancardiers le soulèvent sans ménagement et Jean-Baptiste Laporte disparaît dans le tumulte du fort qui se prépare à l’imminence d’une attaque allemande. ",
          "Emile observe les hommes autour de lui."
          +"Des visages hagards, défigurés pour certains, concentrés pour d’autres. "
          +"Il respire profondément. Il veut vivre. Jusqu’ici, il n’a jamais été blessé. "
          +"Mais les combats font rage autour du fort. Il sait qu’il en est de même au fort de Vaux et de Douaumont. "
          +" La guerre est partout. Les hommes là-bas ne sont pas mieux lotis.",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "La Rencontre",
          paragraph: [
            "Hubert contemple les alentours. Il est seul. "
            +"Aucune habitation en vue. Pas âme qui vive. Au loin, "
            +"un tourbillon de chaleur se forme et s’élève majestueusement " 
            +"dans le ciel. C’est un événement somme toute courant au début de l’été. "
            +"Avec son pied, il soulève doucement la poussière qui retombe comme de la farine sur le sol. "
            +"Une fourmi s’échine à escalader le monticule."
            +"Ses pattes semblent glisser plus qu’accrocher sur la matière friable."
            +"Elle est là, seule, comme égarée. Il se relève et prend conscience de l’immensité autour de lui."
            +"Rien, rien que lui et le désert. "
            +"Pas un bruit, juste une petite brise. "
            +"Hubert se met à rire. Face à l’étendue qui"
            +"l’entoure, il se fait l’effet d’être pareil à cette fourmi. "
            +"Il est là, loin de tout lorsqu’un objet inhabituel attire son attention. "
            +"À quelques dizaines de mètres de lui, un rouleau en cuir noir élimé est posé sur un rocher qui surplombe une excavation. "
            +"Il s’approche avec curiosité et se saisit prudemment du rouleau. Celui-ci est vierge. "
            +"Pourtant un détail l’étonne : le parchemin est usé. "
            +"On dirait que des milliers d’individus l’ont touché. "
            +"Il le déroule avec précaution et constate que le cuir enroulé ne semble pas avoir de fin. ",
            "Il est stupéfait. À qui peut-il bien appartenir ? ",
            "Qui a bien pu l’oublier dans un endroit aussi désertique ? ",
            "Par quel sortilège le rouleau de cuir semble-t-il se dévider à l’infini ? ",
            "",
            "A suivre ..."
          ]
        },
        {
          titre: "25 Octobre 1578",
          paragraph: [
            "... découvre avec étonnement que l’inconnue ne semble pas vraiment avoir ces attributs-là."
            +"— Tu t’attendais sans doute à une rencontre dans un autre lieu.",
            "Sa vision s’améliore petit à petit. Elle distingue bientôt des branches et des troncs charriés dans l’eau boueuse de l’Adour." 
            +"La largeur du fleuve est impressionnante. C’est un véritable delta.",
            "— Où sommes-nous ? ",
            "— À Boucau-en-Marensin puisque c’est ainsi qu’il faut encore l’appeler.",
            "— Boucau-en-Marensin ! Non, c’est impossible ! "
            +"Cela fait plus de quatre siècles que cet estuaire n’existe plus."
            +"Il a été déplacé par la main de l’homme. ",
            "L’Adour ne peut pas être ici ? Tu en es sûre ? "
            +"Ou serait-il plus correct de dire qu’elle pourrait être ici à ce moment précis ? ",
            "— Quand sommes-nous ? Où sont passés les pins ? ",
            "Pourquoi n’y a-t-il que des landes et des marais alentour ?",
            "— Pas tant d’impatience. Une seule question à la fois. ",
            " ",
            " ",
            "Céline est de plus en plus désorientée par ce que lui renvoient ses sens. "
            +"La végétation est méconnaissable. ",
            "Les Landes ne sont plus les Landes. Le paysage environnant présente un aspect lunaire. ",
            "Il est composé de dunes, de marécages, de buissons, de forêts primaires et d’un fleuve qui n’est plus à sa place. ",
            "Il n’y a pas de route, pas de bitume, pas de voiture, pas de monde normal, pas de trace d’avion dans  le ciel, pas de bruit de la civilisation. ",
            "Maintenant a disparu pour un avant, un autre temps.",
            "— Où sont les pins ? ",
            "— Les pins n’existent pas encore tout simplement. ",
            "Ils n’ont été plantés qu’au XIXe siècle. ",
            "— Comment ça pas encore ? ",
            "— Il est encore bien trop tôt pour que les pins existent.",
            "— Mais quel jour sommes-nous ? ",
            "— Très précisément ? ",
            "Oui ! ",
            "— Le 25 octobre 1578. ",
            "Oui ! ",
            "Céline reste interdite. Le sens de tout ceci lui échappe.",
            "— Mais alors mes parents sont morts ! ",
            "L’inconnue se met à rire. Un rire franc, massif, intense qui couvre un moment le tumulte de l’eau toute proche.",
            "— Il faut être né pour mourir ! Non, rassure-toi, ils ne sont tout simplement pas encore.",
            "Céline inspire profondément. Cette rencontre dépasse l’entendement.",
            "Elle se trouve aux portes de la vie, dans un temps où elle ne devrait pas être, dans un lieu qui n’a rien à voir avec ce qu’il sera un jour. ",
            "Et pourtant, aussi incroyable que cela puisse paraître, tout se tient. ",
            "La réalité du moment correspond au chemin du rêve du tableau de Mati.",
            "L’interprétation de la toile était la bonne. Céline se trouve là où l’inconnue l’a conviée.",
            "Oui, mais pourquoi ici et pas ailleurs ?  Pourquoi maintenant ?"
            +"Pourquoi quatre siècles en arrière qui ne seront plus qu’un souvenir oublié de tous les manuels d’histoire ?",
            "— Où est Xavier ?",
            "",
            "A suivre ..."
          ]
        }
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['inconnue/viewState'];
      },
      set(newState) {
        return this.$store.commit('inconnue/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.paragraphe {
  text-align: justify;
}
</style>