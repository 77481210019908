import Vue from 'vue'
import Vuex from 'vuex'

import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueRouter from 'vue-router'
import router from './router'
import store from './store'

// import count from './count'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(vuetify)
Vue.use(Vuex)


new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')
