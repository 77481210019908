<template>
  <v-container v-if="viewState.albumDsply == true">
    <v-carousel cycle height="800" hide-delimiters class="carousel">
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/2012-12-01 14.56.35.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/2013-03-13 13.05.30.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/doors.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/ferry.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/ferry-wheel.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/graffiti.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/park.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/room.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/sign.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/02-carnets/tree.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['carnets/viewState'];
      },
      set(newState) {
        return this.$store.commit('carnets/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>

.carousel {
  border-radius: 5px;
  border: 3px solid lightgrey;
}

</style>