<template>
  <v-container v-if="viewState.albumDsply == true">
    <v-carousel cycle height="800" hide-delimiters>
      <!-- <v-carousel-item
        v-for="(photo,i) in photos"
        :key="i"
        :src="photo.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item> -->
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/01-inconnue/CartePostale01.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/01-inconnue/CartePostale02.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/01-inconnue/CartePostale03.jpg"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['inconnue/viewState'];
      },
      set(newState) {
        return this.$store.commit('inconnue/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style>

</style>