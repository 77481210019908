<template>
  <v-container v-if="viewState.sampleDsply == true">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-card
          dark
          class="elevation-4 mb-3 grey darken-4"
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Voici une sélection d'extraits de mon roman <u>Les mots des salines</u>
          </v-card-text>
        </v-card>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <!-- Extrait 1 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 1 - Dialogue de Sophie & Simon</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                <p class="para-header">...</p> 
                <p class="paragraphe">
                  <br>
                  Six lettres.
                  <br>
                  L’amour est le sel de la vie.
                  <br>
                  Sept mots.
                  <br>
                  Six lettres, sept mots.
                  <br><br>
                  En dépit de la barrière de la langue, de leur condition sociale, Simon et mon père auraient peut-être eu bien des choses à se raconter. Simon aurait pu échanger avec mon papa sur sa conception de la lenteur. Mon père l’aurait emmené dans sa locomotive, les jours de pluie, les jours de beau temps, les jours d’hiver, les nuits d’été, les nuits d’automne. Il aurait convoqué le hasard avec sa pièce de un franc avant de lui laisser les commandes. Il lui aurait enseigné les paysages, désigné ces détails que la vitesse déforme et rend progressivement invisibles à l’œil. Simon aurait peut-être écrit un roman sur ces trains qui flânent en rase campagne, ces cheminots rebelles à la grève qui profitent de chacun des arrêts de travail pour approfondir leur étude de la lenteur. Simon aurait sans doute inventé d’autres mots, ceux qui défilent à présent sous mes yeux, pour décrire ces moments, les donner à voir au monde, les protéger, comme la lenteur, d’une extinction programmée.
                  <br><br>
                  Avec ma mère, il aurait pu aller à Barcelone, prendre de nouveaux clichés de La Sagrada Familia, les ranger dans l’album consacré à cet effet, les comparer aux précédents, rendre au temps cette unité de mesure qui se trouve en voie de disparition, réparer le manque, celui qui nous permet de ressentir le frisson provoqué par chaque instant de la vie.
                  <br><br>
                  Voilà ce qui aurait pu se produire.
                  <br><br>
                  Et qui n'arrivera jamais.
                  <br><br>
                  Comme on dit quelquefois, autant par dépit que par résignation, ces événements se dérouleront peut-être dans une autre existence.
                  <br><br>
                  — Ma mère, reprend Simon, m’a voué aux gémonies quand elle a appris que j’avais emmené mon père en Suisse, non pour le soigner, mais pour lui permettre « de baiser une dernière fois » selon ses propres termes. Ma mère a souvent réduit le monde à sa plus simple expression. Cela la rassure et rend son environnement certainement moins effrayant, plus facile à appréhender. Elle a toujours fonctionné de cette manière : le noir, le blanc, le bien, le mal. J’enviais sa façon de vivre jusqu’à la mort de mon père, celle qui évite soigneusement les conflits intérieurs. Entre le noir et le blanc, il n’y a rien pour elle, aucune place pour le gris, le choix semble facile. Pourtant, le gris et ses différentes nuances, cela sied si bien à Londres et à la Tamise les jours de brouillard.
                  <br><br>
                  — J’ai plus de souvenirs que si j’avais 1000 ans, dis-je en un murmure de mots que le vent disperse un peu trop vite comme jadis au bord de la Tamise.
                  <br><br>
                  — Le deuxième spleen, Les fleurs du mal, susurre Simon en écho. Sophie citait ce vers de Baudelaire autrefois quand je parlais du fog londonien le long des berges de la Tamise.
                  
                  <br><br>
                  À suivre…
                </p>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Extrait 2 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 2 - Eliane</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                <p class="para-header">16</p> 
                <p class="paragraphe">
                  <br>
                  Alors même que le train emprunte le cordon dunaire séparant la mer Méditerranée de l’étang de Thau, je relis les dernières phrases du manuscrit. À l’évidence, le texte écrit par Simon comporte d’étranges similitudes avec la fin de vie de Stéphane. Il y a une différence cependant – et elle est notable car Stéphane n’en est pas arrivé là ou alors je ne l’ai jamais su – lorsque Roger, le personnage principal de son roman, alors même qu’il se sait condamné par la maladie, sollicite les faveurs d’une femme, Éliane. Cette veuve a perdu son mari, officier du Génie dans l’Armée française. Il a sauté sur une mine en 1956, au tout début du conflit algérien. Éliane se rapproche insensiblement de Roger, son voisin, lui prodigue le plaisir qu’elle aurait partagé avec son mari si celui-ci était revenu vivant d’Algérie. C’est en quelque sorte sa revanche sur une société qui a envoyé celui qu’elle aimait se faire tuer dans une guerre qui n’était pas la leur. Ce conflit a dérobé leurs nuits d’amour à venir, moments intimes, projets d’enfants, toute une existence qui a sombré bien au-delà de la veuve qu’elle est devenue.
                  <br><br>
                  Éliane est un personnage qui n’a rien à gagner, plus rien à perdre, et surtout pas la considération des autres. Elle s’en moque bien. Cette posture ne ramènera pas son mari, ni les formules alambiquées de l’Armée française, ni les niaiseries débitées par le prêtre sur un homme – son mari – que Dieu aurait soi-disant rappelé à lui. Chez elle, il y a la nécessité de ne pas sombrer, de composer avec le malheur, continuer à vivre au travers de cette formule inattendue : le sel de la vie. Simon a rédigé l’expression en français. Il l’a utilisée à plusieurs reprises dans son texte et, à chaque fois, je me suis rappelé ce moment anodin, à Londres, où j’ai prononcé ces cinq mots. Cela demeure l'unique moment où j’ai employé ma langue maternelle avec Simon.
                  <br>
                  <em>Le sel de la vie.</em>
                  <br>
                  Comment traduire en anglais une expression idiomatique ?
                  <br>
                  Et celle-ci en particulier ?
                  <br>
                  Voilà bien le casse-tête des traducteurs. Le mien en l’occurrence.
                  <br>
                  Ne pas trahir le sens premier, conserver la pulpe des mots, ce qui constitue leur saveur linguistique.
                  <br>
                  Le sel de la vie !
                  <br>
                  Simon en a fait le titre de son manuscrit. Son éditrice va-t-elle l’accepter ?
                  <br>
                  Un roman écrit en anglais avec un titre en français, voilà qui constituerait une couverture bien singulière au Royaume-Uni.
                  <br>
                  Le sel de la vie.
                  <br>
                  Simon se serait-il souvenu du moment où j’ai prononcé ces mots ?
                  
                  <br><br>
                  À suivre…
                </p>
              </v-card-text>
            </v-card>
          </v-window-item>


          <!-- Extrait 3 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 3 - Interview de Simon</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                <p class="para-header">11</p> 
                <p class="paragraphe">
                  <br>
                  Simon n’est pas devenu architecte.
                  <br>
                  Il a emprunté un autre sentier.
                  <br>
                  Celui de l’écriture.
                  <br>
                  L’interview publiée sur le site internet du journal britannique Daily News apporte des indications aussi précieuses qu’inattendues.
                  <br><br>
                  Notre rédacteur en chef, Alan Smith, a rencontré la semaine passée, à son domicile londonien, l’écrivain anglais Simon Cashburry. Le romancier n’avait plus donné aucune interview dans la presse britannique depuis la parution de son dernier roman, Les demoiselles de Bristol. Lors d’un échange à bâtons rompus, notre journaliste a abordé avec l’auteur à succès le thème de son futur ouvrage sans éluder le drame personnel qui l’a frappé.
                  <br>
                  Alan Smith : Quel est le sujet de votre prochaine histoire ?
                  <br><br>
                  Simon Cashburry : Il parle de la lenteur, celle que met l’eau à s’évaporer avant que le sel ne se précipite sur les parois des salines. L’histoire se déroulera dans le sud de la France, à Sète très précisément.
                  A.S : Pourquoi avoir choisi cet endroit comme cadre de votre prochain roman ?
                  S.C : J’avais besoin d’un site particulier pour cette histoire. Je me suis donc rendu en France il y a plusieurs mois pour visiter des marais salants. Il y a là d’anciens marais salants – les salines de Villeroy – dont l’exploitation a été stoppée en 1969. Ces dernières ont la particularité de se trouver sur un cordon dunaire, précisément enclavées entre la mer Méditerranée et l’étang de Thau. Au lever du jour, on ressent dans ces lieux une sensation d’apaisement, d’abandon, d’histoire du monde. J’y suis venu à plusieurs reprises, le matin, le soir, en journée. Mon histoire a pris corps là, en ces lieux, à observer le travail d’architecture réalisé par les hommes pour piéger le sel, à écouter le bruit du vent, à humer les odeurs d’iode…

                  <br><br>
                  À suivre…
                </p>
              </v-card-text>
            </v-card>
          </v-window-item>


          <!-- Extrait 4 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 4 - Rencontre avec Simon à Sète</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="para-header">18</p> 
                <p class="paragraphe">
                  <br>
                  Après toutes ces années.
                  <br><br>
                  Retrouver cet homme que j’ai tant aimé.
                  <br>
                  Les mots semblent encore bien fragiles pour évoquer ce moment. Le plus gracile d’entre eux, le plus sensible aussi : son prénom. J’avais perdu l’habitude de le prononcer à l’anglaise.
                  <br>
                  Saïmon.
                  <br>
                  Il est là, assis.
                  <br>
                  Les épaules un peu voûtées.
                  <br>
                  Le visage marmoréen.
                  <br>
                  Les yeux dissimulés par des lunettes fumées.
                  <br>
                  Son regard.
                  <br>
                  Je ne le verrai pas, plus jamais. Ni maintenant ni plus tard.
                  <br>
                  Simon entend des pas, les miens – peut-on reconnaître entre tous la signature acoustique d’une personne, ses pas, leur cadence, leur rythme, la manière dont ils touchent ou effleurent le sol ? –, Simon regarde dans ma direction. Je songe à l’écrivain argentin, Jorge Luis Borges. Le romancier a vécu une partie de son existence au cœur d’une obscurité qu’il a ainsi résumée : Dieu m’a donné les livres et la nuit.
                  <br>
                  Cette phrase ferait assurément un très joli titre de roman.
                  <br>
                  Alors même qu’il vivait dans la nuit, une guide a accompagné Jorge Luis Borges en Égypte. Elle a aiguillé ses pas, en aucune façon ses mots. Il est resté maître de ceux-ci jusqu’à la fin.
                  <br>
                  Dans l’obscurité, il est parvenu à écrire ce qu’il ne pourrait plus jamais voir. Parvenu au pied des pyramides, Borges a lâché la main de sa guide, s’est agenouillé avant de s’emparer d’une poignée de sable qu’il a lancée à la volée. Il a alors prononcé ces quelques mots avant de les consigner, plus tard, dans un recueil de nouvelles dont le titre m’échappe à présent : Je viens de modifier le Sahara. J’ai l’intime conviction, qu’ici ou ailleurs, avec du sable ou du sel, au Caire comme à Sète, Borges aurait prononcé les mêmes paroles : Je viens de modifier les salines de Villeroy.


                  <br><br>
                  À suivre…


                </p>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Extrait 5 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 5 - Roger et Eliane</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                <p class="para-header">...</p>
                <p class="paragraphe">
                  <br>
                  <span class="indent">
                    J’utilise mon pouce droit comme catapulte. La pièce s’envole, pivote, virevolte plusieurs fois sur elle-même, chute sur le tranchant, tournoie à la manière d’un patineur artistique sur une piste de glace puis achève sa rotation dans un petit bruit métallique – je songe brusquement à Simon qui a peut-être perçu le tintement et m’en veux de ne pas y avoir pensé plus tôt.
                  </span>
                  <br>
                  Pile.
                  <br>
                  Je fais glisser le curseur de la souris tandis que mon regard donne la main aux mots.
                  <br><br>
                  « Éliane s’avance vers moi. J’aimerais la désirer, lui montrer que ce corps, le mien, m’appartient encore, qu’il ne s’échappera pas comme jadis ces anguilles dans l’étang de Thau. Elles se tapissaient dans les algues, à l’abri des prédateurs, en quête de nourriture. Quand j’en attrapais une avec l’épuisette, j’éprouvais une joie immense. Quand j’en saisissais une entre mes doigts, son corps gluant m’échappait et je ne pouvais la poursuivre longtemps dans l’eau ; je devais remonter à la surface reprendre ma respiration. Mon père était systématiquement agacé par mon rire lors de ces instants de jeu. Je m’enthousiasmais, non de ma maladresse de l’avoir laissée échapper, mais bien au contraire d’avoir réussi à l’attraper. Il me reprochait cette nonchalance, ce côté insouciant. C’était, selon ses dires, un temps inutile, non exploité. Il comparait les secondes de la vie à un monument où chaque pierre donnait de sa personne pour résister au mistral et aux éléments. Tout ce qui n’était pas utile n’avait pas lieu d’être. Au mieux une herbe folle qu’il fallait éradiquer.
                  <br><br>
                  <span class="indent">
                    Mon corps est à son tour devenu une anguille. Il ne cesse de m’échapper. Alors, un soir, plein d’une lassitude que je n’avais jamais connue auparavant, j’ai pris la décision de faire appel à Éliane avant que le plaisir ne devienne une île interdite à jamais. Bientôt, dans trop peu de temps, je n’en aurai plus la force et je tiens à me présenter devant la mort avec la certitude d’avoir été vivant jusqu’au bout. Un peu comme ces soldats refusent de se rendre à l’ennemi parce qu’ils n’ont jamais envisagé aucune autre alternative.
                  </span>
                  <br><br>
                  <span class="indent">
                    Alors, je vais plonger en apnée avec Éliane, une apnée saline, durant quelques minutes, des minutes où le sel et le plaisir vont épingler le temps. Je veux retrouver mes sensations perdues, cet instant d’ultime lenteur. Je devine votre réprobation mais la jalousie vous égare. Éliane n’est pas une « sale pute » comme le laissent croire quelques voisins envieux, ceux qui auraient justement aimé posséder mes parcelles de marais salant. Je ne la paye pas pour baiser, mais pour éprouver du plaisir parce que mon corps m’en réclame encore.
                  </span>
                  <br>
                  <span class="indent">
                    Encore.
                  </span>
                  <br>
                  <span class="indent">
                    La nuance paraît fine, il est vrai. Pourtant, elle existe et possède la forme d’une ligne jaune.
                  </span>
                  <br><br>
                  <span class="indent">
                    J’ai la sensation de me trouver du bon côté, du côté de la vie. Vous n’imaginez pas ce que j’étais – un homme qui a toujours abhorré ce sel maudit –, ni qui je suis devenu : une page impatiente. Il me tarde d’écrire avec Éliane ces quelques mots que ma voix ne prononce plus que par intermittence. Éliane tourne la crémone de ma fenêtre pour créer un courant d’air. J’ai toujours aimé le vent. Cette brise qui anime les rideaux comme le mime un pantin de bois au bout des ficelles. Elle a dû prendre forme quelque part dans l’étang ou, qui sait, dans les salines. Éliane est ma fleur de sel dont je vais enfin goûter la saveur. Elle s’allonge sur moi, nue, ses seins contre mon torse.
                  </span>
                  <br><br>
                  — Tu trembles ? demande-t-elle doucement.
                  <br>
                  — Je n’ai jamais apprécié le goût du sel, Éliane.
                  <br><br>
                  <span class="indent">
                    Les mots ne sortent que lorsqu’ils trouvent un intérêt à le faire. Ils chevrotent, tombent, se relèvent.
                  </span>
                  — Je me suis immergée dans l’étang avant de te rejoindre, mes seins ont pris le goût du sel. Tu devines son parfum ?
                  Elle porte délicatement ses seins à ma bouche, l’un après l’autre, mais je ne peux les gober. J’essaie, mais je n’y parviens plus. Je suis devenu trop faible pour décoller ma tête de l’oreiller.
                  — Attends… chuchote-t-elle.
                  <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                  À suivre…
                </p>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Extrait 6 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 6 - Simon</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>

                <p class="para-header">10</p>
                <p class="paragraphe">
                  Simon.
                  <br><br>
                  Je n’avais plus prononcé son prénom depuis toutes ces années.
                  <br><br>
                  Après notre séparation, j’ai fini par l’emmurer.
                  <br><br>
                  Jusqu’à aujourd’hui.
                  <br>
                  Simon.
                  <br><br>
                  Nous nous sommes rencontrés à Londres, un soir, dans un pub situé à proximité de Picadilly Circus. Il était alors étudiant en architecture, passionné de littérature, par toutes les littératures du monde. Il organisait deux fois par mois – les vendredis soirs – des soirées littéraires auxquelles se rendaient des hommes et des femmes de tous âges et de différentes conditions sociales, toute une société qui se recomposait autour d’un bon livre et d’une bonne bière. La bière représentait ce liant indispensable pour échanger sans fard et en toute convivialité ; elle libérait l’esprit des moins audacieux, permettait de prendre la parole en public. Les soirées étaient chaleureuses, les fous rires, parfois les affrontements verbaux, ne manquaient pas. Simon sélectionnait un auteur de son choix, organisait chaque débat avec la complicité du patron du café, un jeune Londonien. Des guéridons, des tabourets de bar étaient disposés autour d’une table haute où Simon s’installait avec son invité. Il présentait parfois des romans d’ailleurs, des ouvrages américains, australiens, japonais, français. Il militait pour l’ouverture d’esprit et, dans le même temps, ne parlait qu’une seule langue : l’anglais. Simon et ses contradictions. Ce côté paradoxal, ces aspérités du quotidien m’ont donné envie de m’intéresser à lui.
                  
                  <br><br>
                  À suivre…

                </p>
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "Extrait 1",
          paragraph: 
          ["Je ne suis pas né cagot, je le suis devenu. "]
        },
        {
          titre: "Extrait 2",
          paragraph: 
          ["A quatre heures, tout le monde se lève, pas trop vite quand même, "]
        },
        {
          titre: "Extrait 3",
          paragraph: 
          ["J’avance un peu plus pour essayer de comprendre de quoi il retourne ",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 4",
          paragraph: 
          ["C’est lui. ",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 5",
          paragraph: 
          ["Je voudrais renvoyer mon grand-père à son monde ; il n’a plus rien à ",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 6",
          paragraph: 
          ["Les cailloux gémissaient sous la roue cerclée de la brouette. Le jour se ",
          "A suivre ..."
          ]
        }
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['lmds/viewState'];
      },
      set(newState) {
        return this.$store.commit('lmds/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.title {
  color: white;
}
.para-header {
  font-size: 16pt;
  text-align: center;
  color: antiquewhite
}
.paragraphe {
  font-size: 16pt;
  text-align: justify;
  color: antiquewhite
}
.indent {
  margin-left: 30px;
}
</style>