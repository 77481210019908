<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row>
      <v-col>
        <p
          style="
            color: khaki;
            text-align: center;
            padding: 0 0 35px 0;
            font-size: 16pt;
          "
        >
          Pour lire les raisons qui m'ont amenées à la création de
          <strong>Carnets d'esprit</strong>, <br />
          cliquez sur le titre de la rubrique qui vous intéresse.
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col lg="4" md="5" sm="12">
        <v-btn x-large active @click="le95 = false">
          <v-icon color="primary" v-if="le95 == false">mdi-eye</v-icon>  Éclairer les Carnets d'esprit
        </v-btn>
      </v-col>
      <v-col lg="4" md="5" sm="12">
        <v-btn x-large @click="le95 = true">
          <v-icon color="primary" v-if="le95 == true">mdi-eye</v-icon> Le 95 pour écrire
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text v-if="le95 == false">
            <p
              style="
                color: lightgoldenrodyellow;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              Pour tout dire, l’idée même d’écrire un roman sur la plus terrible
              catastrophe nucléaire que l’humanité ait connu m’est venue d’une
              manière assez étrange. Je me suis réveillé le 01 janvier 2010 avec
              ce désir de regarder des reportages sur Tchernobyl. Ce que j’ai
              fait après avoir pris mon petit-déjeuner. Ma femme qui passait par
              là m’a aussitôt dit que je regardais des choses bien tristes pour
              un Premier de l’An. Je lui ai répondu que je ne savais pas
              pourquoi je regardais cela, que j’avais une idée mais que je ne
              savais pas laquelle. <br /><br />Je n’avais pourtant aucune espèce
              d’envie d’écrire sur ce thème. Je venais tout juste de terminer «
              Jours d’été » en première écriture, le roman sur la Joconde. Et
              écrire sur Tchernobyl ne me disait vraiment rien. Pourtant,
              quelque chose que je ne maitrisais pas, un désir très fort, m’a
              incité à poursuivre. J’ai donc continué à regarder ces reportages
              (dont la plupart vous sont proposés en lien hypertexte au bas de
              cet article) et à collecter des informations sur la façon dont
              s’est déroulé le drame dans la nuit du 26 au 27 avril 1986. J’ai
              fait cela pendant 10 jours très exactement, du 01 janvier au 10
              janvier. Puis le 11 janvier, j’ai tout arrêté, brusquement. Sans
              raisons apparentes. Je me suis alors rendu compte que je ne
              désirais plus regarder tout ce qui avait trait de près ou de loin
              à ces évènements dramatiques.<br /><br />
              11 mois se sont ainsi déroulés sans qu’il ne se passe rien. En
              avril de la même année, je découvrais le Prix Nouveau Talent par
              l’intermédiaire du roman de Caroline Vermalle « L’avant-dernière
              chance ». J’ai alors retravaillé « l’Inconnue » durant plusieurs
              mois afin de le présenter au Prix Nouveau Talent pour le 30
              septembre 2010 au plus tard. Et pendant ce temps, je n’ai plus du
              tout repensé à Tchernobyl. Puis, dans la nuit du 19 au 20 décembre
              2010, je me suis réveillé subitement à 03h30 du matin.
              <br /><br />Un mot m’est alors venu : « Carnets d’esprit ». Je
              tenais le titre de mon roman. J’ai alors commencé à « écrire dans
              ma tête » les premières pages. « Carnets d’esprit », ce devait
              être le journal intime que les premiers blessés de Tchernobyl qui
              allaient mourir devaient écrire dans leur tête. Et comme ces mots
              ne pouvaient franchir le barrage de leur cerveau, un homme allait
              les traduire pour les faire connaître au monde. Le 20 décembre au
              matin, je me suis levé et j’ai commencé à écrire de manière
              totalement frénétique. Nous devions aller manger chez mes
              beaux-parents et j’ai dit à mes femme de partir avec les enfants
              et que je les rejoindrai. <br /><br />J’ai commencé à noircir cinq
              pages en une matinée. Puis cela a continué de manière de plus en
              plus intense. J’ai commencé à écrire à tout moment. J’étais
              possédé par le roman. Les personnages se créaient au fur et à
              mesure de l’avancée de l’histoire. Ce roman est le seul de mes
              romans où j’ai eu la sensation que quelqu’un écrivait à travers
              moi. Le soir du Réveillon, le 24 décembre 2010, j’étais immergé
              totalement dans l’histoire tant et si bien que de façon régulière
              je quittais la table précipitamment pour aller écrire.
              <br /><br />Nous étions chez mes parents et cela avait le don
              d’énerver prodigieusement ma mère. Mais je sentais que je n’avais
              pas le choix. Si je n’écrivais pas sur le moment, je risquais de
              perdre l’idée, le mot, la phrase. Jamais autant que là, je n’ai
              ressenti la sensation du néant qui dévore tout si la mémoire n’est
              pas fiable. J’ai écrit pendant quatre mois le jour, la nuit pour
              parvenir à terminer le roman en « première écriture ». Je dis «
              première écriture » car en fait j’écris chaque roman sur un
              premier jet puis je le laisse tomber pendant des mois avant de le
              reprendre en « réécriture ».
              <br />
              C’est ce que j’ai fait également avec « Carnets d’esprit ».
            </p>
          </v-card-text>
          <v-card-text v-if="le95 == true">
            <p
              style="
                color: lightgoldenrodyellow;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              A Noël 2010, je suis parti passer une semaine de vacances chez mes
              parents. J’étais alors en pleine écriture de « Carnets d’esprit »
              et Léonid, le personnage principal, arrivait sur Prypiat. J’avais
              besoin de me rendre dans un lieu assez conséquent abandonné par
              les hommes pour ressentir les émotions de mon personnage au plus
              près.<br /><br />
              J’ai alors décidé de me rendre au « 95 ». Le « 95 » représentait
              mon terrain de jeu quand j’étais gamin. Nous l’avions ainsi
              dénommé avec mes amis d’enfance car, aussi improbable que cela
              pouvait sembler, il y avait un téléphone qui marchait dans cet
              endroit. Je pouvais appeler mes parents car nous étions logés dans
              une maison de fonction et inversement mes parents pouvaient nous
              appeler. Le numéro de ce téléphone était justement le 95. Il a
              donc donné à cet endroit son nom, sachant que ce nom n’est pas le
              nom officiel.<br /><br />
              Le « 95 » est un lieu qui s’étendait sur 14 hectares et a été
              construit en face de la poudrerie de Bergerac (de l’autre côté de
              la route de Sarlat) durant la première guerre mondiale. Quand la
              première guerre mondiale s’est achevé, la construction de ce site
              a été abandonnée et laissé à l’abandon. Les arbres ont alors
              envahi le site et les routes ont progressivement disparu. Le
              réseau de rails de wagonnets à traction humaine qui existait a
              alors été défferé.<br /><br />
              Quand je suis arrivé à Bergerac au début des années 70, le « 95 »
              est très rapidement devenu mon terrain de jeu. Je me figurais que
              la troisième guerre mondiale avait eu lieu et je déambulais dans
              ces 14 hectares de bâtiments inachevés. Des années plus tard, en
              décembre 2010, je suis revenu au « 95 » mais pas pour les mêmes
              raisons.<br /><br />
              J’avais besoin en fait d’avoir un lieu dans lequel je pourrais
              ressentir les émotions de Léonid lorsqu’il arrive à Prypiat. Je
              suis alors venu tous les soirs au « 95 » pour déambuler dans ces
              bâtiments abandonnés et me conditionner à ce qu’allait vivre
              Léonid. J’essayais d’imaginer tous les milliers d’ouvriers (ils
              ont été près de 25.000 en 1918 !) qui ont travaillé sur ces
              lieux…et par comparaison j’imaginais Léonid revenir dans une ville
              fantôme où avaient vécu des dizaines de milliers d’Ukrainiens
              avant la catastrophe de Tchernobyl. Mon personnage avait passé son
              enfance à Prypiat et moi au «95».<br /><br />
              Puis je revenais chez mes parents et je me mettais à écrire de
              façon frénétique. L’espace de quelques instants, j’étais devenu
              Léonid seul face à sa douleur de revenir sur un lieu 25 ans
              après…comme moi je revenais au « 95 » près de 40 ans après…<br />
              Je reste intimement persuadé que cette ambiance a considérablement
              influencé mon écriture.<br /><br />
              Je vous mets d’ailleurs un lien sur l’histoire de la Poudrerie de
              Bergerac (avec une vue aérienne, on distingue d’ailleurs le « 95 »
              sur la gauche de la photo) qui est particulièrement intéressant si
              vous souhaitez le consulter.<br /><br />
              <a
                href="http://prisons-cherche-midi-mauzac.com/varia/la-poudrerie-nationale-de-bergerac-dune-guerre-a-lautre-4627"
                title="Histoire de la poudrerie de Bergerac"
                >Histoire de la Poudrerie de Bergerac </a
              ><br /><br />
              J’ai repris cette technique d’immersion dans un lieu lors de
              l’écriture du manuscrit (non encore publié) « Les pierres de
              mémoire ». Un des lieux du roman est une vieille grange écroulée
              en Charente. Pour écrire ce manuscrit, je suis rentré dans de
              multiples fermes écroulées ou abandonnées, parfois envahis par la
              végétation des Hautes-Pyrénées et du Gers. Un jour, je suis rentré
              dans un vieux moulin abandonné dans le Gers. C’était un endroit
              fabuleux sur une colline. J’ai fermé les yeux, touché les pierres
              pour ressentir la vie, les joies, les drames qui s’étaient
              déroulés ici.<br /><br />
              Et ma plume a de nouveau glissé sur le papier…<br />
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      le95: false,
    };
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["carnets/viewState"];
      },
      set(newState) {
        return this.$store.commit("carnets/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
</style>