<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    <v-row justify="center">
      <v-col align="center">
        <v-card dark outlined class="pa-5">
          <v-card-title justify="center">
            <h1 style="width: 100%; text-align: center; color: AntiqueWhite">
              En savoir davantage sur "Les pigments d'éternité"
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="color: AntiqueWhite; font-size: 16pt; text-align: justify;">
            Je n’ai en aucune façon deviné ce qui allait se passer au Musée du
            Louvre lorsque j’ai écrit la scène où Florence s’adresse à la
            Joconde et qu’elle est expulsée du Musée du Louvre.
            <br><br>
            Cela étant, en août 2009, trois semaines après avoir rédigé ce
            passage en « première écriture », je tombe par hasard sur internet
            sur une dépêche que je vous livre brute ci-dessous.
            <br><br>
            Sur le moment, j’ai fait le lien avec Florence et ce que j’avais
            écrit ! Car, tout comme Florence, cette femme a été amenée devant un
            psychiatre pour voir si elle n’était pas folle après avoir agressé
            la Joconde et lui avoir lancé une tasse ! Depuis, cet évènement ne
            s’est d’ailleurs plus jamais reproduit à ma connaissance.
            <br><br>
            Je vous mets un autre lien où la station de radio RTL en a parlé. 
            <br>
            A écouter absolument!
            <br><br>
            <a
              style="color: wheat"
              href="http://fr.wikinews.org/wiki/La_Joconde_subit_l'attaque_d'une_Russe"
              title="Site de Wikinews"
              >Wikinews - Le tableau de la Joconde attaqué par une Russe</a
            ><br />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["pigments/viewState"];
      },
      set(newState) {
        return this.$store.commit("pigments/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.parag {
  color: AntiqueWhite;
  text-align: justify;
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>