<template>
  <v-row justify="center" class="home ma-0 pa-0">
    <v-col cols="12" align="center" class="pa-0 ma-0">

      <!-- -- -- - -->
      <!-- Livre 7 -->
      <v-row justify="center" align="center" class="mt-3">
        <v-col lg="2" md="1" sm="0" xs="0" class="pa-0 ma-0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" align="center" class="pa-0 ma-0">
          <v-card class="elevation-0" color="rgb(50, 50, 50, 0.1)">
            <v-row justify="center" align="center">
              <v-col
                lg="8"
                md="8"
                sm="8"
                xs="12"
                align="center"
                justify="center"
                class="py-0 my-0"
              >
                <h1>Les mots des salines</h1>
                <v-btn @click="quatLivreSept = !quatLivreSept">
                  4ème de couverture
                </v-btn>
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsLmds"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
              <v-col
                lg="2"
                md="4"
                sm="4"
                xs="12"
                align="center"
                class="py-0 my-0">
                <router-link :to="'lmds'" exact>
                  <v-img
                    class="box"
                    src="@/assets/img/booksCovers/PremiereCouvertureLMDS-250.png"
                  >
                  </v-img>
                </router-link>
              </v-col>
            </v-row>
            <v-dialog max-width="550" v-model="quatLivreSept">
              <v-card>
                <v-img
                  src="@/assets/img/lesMotsDesSalines/QuatriemeCouvertureLMDS-550.png"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0" class="pa-0 ma-0"></v-col>
      </v-row>

      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0" ></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" >
          <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>

      <!-- -- -- - -->
      <!-- Livre 6 -->
      <v-row justify="center">
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" class="pa-0 ma-0" >
          <v-card class="pa-1 elevation-0"  color="rgb(50, 50, 50, 0.1)">
            <v-row align="center">
              <v-col lg="1" md="1" sm="1" xs="1"></v-col>
              <v-col
                lg="2"
                md="3"
                sm="4"
                xs="5"
                align="center"
                class="py-0 my-0"
              >
                <router-link :to="'lpdm'" exact>
                  <v-img
                    class="box"
                    src="@/assets/img/booksCovers/lpdm-couverture-accueil.png"
                  >
                  </v-img>
                </router-link>
              </v-col>
              <v-col
                lg="7"
                md="8"
                sm="7"
                xs="6"
                align="center"
                class="pa-0 ma-0"
              >
                <h1>Les pierres de mémoires</h1>
                <v-btn @click="quatLivreSix = !quatLivreSix">4ème de couverture</v-btn>
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsLpdm"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-dialog max-width="600" v-model="quatLivreSix">
              <v-card>
                <v-img
                  src="@/assets/img/booksCovers/quatrieme-les-pierres-de-memoires.png"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>

      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12">
          <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>


      <!-- -- -- - -->
      <!-- Livre 5 -->
      <v-row justify="center" align="center" class="my-2">
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" align="center" class="pa-0 ma-0" >
          <v-card class="elevation-0" color="rgb(50, 50, 50, 0.1)">
            <v-row justify="center" align="center">
              <v-col
                lg="8"
                md="8"
                sm="8"
                xs="12"
                align="center"
                justify="center"
                class="py-0 my-0"
              >
                <h1>L.D.B.</h1>
                <v-btn @click="quatLivreCinq = !quatLivreCinq">4ème de couverture</v-btn>
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsLdb"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
              <v-col
                lg="2"
                md="4"
                sm="4"
                xs="12"
                align="center"
                class="py-0 my-0">
                <router-link :to="'ldb'" exact>
                <v-img
                  class="box"
                  src="@/assets/img/booksCovers/ldb-bandeau-208.316.png"
                >
                </v-img>
                </router-link>
              </v-col>
            </v-row>
            <v-dialog max-width="600" v-model="quatLivreCinq">
              <v-card>
                <v-img src="@/assets/img/booksCovers/quatrieme-ldb.png">
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>

      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0" ></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" >
          <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>


      <!-- -- -- - -->
      <!-- Livre 4 -->
      <v-row justify="center">
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" class="pa-0 ma-0" >
          <v-card class="pa-1 elevation-0" color="rgb(50, 50, 50, 0.1)">
            <v-row align="center">
              <v-col lg="1" md="1" sm="1" xs="1"></v-col>
              <v-col lg="2" md="3" sm="4" xs="5" class="py-0 my-0">
                <router-link :to="'lemurmuredesattentes'" exact>
                <v-img
                  class="box"
                  src="@/assets/img/booksCovers/couverture.1.lmda.bandeau.208.jpg"
                >
                </v-img>
                </router-link>
              </v-col>
              <v-col
                lg="7"
                md="8"
                sm="7"
                xs="6"
                align="center"
                class="py-0 my-0"
              >
                <h1>Le murmure des attentes</h1>
                <v-btn @click="quatLivreQuatre = !quatLivreQuatre">4ème de couverture</v-btn>
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsMurmure"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-dialog max-width="600" v-model="quatLivreQuatre">
              <v-card>
                <v-img
                  src="@/assets/img/booksCovers/quatrieme-couverture-le-murmure-des-attentes-550.png"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        
      </v-row>

      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0" ></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" >
        <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>

      <!-- -- -- -- -- -- -- -- -- -- -- --  -->
      <!-- Livre 3 : Les pigments d'éternité -->
      <v-row justify="center">
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" class="pa-0 ma-0" >
          <v-card class="pa-1 elevation-0" color="rgb(50, 50, 50, 0.1)">
            <v-row justify="center" align="center">
              <v-col
                lg="8"
                md="9"
                sm="8"
                xs="7"
                align="center"
                class="py-0 my-0"
              >
                <h1>Les pigments d'éternité</h1>
                <v-btn @click="quatLivreTrois = !quatLivreTrois">4ème de couverture</v-btn>
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsPigments"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
              <v-col lg="2" md="3" sm="4" xs="5" class="py-0 my-0">
                <router-link :to="'pigmentsdeternite'" exact>
                  <v-img
                    class="box"
                    src="@/assets/img/booksCovers/les-pigments-deternite-premiere-home.png"
                  >
                  </v-img>
                </router-link>
              </v-col>
            </v-row>
            <!-- Dialog 4ème de Couverture -->
            <v-dialog max-width="600" v-model="quatLivreTrois">
              <v-card>
                <v-img
                  src="@/assets/img/booksCovers/quatrieme-couverture-pigments-eternite.png"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>

      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0" ></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" >
          <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>


      <!-- -- -- -- -- -- -- -- -- -- -->
      <!-- Livre 2 : Carnets d'esprit -->
      <v-row justify="center">
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" class="pa-0 ma-0" >
          <v-card class="pa-1 elevation-0" color="rgb(50, 50, 50, 0.1)">
            <v-row align="center">
              <v-col lg="1" md="1" sm="1" xs="1"></v-col>
              <v-col lg="2" md="3" sm="4" xs="5" class="py-0 my-0">
                <router-link :to="'carnetsdesprit'" exact>
                  <v-img
                    class="box"
                    src="@/assets/img/booksCovers/CouvertureCarnets.png"
                  >
                  </v-img>
                </router-link>
              </v-col>
              <v-col
                lg="7"
                md="8"
                sm="7"
                xs="6"
                align="center"
                class="py-0 my-0"
              >
                <h1>Carnets d'Esprit</h1>
                <v-btn @click="quatLivreDeux = !quatLivreDeux">4ème de couverture</v-btn>
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsCarnets"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
            </v-row>
            <!-- Dialog 4ème de Couverture -->
            <v-dialog max-width="600" v-model="quatLivreDeux">
              <v-card>
                <v-img
                  src="@/assets/img/booksCovers/quatrieme-couverture-carnets-esprit.png"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>

      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0" ></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" >
          <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>


      <!-- -- -- -- -- -- -- - -->
      <!--Livre 1 : L'inconnue -->
      <v-row justify="center">
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" class="pa-0 ma-0" >
          <v-card class="pa-1 elevation-0" color="rgb(50, 50, 50, 0.1)">
            <v-row align="center" justify="center">
              <v-col align="center" cols="9" class="py-0 my-0">
                <h1>L'inconnue</h1>
                <v-btn @click="quatLivreUn = !quatLivreUn"
                  >4ème de couverture</v-btn
                >
                <div class="home-news-container">
                  <p
                    v-for="(actu, idx) in newsInconnue"
                    :key="idx"
                    class="home-news"
                  >
                    <strong>{{ actu.title }}</strong> : {{ actu.content }} -
                    <a :href="actu.linkRef" target="_blank">{{
                      actu.linkText
                    }}</a>
                  </p>
                </div>
              </v-col>
              <v-col lg="3" md="4" class="py-0 my-0">
                <router-link :to="'inconnue'" exact>
                  <v-img
                    class="box"
                    src="@/assets/img/booksCovers/CouvertureInconnue.png"
                  >
                  </v-img>
                </router-link>
              </v-col>
            </v-row>
            <v-dialog max-width="600" v-model="quatLivreUn">
              <v-card>
                <v-img
                  src="@/assets/img/booksCovers/quatrieme-couverture-inconnue.png"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>
      
      <v-row>
        <v-col lg="2" md="1" sm="0" xs="0" ></v-col>
        <v-col lg="8" md="10" sm="12" xs="12" >
          <v-divider></v-divider>
        </v-col>
        <v-col lg="2" md="1" sm="0" xs="0"></v-col>
      </v-row>




      <!-- Coming Soon PopUp -->
      <ComingSoon />
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ComingSoon from "@/components/ComingSoon.vue";

import newsInconnue   from "@/components/inconnue/news.json";
import newsCarnets    from "@/components/carnets/news.json";
import newsPigments   from "@/components/lespigments/news.json";
import newsMurmure    from "@/components/lemurmure/news.json";
import newsLdb        from "@/components/ldb/news.json";
import newsLpdm       from "@/components/lesPierresDeMemoires/news.json";
import newsLmds       from "@/components/lesMotsDesSalines/news.json";


export default {
  name: "Home",
  components: {
    ComingSoon,
  },
  data() {
    return {
      quatLivreSept:    false,
      quatLivreSix:     false,
      quatLivreCinq:    false,
      quatLivreQuatre:  false,
      quatLivreTrois:   false,
      quatLivreDeux:    false,
      quatLivreUn:      false,
      newsInconnue:     newsInconnue,
      newsCarnets:      newsCarnets,
      newsPigments:     newsPigments,
      newsMurmure:      newsMurmure,
      newsLdb:          newsLdb,
      newsLpdm:         newsLpdm,
      newsLmds:         newsLmds
    }; //- return
  },
  methods: {},
  computed: {
    comingSoonDlg: {
      get() {
        return this.$store.getters["GET_COMING_SOON_DIALOG"];
      },
      set(newDlg) {
        return this.$store.commit("SET_COMING_SOON_DIALOG", newDlg);
      },
    },
    count: {
      get() {
        return this.$store.getters["inconnue/counterVal"];
      },
      set(val) {
        return this.$store.commit("inconnue/changeCountval", val);
      },
    },
  },
};
</script>

<style scoped>
.home {
  background-image: url("../assets/img/main-background.png");
  background-position: 50% 0%;
}
.home-news-container {
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
}
.home-news {
  text-align: left;
  font-size: 12pt;
}

.box {
  width: 190px;
  max-height: 330px;
  transition: box-shadow 0.3s;
  border: 1px solid grey;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
  float: left;
}

.box:hover {
  box-shadow: 0 0 20px rgba(10, 10, 10, 0.5);
  cursor: pointer;
}
</style>