<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    <v-row justify="center">
      <v-col align="center">
        <v-card
          dark
          class="elevation-4 mb-3 grey darken-4"
          outlined
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Ci-dessous, se trouve une sélection de liens connexes à mon roman.<br>
            Ces liens ne sont en aucun cas exhaustifs des cagots.<br>
            Ils sont juste là pour vous donner envie d’en savoir plus.
          </v-card-text>
        </v-card>
        <v-card dark outlined class="pa-5">
          <v-divider></v-divider>
          <v-card-text
            style="color: AntiqueWhite; font-size: 16pt; text-align: justify"
          >
            <p>
              <v-icon>mdi-forward</v-icon>
              <a href="http://www.cosmovisions.com/ChronoCagots.htm">
                Lien 1</a> - ChronoCagots
            </p>

            <p>
              <v-icon>mdi-forward</v-icon>
              <a href="http://www.larousse.fr/dictionnaires/francais/cagot_cagote/12121">
                Lien 2</a> - Définition du Cagot ou de la Cagotte
            </p>
            
            <p>
              <v-icon>mdi-forward</v-icon>
              <a href="http://www.ladepeche.fr/article/2012/09/16/1441116-ces-braves-cagots-ont-ete-maudits-pendant-800-ans.html">
                Lien 3</a> - La Dépêche : Ces braves Cagots ont été maudits pendant 800 ans
            </p>
            
            <p>
              <v-icon>mdi-forward</v-icon>
              <a href="http://lieux.loucrup65.fr/cagots.htm">
                Lien 4</a> - Loucrup 65
            </p>
            







          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["lpdm/viewState"];
      },
      set(newState) {
        return this.$store.commit("lpdm/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.parag {
  color: AntiqueWhite;
  text-align: justify;
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>