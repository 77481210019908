<template>
  <v-container v-if="viewState.lecteursDsply == true">
    
    <v-row>
      <v-col>
        <v-card dark class="pa-5" >
          <v-card-title>
            <h1 style="color: AntiqueWhite; width: 100%;">Paroles de lecteurs</h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              - "Journaliste originaire de Prypiat, expatrié en France pour fuir
              un passé trop douloureux, Léonid n’a jamais fait le deuil de sa
              famille décimée par la catastrophe nucléaire de Tchernobyl. Il faut
              dire que Léonid porte le poids de la dépêche, qu’il a rédigée sous
              la contrainte de la Pravda à la suite de l’explosion de la
              catastrophe de Tchernobyl, pour annoncer que l’incident n’était pas
              grave et ne représentait pas de danger pour la population. Marié,
              père de deux enfants, il coule une vie en apparence heureuse et sans
              problème. À l’occasion des 25 ans de l’anniversaire de la
              catastrophe, Léonid se voit confier un reportage pour un journal
              gratuit parisien sur ce sujet. L’occasion se présente à lui de
              retourner en Ukraine. Une décision difficile à prendre mais qui
              permettra à Léonid d’exhumer son passé et d’en savoir plus sur ce
              qui s’est réellement passé. C’est à travers des carnets d’esprit que
              Léonid s’exprime le plus souvent. Ces carnets donnent également la
              parole à ceux qui ne peuvent plus parler : les victimes de
              Tchernobyl. Ce beau roman, plein de tendresse et d’humanité, nous
              fait revivre la catastrophe nucléaire de 1986 et ses conséquences
              humaines jusqu’à nos jours et donne à réfléchir sur l’avenir que
              nous offrons à nos enfants."<br />

              Delphine Boutonnet-Réseau Sortir du nucléaire<br />
            </p>
          </v-card-text>

          <v-card-text>
            <p class="avis-lecteur">
              - "Bon je me décide, ça y est j'ai terminé "Carnets d'esprits"
              hélas, et oui hélas car encore une fois j'ai été envouté, par moment
              j'avais l'impression de sentir ce goût de métal que hélas certains
              habitants ont ressenti, tu vois avant de l'acheter j'ai hésité car
              je me disais encore un livre sur "Tchernobyl" et puis non ce n'est
              pas encore un énième livre sur cette terrible catastrophe, c'est TON
              livre et il est magnifique a lire!! Merci Philippe de nous faire
              partager ces bons moments, j'ai hâte de lire les "pigments
              d'éternité"."

              <br />Peggy<br />
            </p>
          </v-card-text>

          <v-card-text>
            <p class="avis-lecteur">
              - Que dire de " carnets d'esprit " ? ce n'est pas un simple livre
              sur Tchernobyl, on a l'impression de lire un journal intime d'un
              rescapé de ce drame de l'atome. On apprend vraiment plein de choses
              sur un évènement qui a marqué l'humanité, et ce pour plusieurs
              millénaires encore. Allez, achetez ce livre, et laissez un message à
              Philippe.

            <br />Damien<br />
          </p>
          </v-card-text>
          <v-card-text>
          <p class="avis-lecteur">
            - "Je ne sais combien recommander cet ouvrage de Philippe Nonie. Un
            roman où l'écrivain plonge son lecteur dans l'intimité forte et
            tragique d'un a ncien journaliste de la Pravda, qui, pour pouvoir
            continuer d'exercer son métier a dû malgré lui écrire des mensonges
            sur la tragédie technologique de Tchernobyl (ou plus exactement de
            Prypiat), sans savoir quelles seraient les retombées et les dommages
            affectifs et psychologiques le concernant. Un ouvrage poignant,
            criant de vérité, riche de documentation, au rythme enlevé où le
            lecteur n'ose plus respirer, de peur, lui aussi, d'être contaminé
            par ce mal inconnu et invisible, cette absynthe vénéneuse. Même le
            plus insensible des hommes finira par se laisser émouvoir d'une
            telle tragédie, et n'aura probablement plus jamais le même regard
            sur cette tragédie, qui, soyons en sûrs, hélas, n'appartient pas
            encore au passé. Près de 250 pages de plaisir haletant, de voyage en
            terre interdite, proche, tout proche de ce qui ne se voit pas, de ce
            que l'on tait, mais que l'on devrait crier sur tous les toits. Merci
            Monsieur Nonie pour ce roman témoin de ce sacrifice humain, merci à
            vous de perpétrer la mémoire de ceux qui furent véritablement des
            héros de l'humanité."

            <br />Raphaël<br />
          </p>
          </v-card-text>

          <v-card-text> 
            <p class="avis-lecteur">
              - "J'ai adoré ce livre, c'est sur la catastrophe de Tchernobyl.
              l'auteur arrive à nous faire prendre conscience de l'horreur que
              c'était, que c'est encore. on voit tout avec les yeux du reporter,
              c'est très troublant ! merci à Philippe Nonie c'est certain, je vais
              lire ces autres livres."
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["carnets/viewState"];
      },
      set(newState) {
        return this.$store.commit("carnets/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.avis-lecteur {
  color: AntiqueWhite;
  font-size: 16pt;
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
}

.avis-lecteur p {
  color: AntiqueWhite;
  text-align: justify;
}
</style>