<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    <v-row justify="center">
      <v-col align="center">
        <v-card dark outlined class="pa-5" >
          <v-card-title justify="center">
            <h1 style="width: 100%; text-align: center; color: AntiqueWhite;">Vous avez lu mon roman <br>et vous voulez en savoir plus ?</h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="parag">
              Je vous propose une vidéo sur des reportages évoquant la
              catastrophe de Tchernobyl qui m’ont servi de base pour l’écriture
              de « Carnets d’esprit » :
            </p>

            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
            >
              <iframe
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                frameborder="0"
                type="text/html"
                src="https://www.dailymotion.com/embed/video/xajzd4"
                width="100%"
                height="100%"
                allowfullscreen
              >
              </iframe>
            </div>
            <a href="http://www.dailymotion.com/Philname" target="_blank"
              >Philname</a
            >
            <br />
            <p class="parag">
              Ce reportage est en trois parties et je vous recommande de le voir
              tant il est intéressant et très bien documenté sur les
              circonstances de la catastrophe. C’est celui-ci que j’ai regardé
              le 01 janvier 2010 au matin. Je l’ai regardé de multiples fois par
              la suite pour m’imprégner de la situation.
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="parag">
              <!-- http://www.dailymotion.com/video/xeerd0_le-sacrifice-1-2_news -->
              Ce reportage est bouleversant, simplement bouleversant. Il donne
              la parole à des <em>liquidateurs</em> qui ont travaillé sur
              Tchernobyl. Il suit certains d’entre eux dans le temps et parle de
              leurs souffrances, de leur mise à l’écart, de leur manque de
              reconnaissance, de leur oubli…Regardez-le. <br />
              Il est bouleversant mais tellement utile pour comprendre et ne pas
              oublier. Il montre aussi des images de l’intervention sur le
              réacteur numéro quatre. On voit des hommes courir comme lors d’une
              bataille militaire dans une rue…A voir absolument. <br /><br />
            </p>

            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
            >
              <iframe
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                frameborder="0"
                type="text/html"
                src="https://www.dailymotion.com/embed/video/xeerd0?autoplay=1"
                width="100%"
                height="100%"
                allowfullscreen
              >
              </iframe>
            </div>
            <br />
            <a
              href="http://www.dailymotion.com/Super_Resistence"
              target="_blank"
              >Super_Resistence</a
            >
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="parag">
              Enfin, un dernier reportage tout aussi passionnant mais tout aussi
              terrible sur Tchernobyl…<br />
            </p>
            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
            >
              <iframe
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                frameborder="0"
                type="text/html"
                src="https://www.dailymotion.com/embed/video/xjlnti"
                width="100%"
                height="100%"
                allowfullscreen
              >
              </iframe>
            </div>

            <a href="http://www.dailymotion.com/superresistance" target="_blank"
              >Super_Resistence</a
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["carnets/viewState"];
      },
      set(newState) {
        return this.$store.commit("carnets/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.parag {
  color: AntiqueWhite;
  text-align: justify;
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>