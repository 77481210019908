<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row justify="center">
      <v-expansion-panels accordion dark>
        <!-- <v-expansion-panel v-for="(item,i) in 5" :key="i"> -->
        <!-- Panel 01 -->
        <v-expansion-panel>
          <v-expansion-panel-header><strong>Voyages</strong></v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
              J’ai beaucoup voyagé en Europe, en Asie, en Amérique du Nord, dans l’hémisphère sud et je continue car je trouve que c’est une richesse que d’aller voir ce qui se passe ailleurs.<br>
              J’aime les rencontres insolites et j’ai toujours eu la chance de découvrir des gens extraordinaires dans des lieux improbables…<br>
              …comme cette famille iranienne que j’ai croisée…sur les bords du Lac Tibériade en Israël en 1987 ! J’étais avec mon frère et nous nous sommes arrêtés car nous avons entendu parler persan. Nous nous sommes adressés à eux dans leur langue…à leur grand étonnement. Des Français qui parle persan ! Ils étaient juifs et venaient faire un voyage en Israël…à notre grand étonnement.<br>
              Je pense aussi à Esther, une jeune Hollandaise que j’ai croisée à Jérusalem dans une auberge de jeunesse. Nous avons sympathisés le soir sur un toit dans la vieille Jérusalem et je lui ai proposé d’aller voir ensemble le lever de soleil sur le Mont des Oliviers le lendemain matin. Ce fut un moment magique qui m’a d’ailleurs inspiré le personnage de Esther et cette rencontre entre Eric et elle dans mon roman.<br>
              Il y a eu aussi ce Canadien qui m’a abordé sur les bords du Saint Laurent à Québec alors même que je regardais un prospectus touristique pour me rendre aux chutes de Montmorency. Il m’a dit :<br>
              - On y va ?<br>
              - Où ?<br>
              - Aux chutes de Montmorency !<br>
              - Allez !<br>
              Et il m’a emmené visiter les chutes de Montmorency qui sont moins larges mais plus hautes que celles du Niagara. Le week-end, il m’a proposé de découvrir la province de Charlevoix. On était au début du printemps et j’ai pensé au livre Croc Blanc de Jack London. Les lacs étaient encore gelés. C’était magique !<br>
              On a été dans un chalet qui dominait le Saint Laurent et nous avons bu deux litres de vin de pissenlit et fumé cigarette sur cigarette en riant comme des fous. On était sur une terrasse en bois et on avait mis une couverture sur nos jambes pour nous protéger du froid. Cela reste un des plus beaux souvenirs de ma vie.<br>
              Ce Canadien m’a indirectement inspiré le personnage d’Eric.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 02 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Des Personnages : Hubert, Suzanne, Etienne, Céline et Xavier ...</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
              Hubert et Suzanne sont le pur produit de mon imagination. Ils n’ont pas d’ancrage particulier sur des gens que j’ai connus. Hubert est trader mais je dois préciser que j’ai écrit le roman avant « l’affaire Kerviel ».<br>
              Céline est le prénom d’une collègue de travail que j’apprécie tout particulièrement pour sa gentillesse et son professionnalisme.<br>
              Xavier est le prénom de mon arrière-arrière-grand père qui est enterré à Taller dans les Landes et s’appelait Xavier Carrère. J’ai voulu lui rendre hommage en donnant à l’un de mes personnages son prénom.<br>
              Etienne a les traits du fils de Ernest Carrère, personnage évoqué dans le chapitre du Fort de Tavannes. Ernest a réellement existé et a été grièvement blessé lors de la Bataille de Verdun en 1916. C’était le frère de mon arrière-grand-mère.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 03 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Un livre en cuir noir élimé pour un roman</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
              L’Inconnue utilise au Fort de Tavannnes un livre en cuir noir élimé pour approcher l’arrière-grand-père de Hubert Cet ouvrage que j’intègre dans le roman existe réellement et il a une histoire très singulière…<br>
              Il m’a été donné à Venise en 1985.<br>
              Je partais alors en vacances en Grèce pendant 1 mois avec un copain. Nous faisions le voyage en train depuis Bordeaux avec la Carte Inter-Rail. Notre périple ferroviaire s’étalait sur 3 jours et était logiquement le suivant :<br>
              -	Bordeaux-Nice,<br>
              -	Nice-Venise,<br>
              -	Venise-Belgrade,<br>
              -	Belgrade-Athènes<br>
              Nous avons fait une halte sur la journée à Venise pour visiter la Place Saint-Marc. Nous devions prendre le train de 17 heures et en fin de compte nous avons opté pour celui de 21 heures à la gare de Venise. Là, en attendant notre train, j’ai sympathisé avec un prêtre italien qui partait en Ethiopie où sévissait alors une terrible famine. J’avais amené des crêpes depuis la France et je lui ai proposé de les partager avec lui.<br>
              Avant de prendre congé, pour me remercier, il m’a tendu un ouvrage en cuir noir élimé qui s’est avéré être une Bible. L’ouvrage en cuir noir élimé était superbe et j’ai dans un premier temps refusé sa proposition. Il a insisté et m’a dit en anglais : « Prenez là, elle vous protègera ».<br>
              Je n’étais pas croyant et j’ai accepté sous son insistance. J’ai rangé la Bible en haut de mon sac à dos puis nous avons été prendre notre train. Je voulais monter devant et mon pote a insisté pour que l’on monte derrière au prétexte qu’il y aurait moins de gens dans la nuit qui marcheraient jusqu’à la queue du train. Sans le savoir, son initiative nous a sauvés la vie…<br>
              Nous avons sympathisé avec un groupe d’Anglais et avec une Marseillaise dans notre compartiment. Puis nous nous sommes couchés vers minuit et j'ai été dormir dans le couloir. A 05h40 du matin, le contrôleur yougoslave m’a réveillé à coups de pompes dans les côtes pour vérifier ma carte interail.<br>
              Nous passions dans les Balkans. Il faisait froid et j’ai sorti un sweat-shirt avant de me recoucher. Là, il y a eu le premier choc. Mon pote s’est explosé le nez sur la vitre. Je me souviens m’être dit : « Il prend de drôles de virages ce chauffeur ».<br>
              Puis notre wagon est sorti des rails très violemment et a roulé sur les traverses de chemin de fer. Je me suis accroché au porte-bagages et je revois encore tous les sacs à dos qui ont volé en tous sens autour de nous. J’ai eu la sensation à cet instant que la vie était une seconde monstrueusement distendue qui se comprimait au point de n’être plus qu’une seconde…sans savoir si je serai là à la suivante.<br>
              Puis tout s’est arrêté. Il y a eu un silence et une insupportable odeur de poussière. Puis ces cris terribles et angoissants des passagers blessés et apeurés. Dehors, il faisait nuit.<br>
              Comme je n’étais pas blessé, j’ai aidé les gens à sortir. La jeune Marseillaise était en état de choc. Sa bouche tremblait.<br>
              Puis je suis revenu dans notre wagon sortir nos sacs à dos qui s’étaient empilés les uns sur les autres. La lumière dans notre compartiment n’avait pas sauté. Je me suis penché et là j’ai vu la Bible ouverte à mes pieds. Je l’ai regardée pendant de longues secondes et je me suis dit que j’avais le choix entre deux choses : soit regarder à quelle page elle était ouverte, soit la refermer et vivre avec cette inconnue pendant tout le reste de ma vie.<br>
              J’avais 20 ans et j’ai choisi la seconde solution. J’ai préféré ne pas savoir comme je n’étais pas croyant.<br>
              Au petit matin, un train est venu nous chercher. Le spectacle était désolant et les photos interdites car on était encore sous le régime communiste de Tito. Le soleil s’est levé et je me suis mis à pleurer tout en étant pris de nausées terribles.<br>
              Mais je n’oublierai jamais ce prêtre et ses paroles dans la gare de Venise.<br>
              L’ouvrage en cuir noir élimé que tient l’Inconnue dans mon roman est ma façon de le remercier 25 ans après du geste qu’il a eu.<br>
              S’il n’est pas mort, j’espère qu’un jour il lira mon roman et qu’il se souviendra de cet instant passé ensemble à la gare de Venise.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 04 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Des lieux pour un roman</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
              Quatre villages dans les Landes dont trois se situent dans le Marensin.<br>
              Le Marensin est une zone géographique des Landes située grossièrement entre Mimizan et Vieux Boucau. Ma grand-mère aimait me parler du Marensin. Elle me disait : « Je ne suis pas landaise, je suis Marensine ». Et d’ajouter qu’elle ne comprenait pas forcément le patois du Born, la partie des Landes qui remonte sur Biscarosse. Je crois qu’il y avait beaucoup de coquetterie dans ses propos.<br>
              Le village de Taller où se situe l’appartement de Céline est le village natal de ma mère. J’y ai passé mes vacances quand j’étais minot dans la maison familiale. La coopérative de térébenthine a réellement existé et les locaux sont aujourd’hui occupés par un artiste qui est sculpteur.<br>
              La commune de Boos est le village natal de mon grand-père maternel. Cette commune a un charme indéfinissable et une énergie particulière qui émane du sol.<br>
              La petite route qui mène de Taller à Boos m’a inspiré un des passages du roman. Cette route a quelque chose de mystérieux, d’indéfinissable. C’est en y allant tout seul et en faisant la sieste à côté des ruches d’un apiculteur que j’ai écrit ce passage entre Céline et Xavier.<br>
              La commune de Castets est la ville natale de ma grand-mère et le lieu où se sont mariés mes arrières-grands parents en 1905. La photo de leur mariage est vraiment typique de <a href="#?w=700" rel="popup_album04" class="poplight">cette époque</a>.<br>
              La commune de Seignosse le Penon a été choisie car nous y avons passé des vacances en famille en 2007 et j’y ai situé une partie du roman après avoir fait les repérages nécessaires.<br>
              Jérusalem, l’Iran, l’Australie ont complété la fresque car l’Inconnue n’a jamais eu besoin de passeport pour voyager.<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Panel 05 -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Des musiques pour un roman</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="accordion">
              On trouve deux musiques dans ce roman : Sinnerman de Nina Simone et Chambre avec vue de Henri Salvador.<br>
              Sinnerman, c’est pour rendre hommage à Darina Al Djoundi, cette jeune Libanaise qui a triomphé au Festival d’Avignon en 2007. Son livre raconte son histoire et celle de son père qui voulait être enterré sur la musique de Sinnerman.<br>
              Je conseille son livre qui est vraiment un cri de vie extraordinaire qui m’a permis de redécouvrir autrement Nina Simone et de me replonger dans la guerre du Liban.<br>
              Le Liban étant un pays auquel je suis extrêmement attaché car j’y ai de la famille et mes arrières grands-parents paternels sont enterrés là-bas.<br>
              Chambre avec vue évoque une inconnue sur des rythmes très doux et je l’ai intégré naturellement au roman. C’est d’ailleurs la musique que j’ai écouté en boucle lorsque j’ai appris le 19 janvier 2011 à 15h30 que j’étais le lauréat du Prix Nouveau Talent 2011 !<br>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    viewState: {
      get(){
        return this.$store.getters['inconnue/viewState'];
      },
      set(newState) {
        return this.$store.commit('inconnue/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style>

</style>