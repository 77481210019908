<template>
  <v-container v-if="viewState.lecteursDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title>
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de lecteurs
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Bonjour,<br>
               Je viens de finir votre livre que j’ai lu avec beaucoup de plaisir.<br>
               Poudrier depuis le 2 novembre 1989 (mon premier emploi), j’aime l’histoire de la poudrerie 
               et c’est donc avec beaucoup d’attentes et d’intérêts que j’ai lu votre livre.<br>
               J’ai habité l’impasse des hulottes entre 1990 et 1999, mes deux enfants y sont nés, 
               nous habitions le numéro 2 (la première maison à gauche).
               C’est avec beaucoup de tristesse que j’ai vu disparaître ces maisons. 
               J’ai été le dernier locataire de ce n°2, la première à être démolie. 
               Ma première pensée a été de voir qu’il faut plusieurs semaines pour construire une maison 
               qui a vu plusieurs décennies d’histoires et qu’en 1 seule journée elle est devenue un tas de gravas.<br>
               Aujourd’hui je suis sur le point d’acheter une maison de l’allée des grands ducs, 
               je suis en train d’y faire des travaux 
               et dans le grenier se trouve un sac rempli de papiers et de photos retraçant la vie d’une famille qui a habité ce logement 
               dans les années 70. Peut-être les avez vous connus, les enfants de cette familles jouaient peut-être avec vous au 95 ? 
               Les papiers et les photos portent le nom de la famille Martinez, Lucien (le père), Brigitte et Philippe (les enfants). 
               J’aimerai rendre ces documents chargés d’histoire à cette famille, si par le hasard vous les connaissiez, n’hésitez pas…<br>

               Encore merci pour votre roman chargé d’histoire, c’est vrai que la main d’œuvre indigène (comme on les appelait) est méconnue, j’ai un vieux film qui date des années 50 de la poudrerie, le début du film fait l’éloge des poudriers qui ont redémarré la poudrerie entre les 2 guerres sans faire mention de cette main d’œuvre.<br>

               Le grand père de mon épouse est né à la maternité de la poudrerie, de père inconnu (peut-être un travailleur étranger ?).<br>

               Encore merci et bonne continuation dans vos écritures."<br>

               <br><br><strong title="Fabrice a commenté le murmure des attentes de Philippe Nonie">Fabrice</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
                "Bonjour Philippe, c'est fait.  Je viens de fermer la dernière page du "murmure des attentes".<br>
                Un vrai bonheur...<br>
                J'ai été littéralement transportée par le parrallèle entre ces 2 histoires, ces 2 situations de guerre que le temps sépare mais que les attentes rejoignent.<br>
                Ce récit est poignant et plein de vérité qui circule dans un univers où l'humanité est capable d'offrir le meilleur comme le pire.<br>
                L'histoire d'Hô m'a personnellement rappelée celle de mon père, né en 1915 en kabylie, et qui a été blessé de guerre lors des combats de la seconde guerre mondiale au côté de la France.<br>
                Alors merci d'avoir éveillé et réhabilité la mémoire de ces hommes.<br>
                Excellente journée.
                
                <br><br>
                <strong title="Louisa a commenté le murmure des attentes de Philippe Nonie">Louisa</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
               "Depuis six soirs ce fut mon livre de chevet ! 
               Aujourd'hui c est le septième jour je viens de le finir. 
               A tes derniers mots Philippe Nonie. 
               J'ai eu les frissons dans le dos.
               Signe pour moi d'une belle histoire d'une belle émotion. 
               Chaque soir j ai pris le train du présent avec nicole, au bout d'un chapitre, 
               je changeais de quai pour prendre le train du passé avec Hô. 
               J'ai voyagé plus facilement avec Hô. 
               Car je connais bien cette Dordogne..et bravo pour cette recherche dans l'histoire 
               qui donne une vérité a ma mémoire que je ne connaissais pas, .lorsque j'étais à l'école de l'Alba, 
               ecole primaire située non loin de la poudrerie.. Je me souviens avoir demandé à ma mère, 
               pourquoi on appellait cette usine poudrerie ?? 
               He bé c est qu ils y fabriquent de la poudre de riz 
               celle qu on met sur le visage des femmes pour rendre la peau comme du velours ....
               !! Hé bé je l ai cru moi gosse de 10 ans. Comme quoi la vérité peut vite etre transformé. . ..
               merci pour ce beau voyage une forte pensée a toutes ces personnes qui ont été arrachés a leurs terres...
               et a celles qui attendent. 
               <br><br>
               <strong title="Isabelle a commenté le murmure des attentes de Philippe Nonie">Isabelle</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "J'ai fini le murmure hier soir. 
              C'est une belle histoire qui réveille les émotions. 
              J'ai trouvé le rythme finement choisi, et le passage de l'histoire de Ho à celle de Nicole est agréable et astucieux. 
              Je me suis retrouvée dans des attentes décrites, 
              mais aussi dans cette visite éclair de Nicole à son grand père pour faire plaisir à son père. 
              Oui bon ça c'est quand j'étais jeune, maintenant je suis vieillissante et plus réfléchie!! 
              ah ah !!! et puis je me suis retrouvée aussi dans ce choix que Nicole fait pour son grand père 
              alors que ses parents ne comprennent pas. 
              Et puis je me suis retrouvée aussi quand Nicole parle avec sa tante Régine à la terrasse d'un café, 
              alors ça remue aussi de voir en écrit des choses qu'on ressent et qu'on exprime pas."


              <br><br><strong title="Sophie a commenté le murmure des attentes de Philippe Nonie">Sophie</strong>
            </p>
          </v-card-text>
          <v-divider></v-divider>

      
          <v-card-text>
            <p class="avis-lecteur">
              "J'ai lu aujourd'hui "le murmure des attentes" de Philippe Nonie chez Lucane Éditions. 
              J'avoue je l'ai lu dans la salle d'attente du médecin et le lieu se prêtait à merveille pour ce bouquin. 
              Quand mon praticien préféré m'a appelé, je l'ai maudit. Il ne me restait que 5 pages de cette palpitante histoire. 
              Que dire ? Je suis entrée dans l'histoire j'ai voyagé avec Nicole, j'ai souffert avec elle et j'ai attendu aussi. 
              Merci à Philippe Nonie pour ce superbe roman."

              <br>

              <br><br><strong title="Flora a commenté le murmure des attentes de Philippe Nonie">Flora</strong>
            </p>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["murmure/viewState"];
      },
      set(newState) {
        return this.$store.commit("murmure/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.avis-lecteur {
  color: AntiqueWhite;
  font-size: 16pt;
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
}

.avis-lecteur p {
  color: AntiqueWhite;
  text-align: justify;
}
</style>