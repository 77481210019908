<template>
  <v-container v-if="viewState.lecteursDsply == true">
      <h3 style="color: white; font-size: 18pt;">Paroles de lecteurs ...</h3>
      <br><br>
      <p class="avis">
        <strong style="color: #06f924;">Carine</strong> : 
        « Ca y est je l'ai fini ! je l'ai DEGUSTE !! un sujet passionnant, des personnages attachants, 
        des lieux connus pour nous pyrénéens !!! j'ai pu me projeter dans l'histoire, 
        j'étais avec l'inconnue dans tous ces lieux et paysages magnifiques ! 
        Je n'ai pas LU l'histoire, je l'ai VECU !! Félicitations Philippe ! 
        J'ai hâte de lire le prochain roman !!! »
      </p>
      <br>
      <v-divider dark></v-divider>
      <br>
      <p class="avis">
        <strong style="color: #06f924;">Alassane</strong> au Sénégal : 
        « bonjour de loin au Sénégal, terre de rythmes et de rimes, d'écriture et de culture, 
        je suis ravi de découvrir votre livre l'Inconnue. Et je te souhaite un bel avenir. 
        Heureusement que malgré les barrières facebook rapproche les peuples du monde. 
        Plein de soleil tropical a toi. Bonne inspiration Alassane. »
      </p>
      <br>
      <v-divider dark></v-divider>
      <br>
      <p class="avis">
        <strong style="color: #06f924;">Sylvia</strong> : 
        « J'ai commencé a lire votre livre et vous aviez raison en disant ; 
        "posologie 50 pages par jour" il m’envoûte a chaque page que je lit il me tarde la suivante 
        j'avais jamais accroché autant dans un roman. Merci »
      </p>
      <br>
      <v-divider dark></v-divider>
      <br>
      <p class="avis">
        <strong style="color: #06f924;">Sophie</strong> : 
        « Je ne conseille pas le livre, je le recommande ! »
      </p>
      <br>
      <v-divider dark></v-divider>
      <br>
      <p class="avis">
        <strong style="color: #06f924;">Sabine</strong> : 
        « Je viens de DEVORER le livre !!! Un prix bien mérité ! »
      </p>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    viewState: {
      get(){
        return this.$store.getters['inconnue/viewState'];
      },
      set(newState) {
        return this.$store.commit('inconnue/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.avis {
  text-align: justify;
  color: white;
  font-size: 16pt;
}
</style>