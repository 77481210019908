<template>
  <v-container v-if="viewState.auteurDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title> 
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de l'Auteur
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="media">
              <p style="color: AntiqueWhite; font-size: 16pt;">Interview donnée à la radio RVB</p>
              <audio src="@/assets/audio/carnets/Philippe  Nonie - Carnet d esprit - Autre Histoire Tchernobyl.mp3" controls>
                <a href="@/assets/audio/carnets/Philippe  Nonie - Carnet d esprit - Autre Histoire Tchernobyl.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </div>

            <div class="media">
              <p style="color: AntiqueWhite; font-size: 16pt;">Interview donnée à la radio RVB</p>
              <audio src="@/assets/audio/carnets/interview-philippe-nonie.mp3" controls>
                <a href="@/assets/audio/carnets/interview-philippe-nonie.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </div>

            <p style="color: AntiqueWhite; font-size: 16pt;">
              Ci-dessous, mes deux interview faites sur le roman
              <strong><em>Carnets d'esprit</em></strong
              >.
            </p>
            
            <div class="integrated-video">
              <iframe 
              style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden" 
              src="https://www.youtube.com/embed/uNM2vqpYgKc" 
              frameborder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture" 
              allowfullscreen>
              </iframe>
            </div>

            <div class="integrated-video"> 
              <iframe 
              style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden" 
              frameborder="0" 
              type="text/html" 
              src="https://www.dailymotion.com/embed/video/xw5foa" 
              width="100%" height="100%" allowfullscreen > 
              </iframe> 
            </div>

            <div>
              <a href="http://www.dailymotion.com/Lourdesactufr" target="_blank">
                Lourdesactufr
              </a>
            </div>
            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["carnets/viewState"];
      },
      set(newState) {
        return this.$store.commit("carnets/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.integrated-video {
  position:relative;
  padding-bottom:56.25%;
  height:0;
  border: 1px solid grey;
  margin-bottom: 50px;
  overflow:hidden;
}
.media {
  width: 600px;
  margin: 0 auto;
  border-bottom: 1px dashed white;
  padding-bottom: 35px;
  margin-bottom: 35px;;
  padding-top: 35px;
}
</style>