<template>
  <v-container>
    
    <v-row class="my-0 py-0">
      <v-col cols="12" class="my-0 py-0">
        <v-img src="@/assets/img/inconnue/banniere-inconnue.png"></v-img>
      </v-col>
    </v-row>    

    <v-row>
      <v-col cols="12">
        <!-- Lien de menu 1 -->
        <hr>
        <v-btn
          class="mr-3"
          dark text
          to="/"
          color="white">
          Accueil
        </v-btn>

        <!-- Lien de menu 2 -->
        <v-menu
          open-on-hover bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              dark text
              class="mr-3"
              v-bind="attrs"
              v-on="on"
              @click="dsplySiteContent('Default')"
            >
              Le Roman
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(mrLink, index) in menuRomanLinks"
              :key="index"
              @click="dsplySiteContent(mrLink.title)"
              link
            >
              <v-list-item-title >{{ mrLink.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


        <!-- Lien de menu 3 -->
        <v-btn
          text
          class="mr-3"
          color="white"
          @click="dsplySiteContent('LiensUtiles')"
        >
          Lien utiles
        </v-btn>

        <!-- Lien de menu 4 -->
        <v-menu
          open-on-hover bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              text
              class="mr-3"
              v-bind="attrs"
              v-on="on"
            >
              Paroles
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(oepLink, index) in onEnParle"
              :key="index"
              @click="dsplySiteContent(oepLink.title)"
              link
            >
              <v-list-item-title v-text="oepLink.title" ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Lien de menu 5 -->
        <v-btn
          text
          class="mr-3"
          color="white"
          href="mailto:philippe.nonie@gmail.com"
        >
          Contact
        </v-btn>

      <hr>
      </v-col>
    </v-row>

    <!-- default View State -->
    <v-row v-if="viewState.defaultDsply == true">
      <v-col cols="6">
        <v-carousel cycle height="400" hide-delimiters>
          <v-carousel-item
            background-size: contain
            src="@/assets/img/inconnue/AGenoux.png"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
          <v-carousel-item
            background-size: contain
            src="@/assets/img/inconnue/inconnue-pertuze.jpg"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
        </v-carousel>
        <!-- <v-img class="img-inco" src="@/assets/img/inconnue/AGenoux.png"></v-img> -->
      </v-col>
      <v-col cols="6" align-self="start">
        <p v-for="(actu, idx) in news" :key="idx" class="news">
          <strong>{{ actu.title }}</strong> : {{ actu.content }} - <a :href="actu.linkRef" target="_blank">{{ actu.linkText }}</a>
        </p>
      </v-col>
    </v-row>


    <!-- Album Photo -->
    <v-row v-if="viewState.albumDsply == true">
      <v-col>
        <AlbumPhoto />
      </v-col>
    </v-row>

    <!-- La génèse -->
    <v-row v-if="viewState.geneseDsply == true">
      <v-col>
        <Genese />
      </v-col>
    </v-row>

    <!-- Extraits -->
    <v-row v-if="viewState.sampleDsply == true">
      <v-col>
        <Extraits />
      </v-col>
    </v-row>

    <!-- Liens Utiles -->
    <v-row v-if="viewState.liensUtilesDsply == true">
      <v-col>
        <LiensUtiles />
      </v-col>
    </v-row>

    <!-- Paroles de lecteurs -->
    <v-row v-if="viewState.lecteursDsply == true">
      <v-col>
        <ParolesLecteurs />
      </v-col>
    </v-row>

    <!-- Paroles de l'auteur -->
    <v-row v-if="viewState.auteurDsply == true">
      <v-col>
        <ParolesAuteur />
      </v-col>
    </v-row>
    

  </v-container>
</template>

<script>

import AlbumPhoto       from "@/components/inconnue/album-photos.vue";
import Extraits         from "@/components/inconnue/extraits.vue";
import Genese           from "@/components/inconnue/genese.vue";
import LiensUtiles      from "@/components/inconnue/liens-utiles.vue";
import ParolesLecteurs  from "@/components/inconnue/paroles-lecteurs.vue";
import ParolesAuteur    from "@/components/inconnue/paroles-auteur.vue";

import newsInconnue     from "@/components/inconnue/news.json";

export default {
  components:{
    Extraits,
    Genese,
    AlbumPhoto,
    LiensUtiles,
    ParolesLecteurs,
    ParolesAuteur
  },
  data() {
    return {
      news: newsInconnue,
      menuRomanLinks: [
        { title: 'Extraits' },
        { title: 'La génèse' },
        { title: 'Album photos' }
      ],
      onEnParle: [
        { title: 'De lecteurs'},
        { title: "l'Auteur"}
      ]
    }
  },
  methods: {
    dsplySiteContent(link) {

      if(link == 'Default') {
        this.viewState.defaultDsply = true;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if(link == 'La génèse') {
        this.viewState.defaultDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.geneseDsply = true;
        this.viewState.sampleDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if(link == 'Album photos') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = true;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if (link  == 'Extraits') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = true;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if (link  == 'LiensUtiles') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = true;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = false;

      } else if (link  == 'De lecteurs') {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = true;
        this.viewState.auteurDsply = false;
      
      } else if (link  == "l'Auteur") {
        this.viewState.defaultDsply = false;
        this.viewState.sampleDsply = false;
        this.viewState.geneseDsply = false;
        this.viewState.albumDsply = false;
        this.viewState.liensUtilesDsply = false;
        this.viewState.lecteursDsply = false;
        this.viewState.auteurDsply = true;
      }
    }
  },
  computed: {
    viewState: {
      get(){
        return this.$store.getters['inconnue/viewState'];
      },
      set(newState) {
        return this.$store.commit('inconnue/changeDsplyState', newState);
      }
    },
    count: {
      get() {
        return this.$store.getters['inconnue/counterVal'];
      }, set (val) {
        return this.$store.commit('inconnue/changeCountval', val)
      }
    }
  }
}
</script>

<style scoped>

.news {
  text-align: left;
  color: white;
}
</style>