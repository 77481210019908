<template>
  <v-container v-if="viewState.albumDsply == true">
      <v-card
          dark
          class="elevation-4 mb-3"
          style="text-align: center; font-size: 16pt; "
        >
        <v-card-text style="color: AntiqueWhite; font-size: 16pt">
          Ci-dessous vous trouverez une sélection de photos ayant inspiré l'univers du roman <u>Les mots des salines</u>
        </v-card-text>
      </v-card>
    <v-carousel cycle height="754" hide-delimiters class="carousel">
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/Ancien canal damenee d'eau salee salines Villeroy-Sete-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/AncienCanalAmenéed'eauSalinesVilleroy-900.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/Anciennes-Salines-Villeroy-Setes-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/bassin-a-sec-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/Bois sculpte anciennes salines de Villeroy Sete-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/PetitPortPontLevisSete-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/Quai-des-pecheurs-quartier-du-Pont-Levis-Sete-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
      <v-carousel-item
        background-size: contain
        src="@/assets/img/albums/07-LesMotsDesSalines/Quartier-du-Pont-Levis-a-Sete-900-600.png"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['lmds/viewState'];
      },
      set(newState) {
        return this.$store.commit('lmds/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>

.carousel {
  border-radius: 5px;
  border: 3px solid lightgrey;
  background: rgba(100,100,100,0.8);
}

</style>