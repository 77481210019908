<template>
  <v-container v-if="viewState.liensUtilesDsply == true">
    
    <h4 class="darktheme">Vous avez lu mon roman et vous voulez en savoir plus ?</h4>
    <br><br>
    <p class="darktheme">
      Comme vous avez pu le remarquer, dans mon premier roman je parle beaucoup
      des
      <a style="color: #06f924" href="http://www.ondres-landes.net/url6_4.html"
        >Landes</a
      >. En effet cette partie de la France me tient beaucoup à coeur, c'est la
      raison pour laquelle je vous propose ce
      <a style="color: #06f924" href="http://www.ondres-landes.net/url6_4.html"
        >lien</a
      >.
    </p>

    <p class="darktheme">
      Ici, je vous propose un lien vers le
      <a
        style="color: #06f924"
        title=""
        href="http://fr.wikipedia.org/wiki/Hapchot"
        >Hapchot</a
      >, que j'évoque dans la deuxième partie de mon roman. Cet outil servait
      aux résiniers pour pratiquer une entaille dans l'écorce du pin. En
      relation avec ce dernier, je vous oriente également vers le
      <a
        style="color: #06f924"
        title=""
        href="http://www.lou-cutiot.com/albumphoto1/page2.html"
        >Cutiot</a
      >, qui permettait de recueillir la sève du pin après l'avoir entaillé avec
      le hapchot.
    </p>

    <p class="darktheme">
      Dans mon roman, je parle d'une région bien particulière d'Australie, il
      s'agit de
      <a
        style="color: #06f924"
        href="http://fr.wikipedia.org/wiki/Perth_(Australie-Occidentale)"
        >Perth</a
      >
    </p>

    <p class="darktheme">
      La
      <a
        style="color: #06f924"
        href="http://fr.wikipedia.org/wiki/Aborig%C3%A8nes_d%27Australie"
        >culture Aborigène</a
      >
      tient une place importante dans mon roman. Si vous souhaitez en savoir
      plus, je vous propose ce
      <a
        style="color: #06f924"
        href="http://fr.wikipedia.org/wiki/Aborig%C3%A8nes_d%27Australie"
        >lien</a
      >.
    </p>

    <p class="darktheme">
      L'Iran est un pays qui tient également son importance dans le roman, voici
      un lien vers le
      <a
        style="color: #06f924"
        href="http://fr.wikipedia.org/wiki/Mont_Damavand"
        >Mont Damavant</a
      >
      dont je parle.
    </p>

    <br />

    <h3 class="darktheme">Ici, je vous propose plusieurs liens :</h3>

    <p class="darktheme">
      <a
        style="color: #999900"
        href="http://www.editions-calmann-levy.com/Calmann_Levy/acc/acc01_accueil_f.jsp"
        >Calmann Levy</a
      >
    </p>

    <p class="darktheme">
      <a style="color: #99ccff" href="http://www.lesnouveauxtalents.fr"
        >Plateforme nouveau talent</a
      >
    </p>

    <p class="darktheme">
      <a
        style="color: #99ccff"
        href="http://www.facebook.com/fondationbouyguestelecom"
        >Fondation Bouygues Telecom</a
      >
    </p>

    <p class="darktheme">
      <a style="color: #999900" href="http://www.metrofrance.com"
        >Le Journal Métro</a
      >
    </p>
    
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    viewState: {
      get(){
        return this.$store.getters['inconnue/viewState'];
      },
      set(newState) {
        return this.$store.commit('inconnue/changeDsplyState', newState);
      }
    }
  }
};
</script>

<style scoped>
.darktheme {
  font-size: 14pt;
  text-align: justify;
  color: white;
}
</style>