import Vue from 'vue'
import VueRouter from 'vue-router'

/** Pages globales * * * * * * * * * * * * * */
import Home       from '../views/Home.vue'
import Biographie from '../views/Biographie.vue'
import NotFound   from '../views/NotFound.vue'
/** Livres * * * * * * * * * * * * * * * * * */
import Inconnue   from '../views/Inconnue.vue'
import Carnets    from '../views/Carnets.vue'
import Pigments   from '../views/LesPigments.vue'
import Murmure    from '../views/LeMurmure.vue'
import LDB        from '../views/LDB.vue'
import LPDM       from '../views/LesPierresDeMemoires.vue'
import LMDS       from '../views/LesMotsDesSalines.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inconnue',
    name: 'Inconnue',
    component: Inconnue
  },
  {
    path: '/carnetsdesprit',
    name: "Carnets d'Esprit",
    component: Carnets
  },
  {
    path: '/pigmentsdeternite',
    name: "Les pigments d'éternité",
    component: Pigments
  },
  {
    path: '/lemurmuredesattentes',
    name: "Le murmure des attentes",
    component: Murmure
  },
  {
    path: '/ldb',
    name: "L.D.B.",
    component: LDB
  },
  {
    path: '/lpdm',
    name: "Les Pierres de Mémoires",
    component: LPDM
  },
  {
    path: '/lmds',
    name: "Les mots des salines",
    component: LMDS
  },
  {
    path: '/biographie',
    name: 'Biographie',
    component: Biographie
  },
  { 
    path: '/404', 
    component: NotFound
  },
  { 
    path: '*', 
    redirect: '/404' 
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
