<template>
  <v-container v-if="viewState.auteurDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title> 
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de l'Auteur
            </h1>
            <v-card-text>
            <div class="media" style="border-top: 1px dashed white;">
              <p style="color: AntiqueWhite; font-size: 16pt;">Interview donnée à la radio RVB</p>
              <audio src="@/assets/audio/ldb/07 Philippe Nonie - LDB.mp3" controls>
                <a href="@/assets/audio/ldb/07 Philippe Nonie - LDB.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </div>

            <div class="media">
              <p style="color: AntiqueWhite; font-size: 16pt;">Interview du 4 Juin 2014, donnée à la radio Mosaïque FM</p>
              <audio src="@/assets/audio/ldb/L'Inconnu-se-Livre-Virginie-Goevelinger-04.06.2014.mp3" controls>
                <a href="@/assets/audio/ldb/L'Inconnu-se-Livre-Virginie-Goevelinger-04.06.2014.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["ldb/viewState"];
      },
      set(newState) {
        return this.$store.commit("ldb/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.integrated-video {
  position:relative;
  padding-bottom:56.25%;
  height:0;
  border: 1px solid grey;
  margin-bottom: 50px;
  overflow:hidden;
}
.media {
  width: 600px;
  margin: 0 auto;
  border-bottom: 1px dashed white;
  padding-bottom: 35px;
  margin-bottom: 35px;;
  padding-top: 35px;
}
</style>