<template>
  <v-container v-if="viewState.auteurDsply == true">
    <h2 style="margin-top: 15px; color: white;">Paroles de l'auteur : Interviews Vidéos</h2>


    <v-row justify="center">
      <v-col lg="6" sm="10" xs="11">
        <iframe
          frameborder="1"
          width="560"
          height="314"
          src="http://www.dailymotion.com/embed/video/xis3ff?width=560&theme=spring&foreground=%23C2E165&highlight=%23809443&background=%23232912"
        ></iframe>
        <br>
        <a href="http://www.dailymotion.com/video/xis3ff_interview-philippe-nonie_webcam" target="_blank">
          Interview Philippe Nonie
        </a>
        par
        <a href="http://www.dailymotion.com/LesNouveauxTalents" target="_blank">
          LesNouveauxTalents
        </a>
      </v-col>

      <v-col lg="6" sm="10" xs="11">
        <iframe
          frameborder="1"
          width="560"
          height="314"
          src="http://www.dailymotion.com/embed/video/xjlkuq?width=560&theme=slayer&foreground=%23FF0000&highlight=%23FFFFFF&background=%23000000"
        ></iframe>
        <br>
        <a href="http://www.dailymotion.com/video/xjlkuq_la-fondation-bouygues-telecom-promeut-la-langue-francaise-et-encourage-la-creation-litteraire_webcam"
          target="_blank"
          >La Fondation Bouygues Telecom promeut la langue...
        </a>
        <a href="http://www.dailymotion.com/LesNouveauxTalents" target="_blank">
          LesNouveauxTalents
        </a>
      </v-col>
    </v-row>  

    <v-row justify="center">
      <v-col cols="6">
        <v-divider class="space-divider" dark></v-divider>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col lg="6" sm="10" xs="11">
        <iframe
          frameborder="1"
          width="560"
          height="420"
          src="http://www.dailymotion.com/embed/video/xmch8e"
        >
        </iframe>
        <br>
        <a href="http://www.dailymotion.com/video/xmch8e_philippe-noni-partie-01_news"
        target="_blank">Philippe Nonie Partie 01</a> par
        <a href="http://www.dailymotion.com/Lourdesactufr" target="_blank">
          Lourdesactufr
        </a>
      </v-col>

      <v-col lg="6" sm="10" xs="11">
        <iframe
          frameborder="1"
          width="560"
          height="420"
          src="http://www.dailymotion.com/embed/video/xmchws"
        >
        </iframe>
        <br>
        <a href="http://www.dailymotion.com/video/xmchws_philippe-nonie-partie-02_news" target="_blank">
        Philippe Nonie Partie 02</a> par
        <a href="http://www.dailymotion.com/Lourdesactufr" target="_blank">Lourdesactufr</a>
      </v-col>
    </v-row>


    <v-row justify="center">
      <v-col cols="6">
        <v-divider class="space-divider" dark></v-divider>
      </v-col>
    </v-row>
    

    <v-row justify="center">
      <v-col lg="6" sm="10" xs="11">
        <iframe
          frameborder="1"
          width="560"
          height="420"
          src="http://www.dailymotion.com/embed/video/xmcih0"
        ></iframe>
        <br>
        <a href="http://www.dailymotion.com/video/xmcih0_philippe-nonie-partie-03_news" target="_blank">
          Philippe Nonie Partie 03
        </a> 
        par
        <a href="http://www.dailymotion.com/Lourdesactufr" target="_blank">Lourdesactufr</a>
      </v-col>
      <v-col lg="6" sm="10" xs="11">

      </v-col>
    </v-row>


      <v-divider class="space-divider" dark></v-divider>
      <h2>
        Mes interviews radio
      </h2>



      <v-row justify="center">
        
        <v-col cols="6">
          <v-card dark>
            <v-card-title light>
              Interview pour RVB
            </v-card-title>
            <v-card-text>
              <audio src="@/assets/audio/inconnue/Interview-RVB.mp3" controls>
                <a href="@/assets/audio/inconnue/Interview-RVB.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card dark>
            <v-card-title light>
              Sur les ondes paloises
            </v-card-title>
            <v-card-text>
              <audio src="@/assets/audio/inconnue/philippe nonie-radio paloise.mp3" controls>
                <a href="@/assets/audio/inconnue/philippe nonie-radio paloise.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card dark>
            <v-card-title light>
              Sud Radio du 11 Juin 2011
            </v-card-title>
            <v-card-text>
              <audio controls src="@/assets/audio/inconnue/SudRadio11062011.mp3">
                Le navigateur ne supporte pas le type <code>audio</code>.<br>
                <a href="@/assets/audio/inconnue/SudRadio11062011.mp3">
                  Charger l'Interview
                </a>
              </audio>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    <v-divider class="space-divider" dark></v-divider>

    <v-row justify="center">
      <v-col cols="6">
        <p style="font-size: 16pt;">
          Comme tout un chacun, j’ai mes coups de cœur avec… des livres … des films. 
          Si je devais emmener dix livres sur une île déserte, je prendrais :
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            Des livres
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>

            <v-list class="transparent">
              <v-list-item v-for="(book, idx) in books" :key="idx">
                <v-list-item-title class="text-left">{{ book.title }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  de <strong>{{ book.author }}</strong>
                </v-list-item-subtitle>
              </v-list-item>

            </v-list>

            <p class="paragraph">
              <strong>Jean-Paul Kaufmann</strong> apparaît deux fois dans cette
              liste car il a cette faculté à saisir l’indicible et à nous le
              décrire. Notamment dans la chambre noire de Longwood. Ce roman
              décrit les derniers jours de Napoléon à Sainte-Hélène.<br /><br />
              Pour le déguster et me mettre dans l’ambiance, je l’ai lu en
              septembre 2010 à Capbreton…adossé à l’ombre d’un blockhaus de la
              seconde Guerre Mondiale. De temps à autre, je fermais les yeux et
              j’écoutais les vagues s’écraser contre le béton. J’étais
              transporté à Sainte-Hélène où les vagues s’écrasaient sur les
              falaises. C’était magique.<br />
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>
            Des films
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list class="transparent">
              <v-list-item v-for="(movie, idx) in movies" :key="idx">
                <v-list-item-title class="text-left">{{ movie.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="movie.director != ''" class="text-right">
                  de <strong>{{ movie.director }}</strong>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <p class="paragraph">
              …avec des dialogues épatants. Je les connais par cœur. Le grand
              jeu avec mes enfants, c’est de déclamer un dialogue et ils doivent
              retrouver le nom du film.
            </p>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    

    <!-- Fin page -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      movies: [
        {
          title:"Sur la route de Madison",
          director: ""
        },
        {
          title:"La grande illusion",
          director: "Jean Renoir"
        },
        {
          title:"Les tontons flingueurs",
          director: ""
        },
        {
          title:"Il était une fois la révolution",
          director: "Sergio Léone"
        },
        {
          title:"Le bon, la brute et le truand",
          director: ""
        }
      ],
      books: [
        {
          title: "Pleure, ô pays bien aimé",
          author: "Alan Paton",
          comment: ""
        },
        {
          title: "Les raisins de la colère",
          author: "John Steinbeck (qui apparaît dans mon roman)",
          comment: ""
        },
        {
          title: "La nuit des temps",
          author: "Barjavel",
          comment: ""
        },
        {
          title: "Si c’est un homme",
          author: "Primo Lévy",
          comment: ""
        },
        {
          title: "La chambre noire de Longwood",
          author: "Jean-Paul Kaufmann",
          comment: "(qui est évoqué sans être cité dans mon roman)"
        },
        {
          title: "La maison du retour",
          author: "Jean-Paul Kaufmann",
          comment: ""
        },
        {
          title: "Le parfum",
          author: "Patrick Suskind",
          comment: ""
        },
        {
          title: "L’écriture et la vie",
          author: "Jorge Semprun",
          comment: ""
        },
        {
          title: "Si c’était vrai",
          author: "Marc Lévy",
          comment: ""
        }
      ]
    };
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["inconnue/viewState"];
      },
      set(newState) {
        return this.$store.commit("inconnue/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
.space-divider {
  margin-bottom: 50px;
  margin-top: 50px;
}
.paragraph {
  color: grey;
  font-size: 14pt;
  text-align: justify;
}
</style>