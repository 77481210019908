<template>
  <v-container v-if="viewState.lecteursDsply == true">
    <v-row>
      <v-col>
        <v-card dark class="pa-5">
          <v-card-title>
            <h1 style="color: AntiqueWhite; width: 100%">
              Paroles de lecteurs
            </h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="avis-lecteur">
              "Voilà....J'ai enfin les mots pour ce livre....<br>
              Début juillet,dans un salon du livre,à Aurignac,petit village du Comminges,
              une amie,Myriam m'accompagnait afin de découvrir des auteurs et des livres,rien de plus banal ,en somme...Après à peine quelques pas ,mon regard
              fut attiré par une couverture et des mots "les pierres de mémoires" ....Tactile ,je touchais cette jacquette,douce et un peu caoutchouteuse,étrange ,les mots aussi ...De vieilles expériences avec des objets et des roches me remontaient en mémoire,justement ...Philippe Nonie ,l'auteur engagea alors quelques mots...
              <br><br>
              Trois heures après,nous parlions toujours,impossible de mettre fin à cette discussion...
              Ce livre,m'a troublée,faisant remonter à la conscience des souvenirs enfouis aux frontières de ce que l'on s'interdit d'exprimer ,par crainte de passer pour dérangé,"pépiot "ou "fada"....
              <br><br>
              J'ai attendu presque un mois pour laisser mes mots décanter, comme un vin de Saint Mont...
              Voici une semaine,au cours d'une petite virée sur Lourdes et Bagnères de Bigorre,nous passâmes par la vieille route de Lannemezan qui traverse Cieutat ...
              <br><br>
              Et là le paysage des "pierres de mémoires "s'est imposé ,réel,tangible,
              la montagne...Une éternité de labeur humain,des drames,des joies ,un pays buriné 
              par le travail des hommes ,un décor grandiose,le chateau de Mauvezin....
              <br><br>
              Et des ruines,ces mêmes ruines qui ont soufflé un vent ravageur sur Henri le "cagot",le menant dans une quète fiévreuse sur les sentiers de la création et de sa propre histoire...C'est un livre que j'aime,car il me touche,il parle à mon âme,et à mon propre vécu....Merci Philippe Nonie,vous m'avez émue aux larmes..."

              <br /><br /><strong>Taïev</strong>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["lpdm/viewState"];
      },
      set(newState) {
        return this.$store.commit("lpdm/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
.avis-lecteur {
  color: AntiqueWhite;
  font-size: 16pt;
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
}

.avis-lecteur p {
  color: AntiqueWhite;
  text-align: justify;
}
</style>