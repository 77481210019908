<template>
  <v-container v-if="viewState.sampleDsply == true">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-card
          dark
          class="elevation-4 mb-3 grey darken-4"
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Voici une sélection d'extraits de mon roman <u>Les pierres de mémoires</u>
          </v-card-text>
        </v-card>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <!-- Extrait 1 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 1 - Cagot</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="paragraphe">
                  Je ne suis pas né cagot, je le suis devenu.
                  <br><br>
                  Cagot.
                  <br><br>
                  Voici un mot que la mémoire a en partie oublié.
                  <br><br>
                  Pendant des siècles, les cagots ont été ces hommes et ces femmes traités comme des dégénérés. 
                  Ils vivaient en communautés comme les lépreux dans des lazarets. 
                  Ils ne mesuraient guère plus d’un mètre cinquante, parfois moins, 
                  et devaient porter une patte d’oie cousue sur leurs habits pour les distinguer des autres. 
                  Dans les villages, un cours d’eau – un ru, une rivière, un fleuve 
                  – faisait systématiquement office de frontière entre les cagots et les autres ; 
                  ils disposaient même de leur propre lavoir pour éviter tout contact avec les gens normaux.
                  <br><br>
                  Lors des cérémonies religieuses, les cagots devaient pénétrer dans l’église par une porte dérobée, 
                  quand celle-ci existait, avant de se signer dans un bénitier dédié et adapté à leur petite taille. 
                  Encore aujourd’hui, on trouve trace de l’un d’entre eux à l’église de Saint-Savin dans les Hautes-Pyrénées.
                  Les cagots assistaient à chacune des messes dans un lieu réservé ; 
                  il n’était pas rare de les regrouper derrière un mur percé d’un œil-de-bœuf. 
                  Lorsqu’ils communiaient, le prêtre tendait systématiquement l’hostie au bout d’une cuillère en bois afin d’éviter d’être contaminé.
                  <br><br>
                  Dans bien des civilisations, même dans la mort, on ne réunit pas ceux qui ont été systématiquement séparés dans la vie quotidienne. Ce fut le cas pour ces hommes et ces femmes : jusque dans les cimetières, les cagots ont toujours été enterrés à part des autres, une parcelle leur étant spécifiquement réservée.
                  <br><br>
                  À suivre…
                </p>
                

              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Extrait 2 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 2 - 3 Avril 2000</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="paragraphe">
                  <i>Sonnez avant d’entrer.</i>
                  <br><br>
                  J’ignore délibérément l’injonction affichée sous la sonnette et pousse la porte du cabinet médical.
                  <br><br>
                  Dans la salle d’attente, un enfant joue bruyamment avec un camion ;
                  il imite le vrombissement du moteur, sa main accompagnant le véhicule sur une table basse en d’incessants allers-retours.
                  <br><br>
                  Dans un autre contexte, j’aurais sans doute pris du plaisir à l’observer.
                  <br><br>
                  Mais aujourd’hui, cela m’est impossible.
                  <br><br>
                  Tout a véritablement commencé après cette rencontre.
                  <br><br>
                  Oui, cette rencontre…
                  <br><br>
                  Quelque chose a grandi en moi, s’est développé avant de s’installer puis de prendre ses aises.
                  <br><br>
                  Alors, je m’assois, sans bruit, à l’écart de cet enfant, sans même répondre aux patients qui viennent de saluer mon entrée par un Bonjour murmuré.
                  <br><br>
                  Je regarde ma montre avant de plonger dans cette histoire, la mienne, pour comprendre comment j’ai pu en arriver là.
                  <br><br>
                  À suivre…
                </p>
              </v-card-text>
            </v-card>
          </v-window-item>


          <!-- Extrait 3 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 3 - Les années 60 l'enfance</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="paragraphe">
                  Rien ne me prédestinait à devenir ce que je suis aujourd’hui : je n’ai pas choisi d’être romancier ; je le suis devenu par la force des choses. Mes parents considéraient ces gens-là – les écrivains, les nouvellistes, les essayistes, les auteurs de pièces de théâtre, les journalistes – comme des ratés. Pour eux, ils ne savaient rien faire d’autre de leurs dix doigts. Alors, ils écrivaient : des romans, des recueils, des essais, des pièces, des articles. Ils ne labouraient pas une page blanche, non, ils bavaient de l’encre ; pour ma famille, l’écriture représentait une forme d’injure à la terre. Écrire, le cache-misère d’un mensonge quotidien : faire croire que l’on sert à quelque chose !
                  <br><br>
                  Je n’ai donc pas écrit durant ma jeunesse, enfin pas tout de suite.
                  <br>
                  En revanche, je savais comment labourer, herser, semer, récolter – même si ma petite taille s’est rapidement révélée un handicap pour atteindre les pédales des tracteurs –, faire le foin, tirer le lait, aider une brebis à agneler, une vache à vêler, reconnaître une maladie sur le blé et bien d’autres choses encore. Mes parents étaient exploitants agricoles – paysans, comme ils disaient alors avec orgueil – de génération en génération. Ce mot portait en son sein la fierté de travailler la terre et d’en vivre. Inconsciemment, l’expression de la création se faisait au travers de ces parcelles que l’on modelait en fonction de ce que l’on souhaitait y semer. La récolte de céréales, de foin, de regain, d’ensilage était un accouchement qui ne disait pas son nom. La terre était un violon invisible dont mon grand-père grattait les cordes pour lui faire jouer sa partition la plus riche. Cette composition avec les forces naturelles – la pluie, le vent, les orages violents, la canicule, la sécheresse, – demandait des capacités d’adaptation, d’inventivité, et, parfois, un certain art divinatoire. Il fallait sentir les éléments, les deviner, anticiper leurs caprices pour mieux prévenir les événements climatiques.
                  Il faut être né près de la terre, sentir ses effluves, la puissance de sa chair, pour comprendre mes mots et deviner ce qu’ils recouvrent comme efforts au quotidien. Durant mon enfance, j’ai ainsi été programmé à prendre la suite de ceux qui m’avaient précédé pour, plus tard, préparer la succession de ceux qui viendraient après moi. J’ai appris à aimer la terre, c’était un fait, mais pas à la manière de mes ancêtres ; ils portaient comme un fardeau cette obsession viscérale d’être ce trait d’union mandaté par tous ceux – morts et vivants – qui leur murmuraient dès le réveil de poursuivre et léguer le travail entrepris avant eux.
                  <br><br>
                  À suivre…

                </p>
              </v-card-text>
            </v-card>
          </v-window-item>


          <!-- Extrait 4 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 4 - Orientation scolaire</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="paragraphe">
                  C’est là que les choses ont réellement commencé à déraper.
                  <br>
                  À la fin de la troisième, mon orientation a été évoquée.
                  <br>
                  Pour mes parents, c’était une affaire entendue : je devais aller au lycée agricole et forestier Jean Monnet à Vic-en-Bigorre pour suivre une formation agricole jusqu’au bac. Le sillon était formé avant même que le tracteur ne l’ait tracé ; on le voyait de loin, il suffisait de le suivre du doigt. C’est en tous les cas la représentation que s’en faisait mon père et avant lui tous nos ancêtres. L’image traversait les générations et, quand le tracteur n’existait pas encore, c’est le bœuf qui prenait sa place.
                  Mais, je ne voulais pas de cette direction, les lectures de l’année m’ayant convaincu que mon avenir était ailleurs. J’aimais les mots, je les appréciais chaque jour un peu plus, je les manipulais comme un clown ses toupies. Cet exercice me plaisait.
                  <br>
                  J’avais découvert au CDI du collège bon nombre de romans. La documentaliste, une petite femme brune qui aimait son métier et sentait ma propension à la lecture, me proposa : La peste de Camus, J’irai cracher sur vos tombes de Boris Vian, Germinal d’Emile Zola, mais aussi de la poésie avec Les Contemplations de Victor Hugo. 
                  <br>
                  L’élément déclencheur fut, là-encore, la rencontre parents-professeurs. Ma mère était seule avec moi ce jour-là. Mon père devait nous accompagner mais il attendait le vétérinaire pour une génisse qui menaçait de perdre son veau.
                  <br>
                  « Vas-y avec Henri, a-t-il dit, tu n’as pas besoin de moi. »
                  <br>
                  C’est mon prof de maths – monsieur Tellier – qui nous a reçus. Il était accompagné de la prof de français, madame Brochand, une petite dame blonde au nez camus. Lors de l’entretien, il s’est permis, avec beaucoup de tact – même si ce ne fut pas l’avis de ma mère – de donner son opinion.
                  <br>
                  -  Madame, votre fils a des résultats scolaires brillants qui ne se démentent pas année après année, c’est le meilleur élève de sa classe. L’orienter sur une formation agricole est une solution intéressante, très intéressante même car ces filières ont parfois des élèves qui n’aiment pas l’école et il pourrait tirer ses camarades vers le haut.
                  <br>
                  Il a marqué une pause ; j’aurais juré l’avoir entendu prononcer un mais, cette conjonction de coordination annonçant une affirmation qui oblitère tout ce qui l’a précédée.
                  <br>
                  - Il nous semble à ma collègue et à moi que votre fils pourrait se destiner à…
                  <br>
                  Il a eu un instant d’hésitation.

                  <br><br>
                  À suivre…


                </p>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Extrait 5 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 5 - Mai 68</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="paragraphe">
                  Alors même que j’attends toujours mon tour chez le médecin, je songe aux événements qui ont marqué le mois de mai 1968. Je venais d’avoir huit ans ; mes parents n’avaient pas encore la télévision, juste le poste de TSF comme le disaient si joliment mes grands-parents. Tous suivaient, avec une certaine inquiétude, les manifestations qui se déroulaient à Paris. C’est d’ailleurs la seule fois que la radio est restée allumée durant tous les repas pour suivre l’évolution de la situation. Celle-ci délivrait des mots sans images – on entendait parfois les cris, les tirs de grenades lacrymogènes derrière la voix du reporter – et l’imagination de ma famille faisait le reste en envisageant le pire : la remise en cause de ce monde dont ils avaient hérité. Les seules images à leur disposition étaient ces photos dans le journal que le facteur apportait tous les matins dans la boîte aux lettres. Ces clichés, choisis en fonction de leur gravité, et reproduits dans La République des Pyrénées, présentaient des rues dépavées, des voitures calcinées, des policiers, matraque et bouclier en main, repoussant violemment de jeunes gens en sang.
                  <br><br>
                  Derrière ces affrontements, ma famille pressentait qu’un monde – le leur, ce monde fait d’ordre et de respect – vacillait dangereusement. Cette situation inédite a sans nul doute alimenté cette peur de me voir quitter la terre, de l’abandonner, de la délaisser pour un monde où la violence était d’abord dans les mots des reportages-radiophoniques puis, un peu plus tard, dans les images de la télévision. Le fondement même de leur quiétude se trouvait dans les prairies avec ces animaux qui naissaient, tétaient, pacageaient, grossissaient avant d’être conduits à l’abattoir.
                  <br>
                  La seule violence que nous connaissions alors résidait dans la mort de nos agneaux, de nos veaux car certains ne survivaient pas après la naissance. La vision de cet animal sans vie – les membres rigides, les mouches pullulant sur son corps – était difficile à supporter. On en voulait au sort, au hasard, à la fatalité qui avait mal fait les choses. L’équarisseur passait par là et faisait disparaître la dépouille comme un déchet à sortir du champ visuel. Je n’aimais pas ces images ; je préférais, et de loin, les animaux qui partaient vivants dans une bétaillère même si je savais qu’ils allaient être tués à l’abattoir. J’en avais conscience mais je ne voyais jamais ces images ni n’entendais les meuglements des vaches ou les bêlements des brebis avant d’être abattues.
                  <br><br>
                  Une autre violence – légitimée celle-ci, ce qui en faisait sa caractéristique et son originalité – demeurait l’apanage des agriculteurs en colère : ils déboulaient devant les administrations et la préfecture pour balancer du fumier, brûler des pneus, bloquer les routes, car les prix de vente des produits agricoles ne correspondaient pas à ce qu’ils en attendaient. Cette violence permettait de maintenir la pression et de faire entendre les revendications de la profession ; pour eux, elle n’était en rien comparable à celle des étudiants dans la rue, à ces grèves qui marquaient une révolte face à l’ordre établi. La violence paysanne – comme la nommait alors mes parents – faisait justement partie de cet ordre établi ; elle ne le remettait pas en cause, il était donc normal de la conserver.
                  <br><br>
                  À suivre…
                </p>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Extrait 6 -->
          <v-window-item>
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">Extrait 6 - Le tournant</strong>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
                
                <p class="paragraphe">
                  C’est l’été, j’ai quinze ans.
                  <br><br>
                  À l’ombre de la treille de raisins, je lis L’espagnol  de Bernard Clavel.
                  <br><br>
                  Un livre sublime sur la terre, l’exode et la folie des hommes. C’est la première fois que je côtoie l’univers d’un auteur qui parle aussi bien des paysans. Je suis tellement absorbé par l’histoire que je n’entends pas ma grand-mère paternelle débouler dans mon dos.
                  <br><br>
                  - Va jusqu’à la grange des Abadie voir le lot de génisses, ordonne-t-elle. Elles étaient bien vives ce matin après l’orage quand ton père y est passé. Je ne voudrais pas qu’elles s’échappent chez le voisin.
                  <br><br>
                  Je me lève à contrecœur ; la terre est un bien qui ne se partage pas.
                  <br><br>
                  Je pars à la rencontre des génisses en empruntant le sentier des coteaux ; je traverse plusieurs parcelles, arrive au sommet d’une colline. De là, j’ai une vue imprenable sur quelques villages des Baronnies : Uzer, Lies, Esconnets et le château de Mauvezin. Un peu plus loin, vers l’est, ce sont les Comminges. Mon regard revient doucement vers les Baronnies ; j’apprécie leur vallonnement harmonieux. La forêt y est composée d’arbres dont les cimes donnent l’illusion d’un tissu de velours vert. Si j’étais un géant, je m’allongerais dans une prairie pour m’en servir d’oreiller tout en regardant les nuages s’enrouler comme une serviette autour du cou trapu du Pic du Midi de Bigorre. Une brise légère accompagne mes pas et joue avec les tiges des premières fleurs. Qui sait si un chef d’orchestre invisible ne commande pas leur mouvement à mon passage ?
                  <br><br>
                  Ma grand-mère n’est pas là pour me cornaquer, alors je profite de cette liberté. Le lieu-dit des neuf fontaines se situe sur l’autre versant ; lorsque je parviens à la parcelle, certaines vaches sont couchées et ruminent paisiblement en clignant des yeux tandis que d’autres paissent tranquillement. Quelque chose détonne pourtant ; un détail inhabituel : une silhouette en blouse blanche se détache et semble s’incruster dans l’herbe. Quelqu’un est au milieu du troupeau – ce dernier indifférent à sa présence –  et ce n’est pas mon père. Je fronce les sourcils. Qui s’est permis de pénétrer chez nous sans autorisation ? Je dévale la pente en courant, ralentis ma course et m’arrête quelques secondes pour retrouver mon souffle.
                  <br><br>
                  Malgré ma colère, je suis touché par la magie du lieu…
                  <br><br>
                  Une brise apatride emprunte le fond du vallon sur la pointe des pieds. Hiver comme été, elle s’octroie un droit de passage sans jamais faire de halte. Au mois de juillet et août, les bovins viennent y trouver une fraîcheur apaisante. Un ru, enserré entre la prêle et les herbes hautes, coule, invisible, et traverse discrètement la parcelle. Au printemps, les pluies successives gonflent son débit et un clapotis finit par trahir sa présence.
                  <br><br>
                  Un long sifflement attire subitement mon attention : là-haut, dans le ciel, profitant des courants ascendants, une buse plane avec élégance tandis qu’une autre lui répond avant de plonger vers elle en repliant ses ailes. Commence alors un carrousel ; je les observe un instant avant de revenir vers cet intrus qui n’a même pas daigné lever la tête. Je reprends mon avancée ; au fur et à mesure de celle-ci, je distingue un chevalet et une toile.
                  <br><br>
                  La silhouette est celle d’une femme.
                  <br><br>
                  Elle peint.
                  <br><br>
                  Je m’arrête à quelques mètres derrière elle.
                  <br><br>
                  À ses côtés, une petite valise en cuir élimé avec, disposés en vrac, des pinceaux et des tubes de peinture. Elle tient dans sa main gauche une palette où elle a mélangé les couleurs ; de l’autre, elle travaille avec un pinceau assez volumineux. Dans ses gestes, elle semble livrer un combat ; c’est une scène étrange, fascinante même, un corps à corps incertain contre une entité invisible.
                  <br><br>
                  Cet affrontement n’est pas sans rappeler ce que j’ai vu dernièrement au catéchisme.
                  <br><br>
                  Ce passage de la Bible.
                  <br><br>
                  Intense.
                  <br><br>
                  Sublime.
                  <br><br>
                  Livre de la Genèse.
                  <br><br>
                  Chapitre 32.
                  <br><br>
                  La lutte de Jacob avec l’Ange.
                  <br><br>
                  Ces quelques mots.
                  <br><br>
                  Quelqu'un lutta avec lui jusqu'au lever de l'aurore. Voyant qu'il ne le maîtrisait pas, il le frappa à l'emboîture de la hanche, et la hanche de Jacob se démit pendant qu'il luttait avec lui. Il dit : « Lâche-moi, car l'aurore est levée », mais Jacob répondit : «  Je ne te lâcherai pas, que tu ne m'aies béni. » 
                  <br><br>
                  À cet instant, j’ai une intuition : cette femme et son tableau livrent un combat immémorial, aussi farouche que La lutte de Jacob et de l’Ange, cette toile peinte par Delacroix en 1861.

                  À suivre…

                </p>
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "Extrait 1 - Cagot",
          paragraph: 
          ["Je ne suis pas né cagot, je le suis devenu. "
          +"Cagot. "
          +"plaisantait souvent à ce sujet. Dans un couple, disait-il, le mieux serait de "
          +"partir tous en même temps. Comme cela, il ne resterait personne pour "
          +"regretter l’autre. ",
          "",
          ""
          +"Dans la réalité, cela n’arrive jamais ou presque. Il y a bien eu la mort de "
          +"Jean Cocteau, vingt-quatre heures après celle d’Edith Piaf, pour faire mentir "
          +"cette règle. Sans doute l’article qu’on a commandé au poète sur cette "
          +"chanteuse d’exception s’est-il révélé fatal. Pourtant, que je sache, ils n’étaient "
          +"pas mariés. En revanche, mes grands-parents l’étaient et formaient un beau "
          +"couple. Ils sont partis tous les deux, à quelques mois d’intervalle. Ils sont "
          +"enterrés, dans le Lot-et-Garonne, au cimetière de Saint-Antoine-de-Ficalba. ",
          "C’est justement là que je me rends pour l’inhumation de mes parents. Dans "
          +"cette petite commune rurale, située entre Agen et Villeneuve-sur-Lot, mon "
          +"grand-père avait jadis acheté un domaine d’environ trois hectares au lieu-dit ",
          "Bois joli de Martel. Les raisons de cet achat remontent loin dans le temps.",
          "Après la défaite de Diên Biên Phu, le 7 mai 1954, mon grand-père avait été "
          +"emmené dans un camp de rééducation du Viêt Minh puis libéré quatre mois "
          +"plus tard. ",
          "",
          "Durant sa captivité, il avait très difficilement supporté les privations, les"
          +"brimades, l’endoctrinement, mais surtout la mort de milliers de ses "
          +"camarades. Sur douze mille soldats faits prisonniers, seulement trois mille "
          +"étaient revenus de captivité. Pour survivre, mon grand-père avait fait un "
          +"serment : il s’était promis de quitter l’armée et de se retirer dans un lieu "
          +"totalement isolé s’il avait la chance d’en réchapper vivant. ",
          "",
          ""
          +"***",
          " ",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 2",
          paragraph: 
          ["A quatre heures, tout le monde se lève, pas trop vite quand même, "
          +"pour ne pas me donner l’impression d’être resté pour la forme. La lenteur "
          +"est la politesse des enterrements. Carole s’avance vers moi et me demande "
          +"ce que je vais faire de mes quelques jours de permission. ",
          "- Je ne sais pas encore… ",
          "- Tu vas repartir là-bas ? ",
          "- Oui. ",
          "Là-bas. Carole n’a pas osé prononcer « l’Afghanistan » et nommer le danger "
          +"par son nom. ",
          "- Fais attention à toi, Stéphanie ! dit-elle en m’embrassant sur la joue. ", 
          "Je sais à quoi elle fait allusion. L’énergie réclamée par le deuil pourrait "
          +"m’enlever la prudence nécessaire lors des opérations de guerre menées "
          +"contre les talibans. ",
          "- Ne t’inquiète pas Carole. Ça va aller… ",
          "- Tu peux venir dormir à la maison, si tu veux. Tu ne resteras pas toute seule en attendant…",
          "- Non, merci, c’est gentil… ",
          "Je n’ose pas lui répondre que je veux le silence, l’apaisement, la solitude. "
          +"Et je sais où me rendre pour l’obtenir. ",
          "- Où vas-tu ce soir ? ",
          "- À la caserne du 17ème R.G.P à Montauban. J’y ai quelques copains… ",
          "Je lis dans son regard qu’elle ne me croit pas. Mais qu’importe. Ce que je "
          +"compte faire maintenant n’appartient qu’à moi et à personne d’autre. Je vais "
          +"me rendre au Bois joli de Martel. Ce lieu chante la vie. Rien que pour le nom, "
          +"mon grand-père a eu raison d’acheter cette propriété et de la restaurer. Je "
          +"vais y passer la nuit. Pas à la maison, ni dans la grange, mais à la belle étoile, "
          +"car la propriété a été vendue à des Anglais il y a déjà bien des années. Vingt "
          +"ans pour être exacte. Et j’ai maintenant besoin de communier avec ce lieu où "
          +"’ai des souvenirs par milliers. C’est près du petit ruisseau, qui coule été "
          +"comme hiver, que je vais passer la nuit. Mon grand-père y installait jadis son "
          +"potager. Environ deux mille mètres carrés où il cultivait courgettes, "
          +"concombres, citrouilles, salades, haricots verts, piments des Landes, "
          +"artichauts, potirons et surtout des tomates. Toutes sortes de tomates : cœur "
          +"de bœuf, tomates-cerises, tomates de Crimée, et d’autres dont, pour "
          +"certaines, j’ai oublié jusqu’au nom. Mais je n’ai pas oublié leur odeur, cette "
          +"fragrance si caractéristique qui persiste sur les doigts quand on y écrase une "
          +"feuille. ",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 3",
          paragraph: 
          ["J’avance un peu plus pour essayer de comprendre de quoi il retourne "
          +"vraiment. Et l’image de Gérard Majax me revient subitement en mémoire, "
          +"avec ses mots : « Y’a un truc ». Je renvoie cette image au passé et "
          +"m’agenouille pour mieux observer au travers de la haie de frênes et de "
          +"noisetiers. Il y a une silhouette de dos : un homme s’active au jardin. C’est "
          +"étrange, car il a sensiblement la même corpulence que mon grand-père. Je "
          +"marche doucement sans apercevoir une branche qui casse sous mon poids. ",
          "L’homme se retourne, surpris, et j’ai le réflexe de me dissimuler derrière la "
          +"haie. Je laisse passer quelques instants et mon regard glisse à nouveau entre "
          +"les frondaisons. Ce que je vois alors dépasse l’entendement et tous les tours "
          +"de magie qui m’ont été donnés de voir durant mon enfance. ",
          "J’ai devant moi un homme mort depuis vingt ans ! ",
          "Je le sais, j’étais là, à son enterrement. ",
          "Pourtant, je ne peux nier l’évidence. ",
          "Même si cette situation défie toutes les lois de l’univers, cet homme est mon grand-père ! ",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 4",
          paragraph: 
          ["C’est lui. ",
          "C’est sûr. ",
          "Cette silhouette. ",
          "Mon grand-père. ",
          "Et pourtant, c’est forcément quelqu’un d’autre. Il ne peut pas en être "
          +"autrement parce que ça ne peut plus être lui : c’est moi qui l’ai mis en bière "
          +"avec l’aide du croquemort. Mes parents ont d’abord hésité à me laisser faire. ",
          "Mais je voulais le toucher une dernière fois avant qu’on ne ferme le cercueil. ",
          "C’était ma façon de l’embrasser sans faire comme on le faisait autrefois, ce "
          +"baiser sur le front ou la joue d’une peau froide. Je voulais lui dire au revoir à "
          +"ma façon. Personne ne m’en a empêchée et j’aurais regretté qu’il n’en soit "
          +"pas ainsi. ",
          "J’observe celui qui est revenu. ",
          "Est-ce vraiment mon grand-père ? ",
          "Serait-il possible de violer les lois de l’univers ? Et si oui, depuis quand ? ",
          "Tant de questions auxquelles je n’ai pas de réponse hormis la présence de… ",
          "Comment le qualifier d’ailleurs ? ",
          "Un ressuscité ? ",
          "Un miraculé ? ",
          "Je ne sais plus trop quoi penser. Pourrait-il détecter ma présence sans même "
          +"me voir ? Cette pensée m’effraye. Il n’a eu aucun mal à entendre craquer la "
          +"branche sous mon pied. Il était pourtant sourd. La faute à toutes ces guerres "
          +"qui avaient détruit sa capacité d’audition. ",
          "Peut-on se débarrasser des infirmités de la vieillesse lorsqu’on revient en vie ? ",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 5",
          paragraph: 
          ["Je voudrais renvoyer mon grand-père à son monde ; il n’a plus rien à "
          +"faire ici. Ce lieu ne peut plus être le sien. Autrefois, il l’a été. Mais plus "
          +"maintenant. Mon premier réflexe est de reculer comme si cet homme, qui "
          +"fut mon grand-père, représentait une menace. Et je ne suis en aucune "
          +"manière préparée à voir revenir un mort. Et comme je ne suis pas préparée à "
          +"ça, comme personne ne peut se préparer à ça, l’être humain a inventé le "
          +"deuil pour surmonter l’absence définitive de l’autre. ",
          "J’ai fait le deuil de mon grand-père, voilà déjà vingt ans. ",
          "J’ai intégré sa disparition. "
          +"Je me suis habituée à vivre sans lui en dépit de l’amour que je lui portais. "
          +"Et aujourd’hui, là, maintenant, c’est la construction même de ce deuil qui est "
          +"remise en cause. Si encore il avait été porté disparu à Diên Biên Phu et qu’il "
          +"était revenu des années plus tard, j’aurais pu le comprendre. Son cas n’aurait "
          +"pas été isolé. Dans toutes les guerres, des soldats portés disparus sont "
          +"réapparus à l’issue de celles-ci. Leurs épouses légitimes avaient pourtant fait "
          +"le deuil de leur présence au monde. Certaines avaient refait leur vie. Et le "
          +"retour de leur époux, supposé défunt, a provoqué des drames tout aussi "
          +"poignants que leur disparition au combat. Je songe au roman de Balzac, Le "
          +"colonel Chabert, à cet homme qui revient après des années réclamer son dû "
          +"et comprend avec amertume qu’il n’est plus rien dans le cœur de sa femme.",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 6",
          paragraph: 
          ["Les cailloux gémissaient sous la roue cerclée de la brouette. Le jour se "
          +"levait à peine que nous étions déjà au potager afin de profiter de la fraîcheur "
          +"de l’aube. La terre sentait l’humus. Le ruisseau tintait. Mon grand-père "
          +"dégageait une forte odeur de transpiration tandis qu’il coupait la prêle à la "
          +"faucille. Il la plaçait dans un sac de jute afin de la mettre à macérer un peu "
          +"plus tard. Je suis partie vers le ruisseau pour remplir les deux arrosoirs en "
          +"métal. Mes mains ont plongé dans le bouillonnement de la petite cascade. ",
          "Mais je ne suis pas revenue de suite; je me suis assise sur le bord et j’ai "
          +"regardé mon grand-père. Il s’est arrêté un instant et m’a dit : ",
          "- Ça va, tout va bien ? Ça ne te gêne pas de me regarder travailler ? ",
          "- Je ne te regarde pas travailler, papi… ",
          "Ma voix était calme, posée. ",
          "- Alors que fais-tu ? m’a-t-il demandé sur un ton de reproche. ",
          "- Je te regarde… je te regarde pour tous les jours où tu ne seras plus là. ",
          "J’ai détaché chacun de mes mots pour qu’ils contiennent le plus d’amour "
          +"possible. Mon grand-père a vacillé ; ses yeux se sont embués puis nous "
          +"sommes restés quelques secondes ainsi sans parler. Juste nos regards, le "
          +"tintement de la cascade, et cette odeur de terre qui emplissait nos "
          +"muqueuses.",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        }
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['lpdm/viewState'];
      },
      set(newState) {
        return this.$store.commit('lpdm/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.title {
  color: white;
}
.paragraphe {
  font-size: 16pt;
  text-align: justify;
  color: antiquewhite
}
</style>