<template>
  <v-container v-if="viewState.sampleDsply == true">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-card
          dark
          class="elevation-4 mb-3 grey darken-4"
          style="
            text-align: center;
            font-size: 16pt;
          ">
          <v-card-text style="color: AntiqueWhite; font-size: 16pt;">
            Voici une sélection d'extraits de mon roman <u>L.D.B.</u>
          </v-card-text>
        </v-card>
      </v-col>
    
      <v-item-group
        v-model="sampleSelection"
        class="shrink mr-6"
        mandatory
        tag="v-flex"
      >
        <v-item
          v-for="(s, idx) in samples"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>
      <v-col>
        <v-window
          v-model="sampleSelection"
          class="elevation-1"
          vertical
          dark
        >
          <v-window-item
            v-for="(s, idx) in samples"
            :key="idx"
          >
            <v-card flat dark>
              <v-card-text>
                <v-row
                  class="mb-4 pl-3"
                  align="center"
                >
                  <!-- <v-avatar
                    color="grey"
                    class="mr-4"
                  ></v-avatar> -->
                  <v-col cols="12" align="start" class="pa-0 ma-0">
                    <strong class="title">{{ s.titre }}</strong>
                  </v-col>
                  
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr>
                  </v-col>
                </v-row>
  
                <p v-for="(paragraph, idxp) in s.paragraph" :key="idxp" class="paragraphe">
                  {{ paragraph }}
                  <v-img src=""></v-img>
                </p>

              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sampleSelection: 0,
      samples: [
        {
          titre: "Extrait 1",
          paragraph: 
          ["La mort de mes parents m’a inévitablement renvoyée à celle de mes "
          +"grands-parents paternels, vingt ans plus tôt. La mort, mon grand-père "
          +"plaisantait souvent à ce sujet. Dans un couple, disait-il, le mieux serait de "
          +"partir tous en même temps. Comme cela, il ne resterait personne pour "
          +"regretter l’autre. ",
          "",
          ""
          +"Dans la réalité, cela n’arrive jamais ou presque. Il y a bien eu la mort de "
          +"Jean Cocteau, vingt-quatre heures après celle d’Edith Piaf, pour faire mentir "
          +"cette règle. Sans doute l’article qu’on a commandé au poète sur cette "
          +"chanteuse d’exception s’est-il révélé fatal. Pourtant, que je sache, ils n’étaient "
          +"pas mariés. En revanche, mes grands-parents l’étaient et formaient un beau "
          +"couple. Ils sont partis tous les deux, à quelques mois d’intervalle. Ils sont "
          +"enterrés, dans le Lot-et-Garonne, au cimetière de Saint-Antoine-de-Ficalba. ",
          "C’est justement là que je me rends pour l’inhumation de mes parents. Dans "
          +"cette petite commune rurale, située entre Agen et Villeneuve-sur-Lot, mon "
          +"grand-père avait jadis acheté un domaine d’environ trois hectares au lieu-dit ",
          "Bois joli de Martel. Les raisons de cet achat remontent loin dans le temps.",
          "Après la défaite de Diên Biên Phu, le 7 mai 1954, mon grand-père avait été "
          +"emmené dans un camp de rééducation du Viêt Minh puis libéré quatre mois "
          +"plus tard. ",
          "",
          "Durant sa captivité, il avait très difficilement supporté les privations, les"
          +"brimades, l’endoctrinement, mais surtout la mort de milliers de ses "
          +"camarades. Sur douze mille soldats faits prisonniers, seulement trois mille "
          +"étaient revenus de captivité. Pour survivre, mon grand-père avait fait un "
          +"serment : il s’était promis de quitter l’armée et de se retirer dans un lieu "
          +"totalement isolé s’il avait la chance d’en réchapper vivant. ",
          "",
          ""
          +"***",
          " ",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 2",
          paragraph: 
          ["A quatre heures, tout le monde se lève, pas trop vite quand même, "
          +"pour ne pas me donner l’impression d’être resté pour la forme. La lenteur "
          +"est la politesse des enterrements. Carole s’avance vers moi et me demande "
          +"ce que je vais faire de mes quelques jours de permission. ",
          "- Je ne sais pas encore… ",
          "- Tu vas repartir là-bas ? ",
          "- Oui. ",
          "Là-bas. Carole n’a pas osé prononcer « l’Afghanistan » et nommer le danger "
          +"par son nom. ",
          "- Fais attention à toi, Stéphanie ! dit-elle en m’embrassant sur la joue. ", 
          "Je sais à quoi elle fait allusion. L’énergie réclamée par le deuil pourrait "
          +"m’enlever la prudence nécessaire lors des opérations de guerre menées "
          +"contre les talibans. ",
          "- Ne t’inquiète pas Carole. Ça va aller… ",
          "- Tu peux venir dormir à la maison, si tu veux. Tu ne resteras pas toute seule en attendant…",
          "- Non, merci, c’est gentil… ",
          "Je n’ose pas lui répondre que je veux le silence, l’apaisement, la solitude. "
          +"Et je sais où me rendre pour l’obtenir. ",
          "- Où vas-tu ce soir ? ",
          "- À la caserne du 17ème R.G.P à Montauban. J’y ai quelques copains… ",
          "Je lis dans son regard qu’elle ne me croit pas. Mais qu’importe. Ce que je "
          +"compte faire maintenant n’appartient qu’à moi et à personne d’autre. Je vais "
          +"me rendre au Bois joli de Martel. Ce lieu chante la vie. Rien que pour le nom, "
          +"mon grand-père a eu raison d’acheter cette propriété et de la restaurer. Je "
          +"vais y passer la nuit. Pas à la maison, ni dans la grange, mais à la belle étoile, "
          +"car la propriété a été vendue à des Anglais il y a déjà bien des années. Vingt "
          +"ans pour être exacte. Et j’ai maintenant besoin de communier avec ce lieu où "
          +"’ai des souvenirs par milliers. C’est près du petit ruisseau, qui coule été "
          +"comme hiver, que je vais passer la nuit. Mon grand-père y installait jadis son "
          +"potager. Environ deux mille mètres carrés où il cultivait courgettes, "
          +"concombres, citrouilles, salades, haricots verts, piments des Landes, "
          +"artichauts, potirons et surtout des tomates. Toutes sortes de tomates : cœur "
          +"de bœuf, tomates-cerises, tomates de Crimée, et d’autres dont, pour "
          +"certaines, j’ai oublié jusqu’au nom. Mais je n’ai pas oublié leur odeur, cette "
          +"fragrance si caractéristique qui persiste sur les doigts quand on y écrase une "
          +"feuille. ",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 3",
          paragraph: 
          ["J’avance un peu plus pour essayer de comprendre de quoi il retourne "
          +"vraiment. Et l’image de Gérard Majax me revient subitement en mémoire, "
          +"avec ses mots : « Y’a un truc ». Je renvoie cette image au passé et "
          +"m’agenouille pour mieux observer au travers de la haie de frênes et de "
          +"noisetiers. Il y a une silhouette de dos : un homme s’active au jardin. C’est "
          +"étrange, car il a sensiblement la même corpulence que mon grand-père. Je "
          +"marche doucement sans apercevoir une branche qui casse sous mon poids. ",
          "L’homme se retourne, surpris, et j’ai le réflexe de me dissimuler derrière la "
          +"haie. Je laisse passer quelques instants et mon regard glisse à nouveau entre "
          +"les frondaisons. Ce que je vois alors dépasse l’entendement et tous les tours "
          +"de magie qui m’ont été donnés de voir durant mon enfance. ",
          "J’ai devant moi un homme mort depuis vingt ans ! ",
          "Je le sais, j’étais là, à son enterrement. ",
          "Pourtant, je ne peux nier l’évidence. ",
          "Même si cette situation défie toutes les lois de l’univers, cet homme est mon grand-père ! ",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 4",
          paragraph: 
          ["C’est lui. ",
          "C’est sûr. ",
          "Cette silhouette. ",
          "Mon grand-père. ",
          "Et pourtant, c’est forcément quelqu’un d’autre. Il ne peut pas en être "
          +"autrement parce que ça ne peut plus être lui : c’est moi qui l’ai mis en bière "
          +"avec l’aide du croquemort. Mes parents ont d’abord hésité à me laisser faire. ",
          "Mais je voulais le toucher une dernière fois avant qu’on ne ferme le cercueil. ",
          "C’était ma façon de l’embrasser sans faire comme on le faisait autrefois, ce "
          +"baiser sur le front ou la joue d’une peau froide. Je voulais lui dire au revoir à "
          +"ma façon. Personne ne m’en a empêchée et j’aurais regretté qu’il n’en soit "
          +"pas ainsi. ",
          "J’observe celui qui est revenu. ",
          "Est-ce vraiment mon grand-père ? ",
          "Serait-il possible de violer les lois de l’univers ? Et si oui, depuis quand ? ",
          "Tant de questions auxquelles je n’ai pas de réponse hormis la présence de… ",
          "Comment le qualifier d’ailleurs ? ",
          "Un ressuscité ? ",
          "Un miraculé ? ",
          "Je ne sais plus trop quoi penser. Pourrait-il détecter ma présence sans même "
          +"me voir ? Cette pensée m’effraye. Il n’a eu aucun mal à entendre craquer la "
          +"branche sous mon pied. Il était pourtant sourd. La faute à toutes ces guerres "
          +"qui avaient détruit sa capacité d’audition. ",
          "Peut-on se débarrasser des infirmités de la vieillesse lorsqu’on revient en vie ? ",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 5",
          paragraph: 
          ["Je voudrais renvoyer mon grand-père à son monde ; il n’a plus rien à "
          +"faire ici. Ce lieu ne peut plus être le sien. Autrefois, il l’a été. Mais plus "
          +"maintenant. Mon premier réflexe est de reculer comme si cet homme, qui "
          +"fut mon grand-père, représentait une menace. Et je ne suis en aucune "
          +"manière préparée à voir revenir un mort. Et comme je ne suis pas préparée à "
          +"ça, comme personne ne peut se préparer à ça, l’être humain a inventé le "
          +"deuil pour surmonter l’absence définitive de l’autre. ",
          "J’ai fait le deuil de mon grand-père, voilà déjà vingt ans. ",
          "J’ai intégré sa disparition. "
          +"Je me suis habituée à vivre sans lui en dépit de l’amour que je lui portais. "
          +"Et aujourd’hui, là, maintenant, c’est la construction même de ce deuil qui est "
          +"remise en cause. Si encore il avait été porté disparu à Diên Biên Phu et qu’il "
          +"était revenu des années plus tard, j’aurais pu le comprendre. Son cas n’aurait "
          +"pas été isolé. Dans toutes les guerres, des soldats portés disparus sont "
          +"réapparus à l’issue de celles-ci. Leurs épouses légitimes avaient pourtant fait "
          +"le deuil de leur présence au monde. Certaines avaient refait leur vie. Et le "
          +"retour de leur époux, supposé défunt, a provoqué des drames tout aussi "
          +"poignants que leur disparition au combat. Je songe au roman de Balzac, Le "
          +"colonel Chabert, à cet homme qui revient après des années réclamer son dû "
          +"et comprend avec amertume qu’il n’est plus rien dans le cœur de sa femme.",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        },
        {
          titre: "Extrait 6",
          paragraph: 
          ["Les cailloux gémissaient sous la roue cerclée de la brouette. Le jour se "
          +"levait à peine que nous étions déjà au potager afin de profiter de la fraîcheur "
          +"de l’aube. La terre sentait l’humus. Le ruisseau tintait. Mon grand-père "
          +"dégageait une forte odeur de transpiration tandis qu’il coupait la prêle à la "
          +"faucille. Il la plaçait dans un sac de jute afin de la mettre à macérer un peu "
          +"plus tard. Je suis partie vers le ruisseau pour remplir les deux arrosoirs en "
          +"métal. Mes mains ont plongé dans le bouillonnement de la petite cascade. ",
          "Mais je ne suis pas revenue de suite; je me suis assise sur le bord et j’ai "
          +"regardé mon grand-père. Il s’est arrêté un instant et m’a dit : ",
          "- Ça va, tout va bien ? Ça ne te gêne pas de me regarder travailler ? ",
          "- Je ne te regarde pas travailler, papi… ",
          "Ma voix était calme, posée. ",
          "- Alors que fais-tu ? m’a-t-il demandé sur un ton de reproche. ",
          "- Je te regarde… je te regarde pour tous les jours où tu ne seras plus là. ",
          "J’ai détaché chacun de mes mots pour qu’ils contiennent le plus d’amour "
          +"possible. Mon grand-père a vacillé ; ses yeux se sont embués puis nous "
          +"sommes restés quelques secondes ainsi sans parler. Juste nos regards, le "
          +"tintement de la cascade, et cette odeur de terre qui emplissait nos "
          +"muqueuses.",
          "",
          "***",
          "",
          "A suivre ..."
          ]
        }
      ]
    }
  },
  computed:{
    viewState: {
      get(){
        return this.$store.getters['ldb/viewState'];
      },
      set(newState) {
        return this.$store.commit('ldb/changeDsplyState', newState);
      }
    }
  }
}
</script>

<style scoped>
.accordion {
  text-align: justify;
}
.title {
  color: white;
}
.paragraphe {
  font-size: 16pt;
  text-align: justify;
  color: antiquewhite
}
</style>