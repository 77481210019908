<template>
  <v-container v-if="viewState.geneseDsply == true">
    <v-row>
      <v-col>
        <v-card
          dark
          class="elevation-4"
          style="text-align: center; font-size: 16pt"
        >
          <v-card-text style="color: AntiqueWhite; font-size: 16pt">
            Ici nous abordons les raisons qui m'ont amené à l'écriture du roman
            <u>Les mots des salines</u>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text>
            <p
              style="
                color: AntiqueWhite;
                text-align: justify;
                padding: 15px;
                font-size: 16pt;
              "
            >
              Je tiens à remercier tout particulièrement Colette, Paul et Fabien Lavit, respectivement ma tante, mon oncle et mon cousin, qui m’ont ouvert, par un matin d’été en 2012, la Villa Thau, dans le quartier du Pont-Levis, à Sète. C’est là que cette histoire est née, à proximité immédiate des anciennes salines de Villeroy, entre le passage incessant des trains, jour et nuit, sur la voie de chemin de fer reliant Bordeaux à Vintimille. Environ 170 rames passent quotidiennement à quelques mètres de la vieille maison.
              <br>
              Trains de voyageurs.
              <br>
              Trains de marchandises.
              <br>
              Des trains qui ont fini par s’incruster dans le roman sans même que je ne me souvienne les y avoir invités. Le vacarme est devenu mots. Je ne leur en veux pas, je tiens bien au contraire à les en remercier.
              <br>
              Quand je suis rentré en ce matin d’été 2012, les rideaux se sont agités doucement sous l’effet de la brise marine. C’était leur manière de me faire comprendre que mes personnages venaient enfin de trouver le lieu dans lequel situer cette histoire.
              <br>
              Il y avait un lit à l’étage, je m’y suis allongé.
              <br><br>
              Au bout de quelques instants, quelqu’un a frappé à la porte.
              <br>
              — Je peux entrer ? a-t-elle demandé de sa voix tranquille et invisible.
              <br>
              — Oui, ai-je répondu, quelque peu décontenancé par sa présence.
              <br>
              La lenteur s’est assise sur le lit, près de moi.
              <br>
              — Tu auras mis le temps, a-t-elle reproché de sa voix douce, je t’attendais depuis déjà de longues années.
              <br>
              Un train de marchandises est passé. Je me suis réveillé en sursaut.
              <br>
              La lenteur ne m’a pourtant jamais quitté d’une semelle durant l’ébauche du manuscrit. Chaque jour, elle susurrait que je devais éviter le piège de la page impatiente.
              <br><br>
              L’expression avait du chien, je l’ai conservée comme titre de mon tapuscrit.
              <br>
              Un matin, la lenteur est partie sans crier gare. Je l’ai cherchée, en vain. Peut-être, allez savoir, a-t-elle emprunté l’un des trains qui passaient.
              <br>
              J’ai alors compris qu’il était temps de rassembler mes mots pour composer un roman.
              <br><br>
              Celui de Simon et Sophie – l'un est Gallois, l'autre est Française –, deux personnages qui existent réellement. Ils ont eu, après s'être aimés bien des années auparavant à Londres, l'idée de se retrouver en même temps sans s'être jamais concertés. Il est des hasards – l'instant de Dieu selon certains – que rien n'explique. Ils se sont retrouvés à Pau, un jour dont personne ne se souvient sauf eux, et c'est très bien ainsi.
              <br><br>
              Un soir ou un matin, je ne sais plus vraiment, Sophie m'a raconté cette histoire incroyable. Elle venait de perdre son compagnon d'un cancer et l'idée de retrouver Simon, cet homme qu'elle avait aimé, l'a effleurée.
              <br><br>
              J'ai décidé d'en faire une histoire sur fond d'anciennes salines – celles de Villeroy à Sète – et de fleurs de sel.
              <br><br>
              Peu de temps après, je prenais le train entre Paris et Lourdes. Après la gare de Dax, bien des gens étaient descendus. Sur l'une des banquettes se trouvait le journal Libération. Dans mes souvenirs, mais peut-être n'était-ce pas le cas, il me semble qu'il était ouvert sur la tribune libre intitulée Je veux faire l'amour, tribune libre écrite par Patricia Assouline, comédienne handicapée, dont la lecture du texte m'a littéralement bouleversé et continue encore aujourd'hui de m'interroger.
              <br><br>
              Cette tribune libre évoque le plaisir sexuel des personnes handicapées et l'impossibilité en France d'accéder aux assistant(e)s sexuel(le)s, ce métier étant assimilé à de la prostitution alors même qu'il est légalisé chez certains de nos voisins européens comme la Suisse.
              <br><br>
              <v-icon>mdi-forward</v-icon> Voici le lien de cette tribune libre : 
              <a href="https://www.liberation.fr/societe/2013/03/27/je-veux-faire-l-amour_891749">Tribune</a>
              <br><br>
              <v-icon>mdi-forward</v-icon> Voici un autre article de presse paru dans La république du Centre qui évoque le court-métrage « Stand-up », tourné par la réalisatrice Dominique Fisbach, dans lequel  tourne la comédienne Patricia Assouline : 
              <a href="https://www.larep.fr/briare-45250/loisirs/un-court-metrage-traitant-de-la-sexualite-des-personnes-handicapees-en-projet-a-lescabeau_11751087/">
                Lien vers l'article
              </a>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      le95: false,
    };
  },
  methods: {
    navigateToUsefullLinks() {
      this.viewState.defaultDsply = false;
      this.viewState.sampleDsply = false;
      this.viewState.geneseDsply = false;
      this.viewState.albumDsply = false;
      this.viewState.liensUtilesDsply = true;
      this.viewState.lecteursDsply = false;
      this.viewState.auteurDsply = false;
    },
  },
  computed: {
    viewState: {
      get() {
        return this.$store.getters["lmds/viewState"];
      },
      set(newState) {
        return this.$store.commit("lmds/changeDsplyState", newState);
      },
    },
  },
};
</script>

<style scoped>
p {
  color: white;
}
</style>